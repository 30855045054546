import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './white.css';

export interface ButtonViewWhiteProps {
    view?: 'white';
}

export const withViewWhite = withBemMod<ButtonViewWhiteProps>(cnButton(), {view: 'white'});
