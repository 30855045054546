import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './trash.css';

export interface IconTypeTrashProps {
    type?: 'trash';
}

export const withTypeTrash = withBemMod<IconTypeTrashProps>(cnIcon(), {type: 'trash'});
