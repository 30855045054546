import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './filter-white.css';

export interface IconTypeFilterWhiteProps {
    type?: 'filter-white';
}

export const withTypeFilterWhite = withBemMod<IconTypeFilterWhiteProps>(cnIcon(), {
    type: 'filter-white'
});
