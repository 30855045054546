import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import plural from 'plural-ru';

import {
    AudienceType,
    AudienceCategories,
    GeoValue,
    AudienceCategoryInfo,
    AudienceCategoryValue,
    AudienceInterestInfo,
    AudienceInterestValue,
    AudienceRequest,
    AudienceSegmentInfo,
    BoxType,
    Features,
    ExtendedAudienceCategoryId,
    COUNTRIES,
    GeoCountry,
    GenderCategory,
    AgeCategory,
    AudienceSettingsType
} from 'client/common/types';
import {getIsCreativeBox} from 'client/common/utils/box';
import {UserRoles} from 'client/common/hooks/user';
import {AudienceTab} from 'client/common/components/survey-settings/types';

import I18N from 'assets/i18n/brandlift-project';
import {getSortedTranslations} from 'assets/i18n';

import {formatAgeIntervals} from '../format';

import {AudienceSegments} from './segments';
import {TargetingOptions} from './targeting-options';

const i18n = I18N.common.utils.format;
const extendedTargetingI18n = I18N.common.audienceSettings.extendedTargeting;

export interface TargetDisplayData {
    title: string;
    content: string;
    icon?: string;
}

const audienceTargetingI18n = I18N.common.audienceSettings.audienceTargeting;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AudienceTargeting {
    static readonly DEFAULT_AUDIENCE = Object.freeze({
        size: 150,
        type: AudienceType.RespParams
    });
    static readonly DEFAULT_EXTENDED_AUDIENCE = Object.freeze({
        size: 150,
        type: AudienceType.RespParamsOmi,
        categories: <AudienceCategories[]>[
            {categoryId: 'geo', value: [GeoValue.Russia]},
            {categoryId: 'have_children', value: '0'}
        ]
    });
    static readonly DEFAULT_CATEGORIES: AudienceCategories[] = [];
    static readonly EXTENDED_CATEGORY_VALUES: ExtendedAudienceCategoryId[] = [
        'have_car',
        'have_children',
        'cohabitants_amount',
        'family_status'
    ];
    static WIDE_AGE_CATEGORY: AgeCategory = {
        categoryId: 'age',
        value: ['1', '2', '3', '4', '5']
    };
    static WIDE_GENDER_CATEGORY: GenderCategory = {
        categoryId: 'gender',
        value: ['0', '1']
    };

    // eslint-disable-next-line complexity, max-statements
    static getAvailableAudienceTypes(params: {
        boxType: BoxType | undefined;
        request: AudienceRequest;
        segments: AudienceSegmentInfo[];
        userRoles: UserRoles;
        features: Features;
        selectedCountry: string | undefined;
    }): AudienceSettingsType[] {
        const {boxType, request, segments, selectedCountry, userRoles} = params;

        const types: AudienceSettingsType[] = [];

        const isCreativeBox = getIsCreativeBox(boxType);

        if (AudienceTargeting.isRespParamsAvailable(boxType, selectedCountry, userRoles)) {
            types.push(AudienceType.RespParams);
        }

        if (AudienceTargeting.isInterestsAvailable(boxType, selectedCountry, userRoles)) {
            types.push(AudienceType.MediaTargeting);
        }

        if (AudienceTargeting.isYaAudienceAvailable(request, segments, userRoles, boxType)) {
            types.push(AudienceType.YaAudience);
        }

        if ((selectedCountry as GeoValue) === GeoValue.Russia || isCreativeBox) {
            types.push(AudienceType.Quotas);
        }

        if (
            isCreativeBox &&
            selectedCountry &&
            [GeoValue.Russia, GeoValue.Kazakhstan, GeoValue.Belarus, GeoValue.Uzbekistan].includes(
                selectedCountry as GeoValue
            )
        ) {
            types.push('GEO');
        }

        return types;
    }

    static getAvailableAudienceTabs(params: {
        audienceType?: AudienceType;
        boxType?: BoxType;
        userRoles: UserRoles;
        features: Features;
    }) {
        const {audienceType, userRoles, boxType, features} = params;

        const tabs: Array<AudienceTab> = [AudienceTab.Request];

        const shouldAddOwn = !getIsCreativeBox(boxType);

        if (shouldAddOwn) {
            tabs.push(AudienceTab.Own);
        }

        if (
            AudienceTargeting.isManualAudienceAvailable({features, userRoles}) ||
            audienceType === AudienceType.Manual
        ) {
            tabs.push(AudienceTab.Manual);
        }

        return tabs;
    }

    static formatAsString(params: {
        request: AudienceRequest;
        categories: AudienceCategoryInfo[];
        interests: AudienceInterestInfo[];
        segments: AudienceSegmentInfo[];
        separator?: string;
    }): string {
        return this.format(params) as string;
    }

    static formatFullData(params: {
        request: AudienceRequest;
        categories: AudienceCategoryInfo[];
        interests: AudienceInterestInfo[];
        segments: AudienceSegmentInfo[];
        separator?: string;
    }): TargetDisplayData[] {
        return this.format({
            ...params,
            fullData: true
        }) as TargetDisplayData[];
    }

    static getRequestTypeTitle(requestType: AudienceType): string {
        switch (requestType) {
            case AudienceType.Quotas:
                return audienceTargetingI18n.quotas;
            case AudienceType.FixedTotalCost:
                return audienceTargetingI18n.fixedTotalCost;
            case AudienceType.MediaTargeting:
                return audienceTargetingI18n.mediaTargeting;
            case AudienceType.RespParams:
                return audienceTargetingI18n.respParams;
            case AudienceType.RespParamsOmi:
                return audienceTargetingI18n.respParamsOmi;
            case AudienceType.YaAudience:
                return audienceTargetingI18n.segments;
            case AudienceType.None:
                return audienceTargetingI18n.noneTitle;
            case AudienceType.Manual:
                return audienceTargetingI18n.manual;
        }
    }

    // eslint-disable-next-line max-params, max-statements, complexity
    static format(params: {
        request: AudienceRequest;
        categories: AudienceCategoryInfo[];
        interests: AudienceInterestInfo[];
        segments: AudienceSegmentInfo[];
        separator?: string;
        fullData?: boolean;
    }): string | Array<TargetDisplayData> {
        const {request, categories, interests, segments, separator = ' · ', fullData} = params;

        switch (request.type) {
            case AudienceType.Quotas: {
                if (fullData) {
                    return [];
                }

                return i18n.audience.type.quotas;
            }

            case AudienceType.FixedTotalCost: {
                if (fullData) {
                    return [];
                }

                return '';
            }

            case AudienceType.MediaTargeting: {
                if (fullData) {
                    return [
                        {
                            title: audienceTargetingI18n.interestsTitle,
                            content: this.getInterestShorthand(request.interests, interests)
                        }
                    ];
                }

                return this.getInterestShorthand(request.interests, interests);
            }

            case AudienceType.RespParams:
            case AudienceType.RespParamsOmi: {
                const gender = this.getGenderShorthand(
                    request.categories.find(({categoryId}) => categoryId === 'gender')
                        ?.value as AudienceCategoryValue
                );
                const age = this.getAgeShorthand(
                    request.categories.find(({categoryId}) => categoryId === 'age')
                        ?.value as AudienceCategoryValue[],
                    categories.find(({id}) => id === 'age')!,
                    separator
                );
                const geo = this.getGeoShorthand(
                    request.categories.find(({categoryId}) => categoryId === 'geo')
                        ?.value as AudienceCategoryValue[]
                );
                const extendedShorthands = this.getExtendedShorthands(request.categories, fullData);

                if (fullData) {
                    const gatheredData = [
                        {
                            icon: 'gender',
                            content: gender,
                            title: audienceTargetingI18n.genderTitle
                        },
                        {
                            icon: 'age',
                            content: age,
                            title: audienceTargetingI18n.ageTitle
                        },
                        {
                            icon: 'geo',
                            content: geo,
                            title: audienceTargetingI18n.regionTitle
                        },
                        ...(extendedShorthands as TargetDisplayData[])
                    ];

                    return gatheredData;
                }

                return [gender, age, geo, ...extendedShorthands].join(separator);
            }

            case AudienceType.YaAudience: {
                if (fullData) {
                    return [
                        {
                            content: AudienceSegments.getShorthand(request.segments, segments),
                            title: ''
                        }
                    ];
                }

                return AudienceSegments.getShorthand(request.segments, segments);
            }

            case AudienceType.None: {
                if (fullData) {
                    return [];
                }

                return i18n.audience.type.own;
            }

            case AudienceType.Manual: {
                if (fullData) {
                    return [];
                }

                return i18n.audience.type.manual;
            }
        }
    }

    static isExtendedTabAvailable(boxType: BoxType | undefined) {
        return !boxType || getIsCreativeBox(boxType);
    }

    static isDefaultAudience(request: {
        type: AudienceType;
        categories: AudienceCategories[];
    }): boolean {
        return (
            request.type === AudienceTargeting.DEFAULT_AUDIENCE.type &&
            (isEqual(request.categories, []) ||
                AudienceTargeting.isSelectedOnlySingleCountry(request.categories))
        );
    }
    static isExtendedAudience(request: AudienceRequest) {
        return request.type === AudienceTargeting.DEFAULT_EXTENDED_AUDIENCE.type;
    }

    static getSelectedCountry({
        categories,
        boxType,
        request
    }: {
        request: AudienceRequest;
        categories: AudienceCategoryInfo[];
        boxType: BoxType | undefined;
    }): GeoCountry {
        const targetingOptions = TargetingOptions.getTargetingOptions({
            categories,
            boxType,
            country: undefined
        });
        const targetingValues = TargetingOptions.getTargetingValues(request);

        const geoValue = targetingValues.geo;
        const geoPossibleValues = targetingOptions.geo;

        if (!geoValue || geoValue.length === 0) {
            return GeoValue.Russia;
        }

        return geoPossibleValues.find(({value}) => value === geoValue[0])?.properties
            .country as GeoCountry;
    }

    private static getInterestShorthand(
        values: AudienceInterestValue[],
        info: AudienceInterestInfo[]
    ): string {
        const interest = info.find(({id}) => id === values[0]);

        return interest?.name ?? '';
    }

    private static getGenderShorthand(value?: AudienceCategoryValue): string {
        switch (value) {
            case '0':
                return i18n.audience.shorthand.gender.male;

            case '1':
                return i18n.audience.shorthand.gender.female;

            default:
                return `${i18n.audience.shorthand.gender.male}+${i18n.audience.shorthand.gender.female}`;
        }
    }

    private static getAgeShorthand(
        values: AudienceCategoryValue[] | undefined,
        info: AudienceCategoryInfo,
        separator: string
    ): string {
        const ages = values
            ? info.possibleValues.filter(({value}) => values.includes(value))
            : info.possibleValues;

        return formatAgeIntervals(map(ages, 'label'), separator);
    }

    private static getGeoShorthand(values: AudienceCategoryValue[] | undefined): string {
        const cities = values ?? [GeoValue.Russia];

        if (cities.length === 1) {
            return I18N.common.geo[cities[0] as keyof typeof I18N.common.geo] || '';
        }

        return plural(
            cities.length,
            ...(getSortedTranslations(i18n.audience.geo) as [string, string, string])
        );
    }

    private static getExtendedShorthands(
        categories: AudienceCategories[],
        fullData?: boolean
    ): string[] | TargetDisplayData[] {
        const shorthands: string[] = [];

        const data: TargetDisplayData[] = [];

        // eslint-disable-next-line max-statements
        categories.forEach(({categoryId, value}) => {
            if (categoryId === 'city_size') {
                const valueStr = value.join('_');

                if (valueStr === '2' || valueStr === '3_4' || valueStr === '2_3_4') {
                    shorthands.push(extendedTargetingI18n.citySize[valueStr]);

                    data.push({
                        title: audienceTargetingI18n.citiesTitle,
                        content: extendedTargetingI18n.citySize[valueStr]
                    });
                }
            } else if (categoryId === 'cohabitants_amount') {
                shorthands.push(extendedTargetingI18n.cohabitantsAmount[value]);
                data.push({
                    title: audienceTargetingI18n.cohabitantsAmountTitle,
                    content: extendedTargetingI18n.cohabitantsAmount[value]
                });
            } else if (categoryId === 'family_status') {
                shorthands.push(extendedTargetingI18n.familyStatus[value]);

                data.push({
                    title: audienceTargetingI18n.familyStatusTitle,
                    content: extendedTargetingI18n.familyStatus[value]
                });
            } else if (categoryId === 'have_car') {
                shorthands.push(extendedTargetingI18n.haveCar[value]);

                data.push({
                    title: audienceTargetingI18n.haveCarTitle,
                    content: extendedTargetingI18n.haveCar[value]
                });
            } else if (categoryId === 'have_children') {
                shorthands.push(extendedTargetingI18n.haveChildren[value]);

                data.push({
                    title: audienceTargetingI18n.haveChildrenTitle,
                    content: extendedTargetingI18n.haveChildren[value]
                });
            }
        });

        if (fullData) return data;

        return shorthands;
    }

    private static isSelectedOnlySingleCountry(categories: AudienceCategories[]) {
        return COUNTRIES.some(country =>
            isEqual(categories, [{categoryId: 'geo', value: [country]}])
        );
    }

    private static isRespParamsAvailable(
        boxType: BoxType | undefined,
        country: string | undefined,
        userRoles: UserRoles
    ) {
        if (userRoles.isSuperuser) {
            return true;
        }

        if (boxType) {
            if (getIsCreativeBox(boxType)) {
                return true;
            }

            if (country === GeoValue.Russia && boxType === 'ux') {
                return true;
            }

            return ['videoBox', 'videoBoxKZ', 'createSegment'].includes(boxType);
        }

        return country === GeoValue.Russia;
    }

    private static isInterestsAvailable(
        boxType: BoxType | undefined,
        country: string | undefined,
        userRoles: UserRoles
    ) {
        if (userRoles.isSuperuser) {
            return true;
        }

        if (country === GeoValue.Uzbekistan) {
            return false;
        }

        if (boxType) {
            if (country === GeoValue.Russia && boxType === 'ux') {
                return true;
            }

            if (getIsCreativeBox(boxType)) {
                return true;
            }

            return ['videoBox', 'videoBoxKZ', 'createSegment'].includes(boxType);
        }

        return country === GeoValue.Russia;
    }

    private static isManualAudienceAvailable(params: {features: Features; userRoles: UserRoles}) {
        const {features, userRoles} = params;

        return [
            features.manualAudience,
            userRoles.isSuperuser,
            userRoles.isSupport,
            userRoles.isYandexPublisher
        ].some(Boolean);
    }

    private static isYaAudienceAvailable(
        request: AudienceRequest,
        segments: AudienceSegmentInfo[],
        userRoles: UserRoles,
        boxType: BoxType | undefined
    ): boolean {
        if (boxType === 'videoBox') {
            return true;
        }

        if (request.type === AudienceType.YaAudience) {
            return true;
        }

        const userHasAvailableSegments =
            segments.find(segment => AudienceSegments.isAvailable(segment)) !== undefined;
        const requestHasSegments = request.segments.length > 0;

        if (!userHasAvailableSegments && !requestHasSegments) {
            return false;
        }

        return userRoles.isSuperuser;
    }
}
