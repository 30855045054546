import React, {Fragment} from 'react';
import b_ from 'b_';
import {Helmet} from 'react-helmet';
import {render} from 'react-dom';
import {Router, BrowserRouter} from 'react-router-dom';
import {DefaultOptions, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {logError} from '@yandex-int/pythia-libs/lib/rum';

import {Store} from 'client/common/types';
import {BundleData, BundleProps} from 'client/common/types/bundle';
import history from 'client/common/utils/history';
import {queryClient} from 'client/common/utils/react-query';
import {isYaTeamHost} from 'client/common/utils/client-config';

import {LangProvider} from 'client/common/contexts/lang';
import {SurveyApiLocationProvider} from 'client/common/contexts/survey-api-location';

import {onRequestSettled} from 'client/common/hooks/utils';
import {HeaderNotificationPassport} from 'client/common/components/header-notification/header-notification-passport';
import MetricInitializer from 'client/common/components/metric-initializer';

import './bundle.css';

const b = b_.with('app');

function Page(props: React.PropsWithChildren<unknown>) {
    return (
        <Router history={history}>
            <div className={b()}>
                <Fragment>
                    <Helmet>
                        <html />
                    </Helmet>
                    {!isYaTeamHost && <HeaderNotificationPassport />}
                    <MetricInitializer />
                    {props.children}
                </Fragment>
            </div>
        </Router>
    );
}

const queryDefaultOptions: DefaultOptions = {
    mutations: {
        onSettled: onRequestSettled
    },
    queries: {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onError: (err: Error) => {
            logError({message: err.message, err});
        },
        onSettled: onRequestSettled
    }
};

export function renderBundle(Bundle: React.ComponentType<BundleProps>) {
    return function (data: BundleData, state: Store) {
        queryClient.setDefaultOptions(queryDefaultOptions);

        render(
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <SurveyApiLocationProvider value={data.isPublic ? 'public' : 'survey'}>
                    <LangProvider value={state.user.lang}>
                        <BrowserRouter>
                            <Page>
                                <Bundle data={data} state={state} />
                            </Page>
                        </BrowserRouter>
                    </LangProvider>
                </SurveyApiLocationProvider>
            </QueryClientProvider>,
            document.getElementById('mount')
        );
    };
}
