import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './blue.css';

export interface ButtonViewBlueProps {
    view?: 'blue';
}

export const withViewBlue = withBemMod<ButtonViewBlueProps>(cnButton(), {view: 'blue'});
