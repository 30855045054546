import {useCallback, useRef, useState} from 'react';
import {UseQueryResult, useQuery} from 'react-query';

import throttle from 'lodash/throttle';

import {MetrikaCounter, MetrikaGoal, MetrikaParam, RootParam} from 'client/common/types';
import {getMetrikaCounters} from 'client/common/utils/api-client';
import {sendGoal, sendParams} from 'client/common/utils/metrika';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';

const METRIKA_KEY = 'metrika';
const COUNTERS_KEY = 'counters';

export function useMetrikaCounters(): UseQueryResult<MetrikaCounter[]> {
    return useQuery(
        [METRIKA_KEY, COUNTERS_KEY],
        async () => {
            const {data} = await getMetrikaCounters();

            return data.counters;
        },
        {
            // сегменты есть только во внешнем домене, нужны внешние куки
            enabled: !isYandexTeamDomain
        }
    );
}

export function useSendGoalOnce() {
    const [sentGoals, setSentGoals] = useState(new Set<MetrikaGoal>());

    return useCallback(
        (goal: MetrikaGoal) => {
            if (sentGoals.has(goal)) {
                return;
            }

            setSentGoals(sentGoals.add(goal));

            sendGoal(goal);
        },
        [sentGoals]
    );
}

export function useSendParamsOnce() {
    const [sentParams, setSentParams] = useState(new Map<MetrikaParam, string | number>());

    return useCallback(
        (param: MetrikaParam, value: string | number) => {
            if (sentParams.get(param) === value) {
                return;
            }

            setSentParams(sentParams.set(param, value));

            sendParams([param, value]);
        },
        [sentParams]
    );
}

export function useScrollPageParamSending(elements: React.RefObject<HTMLDivElement>[], offset = 0) {
    const [initialized, setInitialized] = useState(false);
    const sendParamsOnce = useSendParamsOnce();
    const sentPage = useRef(-1);

    const sendPage = useCallback(
        (page: number) => {
            if (sentPage.current < page) {
                sentPage.current = page;
                sendParamsOnce(RootParam.scrolledPage, page + 1);
            }
        },
        [sendParamsOnce, sentPage]
    );

    const onScroll = useCallback(() => {
        const positions: number[] = [-window.scrollY];

        elements.forEach(el => {
            const value = el?.current?.getBoundingClientRect().y;

            if (value) {
                positions.push(value);
            }
        });

        const page = positions.findIndex(y => y + window.innerHeight - offset >= 0);

        sendPage(page);
    }, [elements, offset, sendPage]);

    if (initialized) return;

    setInitialized(true);

    sendPage(0);
    window.addEventListener('scroll', throttle(onScroll, 1000));
}
