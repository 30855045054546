import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './help-filled.css';

export interface IconTypeHelpFilledProps {
    type?: 'help-filled';
}

export const withTypeHelpFilled = withBemMod<IconTypeHelpFilledProps>(cnIcon(), {
    type: 'help-filled'
});
