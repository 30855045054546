import React, {useState} from 'react';
import b_ from 'b_';

import {Radiobox, Radio} from 'client/common/components/lego';

import i18n from './i18n';
import {getPayerFieldsInForm} from './utils';

import './card-payment-form.css';
import {Payer, PayerCountry} from './types';

const b = b_.with('card-payment-form');

interface Props {
    selectedPayerId?: string;
    payersList: Payer[];
    onSave: (id: string) => void;
    userCountry: PayerCountry;
}

/* eslint react/jsx-no-bind:0 no-alert:1 */
export default function ChoosePage({
    selectedPayerId,
    payersList,
    onSave,
    userCountry
}: Readonly<Props>) {
    const [selectedId, setSelectedId] = useState(selectedPayerId);

    return (
        <div className={b('choose-page')}>
            <h3 className={b('title')}>{i18n.chooseTitle}</h3>
            <div className={b('choose-list')}>
                <Radiobox
                    view='default'
                    size='m'
                    value={selectedId}
                    onChange={e => {
                        const id = e.target.value;

                        setSelectedId(id);
                        onSave(id);
                    }}
                    className={b('choose-items')}
                >
                    {payersList.map(payer => {
                        const name = getPayerFieldsInForm(userCountry)
                            .map(field => payer[field])
                            .join(' ');

                        return (
                            <Radio
                                key={payer.id}
                                value={payer.id}
                                className={b('choose-list-item')}
                                checked={payer.id === selectedId}
                                onClick={() => {
                                    setSelectedId(payer.id);
                                    onSave(payer.id);
                                }}
                            >
                                <div
                                    className={b('choose-list-info')}
                                    onMouseOver={() => setSelectedId(payer.id)}
                                >
                                    <span className={b('choose-list-name')} title={name}>
                                        {name}
                                    </span>
                                    <div className={b('choose-list-other')}>
                                        <span
                                            className={b('choose-list-email')}
                                            title={payer.email}
                                        >
                                            {payer.email}
                                        </span>
                                        <span>{payer.phone}</span>
                                    </div>
                                </div>
                            </Radio>
                        );
                    })}
                </Radiobox>
            </div>
        </div>
    );
}
