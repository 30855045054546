import React, {Fragment, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {Route, Switch} from 'react-router-dom';
import b_ from 'b_';
import {MetricaExperimentsProvider} from 'yandex-metrica-ab-react';

import cfg from 'client/common/utils/client-config';
import {Lang} from 'client/common/types';
import {ErrorBoundary} from 'client/common/components/error';
import SubscribeNotification from 'client/common/components/subscribe-notification';
import {useRumCounter} from 'client/common/utils/rum';
import {useIsAuthorized, useUserProfile} from 'client/common/hooks/user';
import {useScrollPageParamSending} from 'client/common/hooks/metrika';
import {useUser} from 'client/common/contexts/user';

import I18N from 'assets/i18n/root';

import {Header, headerMeaningfulSelector} from './components/header';
import OurClients from './components/our-clients';
import FeatureList from './components/feature-list';
import Reviews from './components/reviews';
import CallToAction from './components/call-to-action';
import Faq from './components/faq';
import {PageFooter} from './components/page-footer';
import MainSlide from './components/main-slide';
// import Callback from './components/phone-callback';
import FeedbackForm from './components/feedback-form';
import Articles from './components/articles';
import CrowdPage from './crowd';

import './root.css';

const i18n = I18N.page;
const b = b_.with('root');

const LandingPage = ({lang, hasSurveys = false}: {lang: Lang; hasSurveys?: boolean}) => {
    const userProfileQuery = useUserProfile();
    const user = useUser();

    const isLoaded = userProfileQuery.status === 'success';

    const isAuthorized = useIsAuthorized();
    const isSubscriber = userProfileQuery.data?.email && userProfileQuery.data?.receive_mailings;
    const withNotification = isLoaded && isAuthorized && !isSubscriber;

    const refs = {
        features: useRef<HTMLDivElement>(null),
        reviews: useRef<HTMLDivElement>(null),
        faq: useRef<HTMLDivElement>(null),
        form: useRef<HTMLDivElement>(null),
        articles: useRef<HTMLDivElement>(null)
    };

    useScrollPageParamSending(Object.values(refs), 500);

    return (
        <MetricaExperimentsProvider
            clientId={`metrika.${cfg.metrika.counters?.surveys}`}
            clientFeatures={{lang, hasSurveys: String(hasSurveys)}}
        >
            <MainSlide withNotification={withNotification}>
                {withNotification && (
                    <SubscribeNotification
                        defaultEmail={userProfileQuery.data?.email || user?.info?.email}
                    />
                )}
                <Header lang={lang} />
            </MainSlide>
            <div ref={refs.features} id='features'>
                <OurClients />
                <FeatureList />
            </div>
            <div ref={refs.reviews} id='reviews'>
                <Reviews />
            </div>
            <div ref={refs.articles} id='articles'>
                <Articles />
            </div>
            <div ref={refs.faq} id='faq'>
                <Faq />
            </div>
            <CallToAction />
            <div ref={refs.form} id='form'>
                <FeedbackForm lang={lang} />
            </div>
            {/* SURVEYSDUTY-4619: Убрать телефон с лэндинга */}
            {/* <div className={b('page-callback')}> */}
            {/*     <Callback /> */}
            {/* </div> */}
            <div className={b('page-footer')}>
                <PageFooter />
            </div>
        </MetricaExperimentsProvider>
    );
};

export default function Root() {
    const user = useUser();

    useRumCounter({
        page: 'quicksurvey.root',
        meaningfulPaintSelector: headerMeaningfulSelector,
        testBuckets: user?.testBuckets ?? []
    });

    return (
        <Fragment>
            <Helmet>
                <title>{i18n.title}</title>
            </Helmet>
            <div className={b()}>
                <ErrorBoundary position='center'>
                    <Switch>
                        <Route
                            exact
                            path={['/', '/landing']}
                            component={() => (
                                <LandingPage
                                    lang={user?.lang ?? 'ru'}
                                    hasSurveys={user?.info?.hasSurveys}
                                />
                            )}
                        />
                        <Route
                            exact
                            path='/landing/crowd'
                            component={() => <CrowdPage user={user!} />}
                        />
                    </Switch>
                </ErrorBoundary>
            </div>
        </Fragment>
    );
}
