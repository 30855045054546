import React from 'react';

import b_ from 'b_';

import {Button} from 'client/common/components/lego';

import {BoxType, SurveysGoal} from 'client/common/types';

import {Domain} from 'types/domain';
import {tld} from 'client/common/utils/tld';

import {useDevice} from 'client/common/hooks/device';

import {openLink} from 'client/common/utils/window';
import {createUrl} from 'client/common/utils/history';
import {Device} from 'client/common/utils/device-width';
import {sendGoal} from 'client/common/utils/metrika';
import I18N from 'assets/i18n/brandlift-project';

import {HelpCreateProps} from './types';

import './help-create.css';

const i18n = I18N.common.helpCreate;
const b = b_.with('help-create');

const commonHelpOrigin = ['ru', 'by', 'kz'].includes(tld) ? '' : 'https://surveys.yandex.ru';
const proHelpOrigin = ['ru', 'by'].includes(tld) ? '' : 'https://surveys.yandex.ru';

export const HelpLink = {
    MAIN: createUrl({link: `${commonHelpOrigin}/help/index.html`}),
    RECOMMENDATIONS: createUrl({link: `${commonHelpOrigin}/help/recomendations.html`}),
    MODERATION: createUrl({link: `${commonHelpOrigin}/help/moderation.html`}),
    COMPARISON: createUrl({link: `${proHelpOrigin}/help/comparison.html`}),
    UX_GOALS: createUrl({link: `${proHelpOrigin}/help/ux-test/goals.html`}),
    UX_CREATE: createUrl({link: `${proHelpOrigin}/help/ux-test/create.html`}),
    UX_RECOMMENDATIONS: createUrl({link: `${proHelpOrigin}/help/ux-test/recommendations.html`}),
    SEGMENT_GOALS: createUrl({link: `${proHelpOrigin}/help/segment/goals.html`}),
    SEGMENT_CREATE: createUrl({link: `${proHelpOrigin}/help/segment/create.html`})
} as const;

interface LinkInfo {
    link: (typeof HelpLink)[keyof typeof HelpLink];
    label: string;
}

const LINKS: Partial<Record<BoxType, LinkInfo[]>> & Record<'default', LinkInfo[]> = {
    ux: [
        {
            link: HelpLink.UX_GOALS,
            label: i18n.referenceItem.uxGoals
        },
        {
            link: HelpLink.UX_CREATE,
            label: i18n.referenceItem.uxCreate
        },
        {
            link: HelpLink.UX_RECOMMENDATIONS,
            label: i18n.referenceItem.uxRecommendations
        },
        {
            link: HelpLink.MODERATION,
            label: i18n.referenceItem.moderation
        }
    ],
    createSegment: [
        {
            link: HelpLink.SEGMENT_GOALS,
            label: i18n.referenceItem.segmentGoals
        },
        {
            link: HelpLink.SEGMENT_CREATE,
            label: i18n.referenceItem.segmentCreate
        },
        {
            link: HelpLink.MODERATION,
            label: i18n.referenceItem.moderation
        }
    ],
    default: [
        {
            link: HelpLink.MAIN,
            label: i18n.referenceItem.main
        },
        {
            link: HelpLink.RECOMMENDATIONS,
            label: i18n.referenceItem.recommendations
        },
        {
            link: HelpLink.MODERATION,
            label: i18n.referenceItem.moderation
        },
        {
            link: HelpLink.COMPARISON,
            label: i18n.referenceItem.comparison
        }
    ],
    videoBoxKZ: [
        {
            link: HelpLink.MAIN,
            label: i18n.referenceItem.main
        },
        {
            link: HelpLink.RECOMMENDATIONS,
            label: i18n.referenceItem.recommendations
        },
        {
            link: HelpLink.MODERATION,
            label: i18n.referenceItem.moderation
        }
    ]
};

function getHelpLinks(boxType: BoxType | undefined): LinkInfo[] {
    if (boxType) {
        return LINKS[boxType] ?? LINKS.default;
    }

    if (tld === Domain.Kz) {
        return LINKS.videoBoxKZ ?? LINKS.default;
    }

    return LINKS.default;
}

export default function HelpCreate(props: HelpCreateProps) {
    const {boxType} = props;

    const device = useDevice();

    const links = getHelpLinks(boxType);

    const handleOpenHelpLink = () => {
        sendGoal(SurveysGoal.ClickHelpCreateButton);
        openLink('/landing#form', true);
    };

    return (
        <div className={b()}>
            <h1 className={b('title')}>{i18n.title}</h1>
            <p className={b('description')}>{i18n.description}</p>
            <h2 className={b('reference-title')}>{i18n.reference}</h2>
            <ul className={b('reference')}>
                {links.map(({link, label}, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className={b('reference-item')} key={index}>
                        <a
                            href={link}
                            className={b('link')}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {label}
                        </a>
                    </li>
                ))}
            </ul>
            <div className={b('buttons')}>
                <Button
                    className={b('button')}
                    view='action'
                    type='link'
                    size='m'
                    width={device === Device.Mobile ? 'max' : 'auto'}
                    onClick={handleOpenHelpLink}
                >
                    {i18n.orderSurveyCreate}
                </Button>
            </div>
        </div>
    );
}
