import b_ from 'b_';
import React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import NotificationManager from './manager';
import Notification from './notification';

import './notifications.css';

const b = b_.with('notifications');

export default class Notifications extends React.Component {
    state = {
        notifications: NotificationManager.getList()
    };

    handleStoreChange = (notifications: any) => {
        this.setState({notifications});
    };

    componentDidMount = () => {
        NotificationManager.addChangeListener(this.handleStoreChange);
    };

    componentWillUnmount = () => {
        NotificationManager.removeChangeListener(this.handleStoreChange);
    };

    requestHideHandler = (notification: any) => () => {
        NotificationManager.remove(notification);
    };

    render() {
        const {requestHideHandler} = this;
        const {notifications} = this.state;

        return (
            <TransitionGroup className={b()}>
                {notifications.map((notification: any) => {
                    const {id, message, type, timeOut, x, y} = notification;

                    return (
                        <CSSTransition
                            key={id}
                            timeout={500}
                            classNames={{
                                appear: b('item', {appearing: 'yes'}),
                                enter: b('item', {entering: 'yes'}),
                                exit: b('item', {exiting: 'yes'}),
                                appearActive: b('item', {appeared: 'yes'}),
                                enterActive: b('item', {entered: 'yes'}),
                                exitActive: b('item', {exited: 'yes'})
                            }}
                        >
                            <Notification
                                type={type}
                                message={message}
                                timeOut={timeOut}
                                onRequestHide={requestHideHandler(notification)}
                                x={x}
                                y={y}
                            />
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        );
    }
}
