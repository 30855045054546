import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './refresh-black.css';

export interface IconTypeRefreshBlackProps {
    type?: 'refresh-black';
}

export const withTypeRefreshBlack = withBemMod<IconTypeRefreshBlackProps>(cnIcon(), {type: 'refresh-black'});
