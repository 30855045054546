import {withBemMod} from '@bem-react/core';

import {cnTextarea} from '@yandex-lego/components/Textarea';

import './borderless.css';

export interface TextareaViewBorderlessProps {
    view?: 'borderless';
}

export const withViewBorderless = withBemMod<TextareaViewBorderlessProps>(cnTextarea(), {
    view: 'borderless'
});
