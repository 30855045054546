import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './percent.css';

export interface IconTypePercentProps {
    type?: 'percent';
}

export const withTypePercent = withBemMod<IconTypePercentProps>(cnIcon(), {type: 'percent'});
