import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './interviews.css';

export interface IconTypeInterviewProps {
    type?: 'interview';
}

export const withTypeInterview = withBemMod<IconTypeInterviewProps>(cnIcon(), {type: 'interview'});
