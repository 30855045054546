import React from 'react';
import b_ from 'b_';

import {getStaffAvatarUrl, getStaffUrl} from 'client/common/utils/yandex-team';

import './user-staff.css';

const b = b_.with('user-staff');

export function UserStaff({login}: {login: string}) {
    return (
        <a
            className={b('link')}
            href={getStaffUrl(login)}
            target='_blank'
            rel='noopener noreferrer'
        >
            <img className={b('img')} src={getStaffAvatarUrl(login)} />
        </a>
    );
}
