import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './video.css';

export interface IconTypeVideoProps {
    type?: 'video';
}

export const withTypeVideo = withBemMod<IconTypeVideoProps>(cnIcon(), {type: 'video'});
