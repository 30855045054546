import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './hashtag.css';

export interface IconTypeHashtagProps {
    type?: 'hashtag';
}

export const withTypeHashtag = withBemMod<IconTypeHashtagProps>(cnIcon(), {type: 'hashtag'});
