import React, {useState} from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/brandlift-project';
import I18N_Root from 'assets/i18n/root';

import {LinkPage} from 'client/common/utils/header-link';
import {createUrl} from 'client/common/utils/history';
import {sendGoal} from 'client/common/utils/metrika';
import {Button, Icon} from 'client/common/components/lego';
import LangSwitcher from 'client/common/components/lang-switcher/lang-switcher';
import {Lang, RootGoal} from 'client/common/types';
import {useIsAuthorized} from 'client/common/hooks/user';

import LogoIcon from './logo.svg';

import './header.css';

const b = b_.with('header');

const i18n = I18N.common.header2;

const meaningfulElemName = 'menu-link';
const meaningfulWrapperClassName = b('menu', {type: 'main'});

const header_i18n = I18N_Root.header;

export const headerMeaningfulSelector = `.${meaningfulWrapperClassName} .${b(meaningfulElemName)}`;

/* eslint-disable max-statements */
export function Header({lang}: {lang: Lang}) {
    const [opened, setOpened] = useState(false);

    const referenceUrl = document.referrer ? new URL(document.referrer) : null;

    const currentUrl = new URL(window.location.href);

    const isUserLogedIn = useIsAuthorized();

    const isInternalTransition = () => {
        if (referenceUrl) {
            return referenceUrl.origin === currentUrl.origin;
        }

        return false;
    };
    const isUserLoggedAndOnSite = isInternalTransition() && isUserLogedIn;

    let buttonText;
    let buttonUrl;
    let handleStartClick;

    if (isUserLoggedAndOnSite) {
        buttonText = header_i18n.backToServiceBtnText;
        buttonUrl = createUrl({link: LinkPage.QuicksurveyList});
        handleStartClick = () => sendGoal(RootGoal.ClickLandingBackToSurveys);
    } else {
        buttonText = header_i18n.tryBtnText;
        buttonUrl = createUrl({link: LinkPage.Quicksurvey});
        handleStartClick = () => sendGoal(RootGoal.ClickLandingStart);
    }

    const handleOpenClick = () => {
        setOpened(isOpened => !isOpened);
    };

    return (
        <div className={b()}>
            <div className={meaningfulWrapperClassName}>
                <a className={b(meaningfulElemName)} href={createUrl({link: LinkPage.Landing})}>
                    <img
                        className={b('title-logo')}
                        alt={i18n.logo.yandex}
                        src={`https://yastatic.net/q/logoaas/v2/${i18n.logo.yandex}%20.svg?size=32&color=1B202B`}
                    />
                    <img className={b('logo')} src={LogoIcon} />
                    <img
                        className={b('service-logo')}
                        alt={i18n.logo.surveys}
                        src={`https://yastatic.net/q/logoaas/v2/%20${i18n.logo.surveys}.svg?size=32&color=1B202B`}
                    />
                </a>
            </div>
            <div className={b('menu', {type: 'links', opened: opened ? 'yes' : 'no'})}>
                <Button view='clear' type='link' url='#features'>
                    {header_i18n.features}
                </Button>
                <Button view='clear' type='link' url='#reviews'>
                    {header_i18n.reviews}
                </Button>
                <Button view='clear' type='link' url='#faq'>
                    {header_i18n.faq}
                </Button>
                <LangSwitcher
                    className={b('lang-switcher', {type: 'mobile'})}
                    lang={lang}
                    withLabel
                />
            </div>
            <div className={b('menu', {type: 'misc'})}>
                <Button
                    size='menu'
                    view='blue'
                    type='link'
                    onClick={handleStartClick}
                    url={buttonUrl}
                >
                    {buttonText}
                </Button>
                <Button
                    className={b('mobile-menu-btn')}
                    size='m'
                    view='clear'
                    icon={(iconClassName: string) => (
                        <Icon
                            className={iconClassName}
                            type={opened ? 'cross-bold' : 'menu-burger'}
                        />
                    )}
                    onClick={handleOpenClick}
                />
                <LangSwitcher
                    className={b('lang-switcher', {type: 'desktop'})}
                    lang={lang}
                    size='m'
                />
            </div>
        </div>
    );
}
