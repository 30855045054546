import React, {useEffect, useState} from 'react';
import b_ from 'b_';

import {sendGoal} from 'client/common/utils/metrika';
import {Lang, RootGoal} from 'client/common/types';
import {createUrl} from 'client/common/utils/history';

import I18N from 'assets/i18n/root';

import './feedback-form.css';

const b = b_.with('feedback-form');

const i18n = I18N.feedbackForm;
const FORMS_ORIGIN = 'https://forms.yandex.ru';
const FORM_ID = '10019142.97506dce61eff7c7a0884b6b770f0ba0532c6891';

export default function FeedbackForm({lang}: {lang: Lang}) {
    const [height, setHeight] = useState(150);

    const handleMessage = (e: MessageEvent) => {
        if (e.origin === FORMS_ORIGIN && e.data) {
            try {
                const parsedData = JSON.parse(e.data);

                // eslint-disable-next-line max-depth
                if (parsedData['iframe-height']) {
                    // SURVEYSDUTY-3079 с темой cloud-www иногда формы зацикливаются и передают постоянно
                    // две разные высоты, добавляем чтобы не было мерцания скроллбара
                    const BUGFIX_SCROLLBAR_HEIGHT = 20;

                    setHeight(parsedData['iframe-height'] + BUGFIX_SCROLLBAR_HEIGHT);
                }

                // eslint-disable-next-line max-depth
                if (parsedData.message === 'sent') {
                    sendGoal(RootGoal.ClickLandingFeedbackSent);
                }

                // eslint-disable-next-line no-empty
            } catch (_) {}
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => window.removeEventListener('message', handleMessage);
    }, []);

    return (
        <div className={b()}>
            <h5 className={b('title')}>{i18n.title}</h5>
            <div className={b('subtitle')}>{i18n.subtitle}</div>
            <iframe
                className={b('iframe')}
                style={{height}}
                src={createUrl({
                    link: `${FORMS_ORIGIN}/surveys/${FORM_ID}/?iframe=1&theme=cloud-www&lang=${lang}`
                })}
            />
        </div>
    );
}
