import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './menu.css';

export interface IconTypeMenuProps {
    type?: 'menu';
}

export const withTypeMenu = withBemMod<IconTypeMenuProps>(cnIcon(), {type: 'menu'});
