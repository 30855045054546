import React, {ComponentProps, useRef} from 'react';
import {compose} from '@bem-react/core';
import {Registry, withRegistry} from '@bem-react/di';

import {IMenuProps} from '@yandex-lego/components/Menu/desktop';
import {IPopupProps} from '@yandex-lego/components/Popup';
import {
    Select as SelectDesktop,
    cnSelect,
    withWidthMax
} from '@yandex-lego/components/Select/desktop';
import {withTogglable} from '@yandex-lego/components/withTogglable';

import {Button} from '../button';
import {Icon} from '../icon';

import {Menu, MenuTags, MenuWithSearch} from '../menu';
import {Popup} from '../popup';

import {TriggerWithTags} from './trigger-with-tags/trigger-with-tags';

import {withPinDividerRound} from './pin/divider-round';

import './index.css';

const registry = new Registry({id: cnSelect()});

registry
    .set('Trigger', Button)
    .set('Icon', props => <Icon {...props} type='arrow' glyph={undefined} />)
    .set('Menu', (props: IMenuProps) => <Menu {...props} view='default' />)
    .set('Popup', (props: IPopupProps) => <Popup {...props} view='default' zIndex={1010} />);

export const Select = compose(
    withPinDividerRound,
    withRegistry(registry),
    withTogglable,
    withWidthMax
)(SelectDesktop);

const registryDown = new Registry({id: cnSelect()});

registryDown
    .set('Trigger', Button)
    .set('Icon', props => <Icon {...props} type='arrow' glyph={undefined} />)
    .set('Menu', (props: IMenuProps) => <Menu {...props} view='default' />)
    .set('Popup', (props: IPopupProps) => (
        <Popup {...props} view='default' zIndex={1010} direction='bottom' />
    ));
/**
 * Селект, у которого попап открывается строго вниз.
 * Пришлось добавить для админки сапплаеров, попап селекта
 * при каждом клике менял направление открытия вверх/вниз
 */
export const SelectWithPopupDown = compose(
    withPinDividerRound,
    withRegistry(registryDown),
    withTogglable,
    withWidthMax
)(SelectDesktop);

// селект встраивающий попап в body, для кейса когда селект расположен внутри контейнера со скроллом
// попапы не скроллятся вместе с селектом
export const SelectWithBodyPopup = (props: ComponentProps<typeof Select>) => (
    <Select unsafe_scope={useRef(document.body)} {...props} />
);

export const SelectWithSearch = (props: ComponentProps<typeof Select>) => (
    <Select {...props} renderMenu={MenuWithSearch} />
);

export const SelectWithForwardView = (props: ComponentProps<typeof Select>) => (
    <Select {...props} renderMenu={(menuProps: any) => <Menu {...menuProps} view={props.view} />} />
);

interface SelectWithTagsProps extends ComponentProps<typeof Select> {
    minSelected?: number;
    menuType?: 'tags' | 'search';
}

export const SelectWithTags = (props: SelectWithTagsProps) => {
    const {value, minSelected, onChange, options, menuType = 'tags', className = ''} = props;

    const selected = Object.values(value);

    const getTags = (selectedValues: any[]) => {
        const valuesSet = new Set(selectedValues);
        const tags: any[] = [];

        options.forEach(option => {
            if ('items' in option) {
                option.items.forEach(opt => {
                    if (valuesSet.has(opt.value)) {
                        tags.push(opt);
                    }
                });
            } else if (valuesSet.has(option.value)) {
                tags.push(option);
            }
        });

        return tags;
    };

    const handleTagRemove = (tag: unknown) => {
        return getTags(Object.values(value).filter((val: any) => val !== tag)).map((item: any) => {
            return item.value;
        });
    };

    const tags = getTags(selected);

    let optionsLength = 0;

    options.forEach(option => {
        if ('items' in option) {
            option.items.forEach(() => {
                optionsLength = optionsLength + 1;
            });
        } else {
            optionsLength = optionsLength + 1;
        }
    });

    return (
        <Select
            {...props}
            className={[className, 'select-with-tags'].join(' ')}
            renderMenu={menuProps => (
                menuType === 'tags' 
                    ? <MenuTags {...menuProps} minSelected={minSelected} view='default' />
                    : <MenuWithSearch {...menuProps} minSelected={minSelected} view='default' autocomplete='off'/>
            )}
            renderTrigger={triggerProps => (
                <TriggerWithTags
                    {...triggerProps}
                    handleTagRemove={handleTagRemove}
                    minSelected={minSelected}
                    onChange={onChange}
                    allSelected={selected.length === optionsLength}
                    tags={tags}
                />
            )}
        />
    );
};
