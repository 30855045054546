import React from 'react';
import {YMInitializer} from 'react-yandex-metrika';

import {getCounter} from 'client/common/utils/metrika';

export default function MetricInitializer() {
    const metrikaCounter = getCounter();

    return (
        <YMInitializer
            accounts={metrikaCounter ? [metrikaCounter] : []}
            options={{webvisor: true, accurateTrackBounce: true}}
            version='2'
        />
    );
}