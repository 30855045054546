import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './upload.css';

export interface IconTypeUploadProps {
    type?: 'upload';
}

export const withTypeUpload = withBemMod<IconTypeUploadProps>(cnIcon(), {type: 'upload'});
