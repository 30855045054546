import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './edit.css';

export interface IconTypeEditProps {
    type?: 'edit';
}

export const withTypeEdit = withBemMod<IconTypeEditProps>(cnIcon(), {type: 'edit'});
