import {configureRootTheme} from '@yandex-lego/components/Theme';

import {theme} from './theme';

configureRootTheme({theme});

export * from './button';
export * from './checkbox';
export * from './icon';
export * from './link';
export * from './menu';
export * from './message-box';
export * from './modal';
export * from './popup';
export * from './radio-button';
export * from './radiobox';
export * from './select';
export * from './slider';
export * from './spin';
export * from './textarea';
export * from './textinput';
export * from './tooltip';
export * from './tumbler';
export * from './user-pic';
