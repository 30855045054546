import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './arrow-right.css';

export interface IconTypeArrowRightProps {
    type?: 'arrow-right';
}

export const withTypeArrowRight = withBemMod<IconTypeArrowRightProps>(cnIcon(), {
    type: 'arrow-right'
});
