import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import I18N from 'assets/i18n/brandlift-project';

import {AudienceCategoryInfo, BoxType} from 'client/common/types';
import {
    AudienceCategoryProperties,
    AudienceRequest,
    ExtendedAudienceCategoryId,
    AudienceCategoryValues,
    GeoCountry,
    GeoValue
} from 'client/common/types/audience';

import {TargetingOptions} from './targeting-options';

const i18n = I18N.common.audienceSettings;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TargetingExtendedOptions {
    static getDefaultValues({
        categories,
        selectedCountry
    }: {
        categories: AudienceCategoryInfo[];
        boxType: BoxType | undefined;
        selectedCountry: GeoCountry;
    }) {
        const targetingOptions = TargetingExtendedOptions.getTargetingOptions({
            categories,
            country: selectedCountry
        });

        return {
            age: map(targetingOptions.age, 'value'),
            gender: map(targetingOptions.gender, 'value'),
            geo: TargetingOptions.getDefaultGeoValue(targetingOptions.geo),
            haveCar: map(targetingOptions.have_car, 'value')[0],
            haveChildren: map(targetingOptions.have_children, 'value')[0],
            familyStatus: map(targetingOptions.family_status, 'value')[0],
            cohabitantsAmount: map(targetingOptions.cohabitants_amount, 'value')[0],
            citySize: map(targetingOptions.city_size, 'value')
        };
    }

    static getSelectedCategory(request: AudienceRequest): ExtendedAudienceCategoryId {
        const targetingValues = TargetingOptions.getTargetingValues(request);

        if (targetingValues.have_car) {
            return 'have_car';
        } else if (targetingValues.have_children) {
            return 'have_children';
        } else if (targetingValues.cohabitants_amount) {
            return 'cohabitants_amount';
        }

        return 'family_status';
    }

    /**
     * Возвращает для дополнительных таргетингов(ОМИ) список options для селектов
     */
    static getTargetingOptions(params: {categories: AudienceCategoryInfo[]; country?: string}) {
        const {categories, country} = params;

        // Фильтруем гео по типу коробки
        let geo = TargetingOptions.getGeoPossibleValues(categories);

        geo = geo.filter(({properties}) => {
            // для дополнительных таргетингов города России
            if (properties.regionType === 'country') {
                return true;
            }

            if (properties.population === '1M+' && properties.country === GeoValue.Russia) {
                return true;
            }
        });

        const genderOptions = (
            categories.find(({id}) => id === 'gender')?.possibleValues ?? []
        ).map(gender => ({
            ...gender,
            label: i18n.quotas.gender[gender.value as keyof typeof i18n.quotas.gender]
        }));

        const haveCarOptions = (
            categories.find(({id}) => id === 'have_car')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['have_car'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.haveCar[entry.value]
            })
        );

        const haveChildrenOptions = (
            categories.find(({id}) => id === 'have_children')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['have_children'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.haveChildren[entry.value]
            })
        );

        const familyStatusOptions = (
            categories.find(({id}) => id === 'family_status')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['family_status'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.familyStatus[entry.value]
            })
        );

        const cohabitantsAmountOptions = (
            categories.find(({id}) => id === 'cohabitants_amount')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['cohabitants_amount'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.cohabitantsAmount[entry.value]
            })
        );

        // Фильтруем гео по выбранной стране
        if (country) {
            geo = geo.filter(({properties}) => properties.country === country);
        }

        const citySizeOptions = [
            {
                label: i18n.extendedTargeting.citySize['2'],
                value: '2',
                properties: []
            },
            {
                label: i18n.extendedTargeting.citySize['3_4'],
                value: '3-4',
                properties: []
            }
        ];

        return {
            age: sortBy(categories.find(({id}) => id === 'age')?.possibleValues ?? [], 'label'),
            gender: genderOptions,
            geo: TargetingOptions.sortGeoOptions(geo),
            cohabitants_amount: cohabitantsAmountOptions,
            family_status: familyStatusOptions,
            have_car: haveCarOptions,
            have_children: haveChildrenOptions,
            city_size: citySizeOptions
        };
    }

    static getExtendedCategoryOptions(): {
        value: ExtendedAudienceCategoryId;
        content: string;
    }[] {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const i18n = I18N.common.audienceSettings.audienceTargeting;

        return [
            {
                value: 'have_children',
                content: i18n.extendedCategorySelect.haveChildren
            },
            {
                value: 'family_status',
                content: i18n.extendedCategorySelect.familyStatus
            },
            {
                value: 'have_car',
                content: i18n.extendedCategorySelect.haveCar
            },
            {
                value: 'cohabitants_amount',
                content: i18n.extendedCategorySelect.cohabitantsAmount
            }
        ];
    }
}
