import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './error-red.css';

export interface IconTypeErrorRedProps {
    type?: 'error-red';
}

export const withTypeErrorRed = withBemMod<IconTypeErrorRedProps>(cnIcon(), {type: 'error-red'});
