import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './dialog.css';

export interface IconTypeDialogProps {
    type?: 'dialog';
}

export const withTypeDialog = withBemMod<IconTypeDialogProps>(cnIcon(), {type: 'dialog'});
