import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './refresh.css';

export interface IconTypeRefreshProps {
    type?: 'refresh';
}

export const withTypeRefresh = withBemMod<IconTypeRefreshProps>(cnIcon(), {type: 'refresh'});
