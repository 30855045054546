import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './tip.css';

export interface ButtonViewTipProps {
    view?: 'tip';
}

export const withViewTip = withBemMod<ButtonViewTipProps>(cnButton(), {view: 'tip'});
