import {compose, composeU} from '@bem-react/core';

import {
    Textinput as TextinputDesktop,
    withHasClear,
    withPinBrickBrick,
    withPinRoundRound,
    withPinRoundClear,
    withPinClearRound,
    withPinBrickClear,
    withPinBrickRound,
    withPinRoundBrick,
    withSizeS,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Textinput/desktop';

import {withSizeMBold} from './size/m-bold';

import {withViewBorderless} from './view/borderless';
import {withViewDL} from './view/dl';

import './index.css';

export const Textinput = compose(
    withHasClear,
    composeU(
        withPinRoundRound,
        withPinRoundClear,
        withPinClearRound,
        withPinBrickClear,
        withPinBrickRound,
        withPinBrickBrick,
        withPinRoundBrick
    ),
    composeU(withSizeS, withSizeM, withSizeMBold),
    composeU(withViewBorderless, withViewDefault, withViewDL)
)(TextinputDesktop);
