import {compose, composeU} from '@bem-react/core';

import {
    RadioButton as RadioButtonDesktop,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/RadioButton/desktop';

import {withViewDL} from './view/dl';

export const RadioButton = compose(
    withSizeM,
    composeU(withViewDefault, withViewDL)
)(RadioButtonDesktop);
