import React, {useCallback, useState} from 'react';
import b_ from 'b_';

import {RootGoal, SurveysParam} from 'client/common/types';

import {openLink} from 'client/common/utils/window';
import {sendGoal, sendParams} from 'client/common/utils/metrika';
import {Button} from 'client/common/components/lego';

import I18N from 'assets/i18n/root';

import './feature-list.css';

const b = b_.with('feature-list');

const i18n = I18N.featureList;

type Features = typeof i18n.features;

type Feature = keyof Omit<Features, 'order'>;
type FeatureWithLink = keyof Pick<
    Features,
    | 'create_own'
    | 'creatives'
    | 'ux'
    | 'brand_awareness'
    | 'customer_awareness'
    | 'brand_concept'
    | 'crowd'
>;

const featuresWithLink: Feature[] = [
    'create_own',
    'creatives',
    'ux',
    'brand_awareness',
    'customer_awareness',
    'brand_concept',
    'crowd'
];

const listArray = i18n.features.order.split(',').map((str: string) => str.trim() as Feature);

export default function FeatureList() {
    const [selected, setSelected] = useState<Feature>(listArray[0]);

    const card = i18n.features[selected];

    const handleClick = (e: any) => {
        setSelected(e.target.dataset.key);

        e.target.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    };

    const handleFeatureLinkClick = useCallback(() => {
        sendParams([SurveysParam.SelectedCategory, selected]);
        sendGoal(RootGoal.ClickFeatureLink);

        openLink(i18n.featureLinks[selected as FeatureWithLink].link);
    }, [selected]);

    return (
        <div className={b()}>
            <div className={b('bg')}>
                <div className={b('tabs-wrapper')}>
                    <div className={b('tabs')}>
                        {listArray.map(key => {
                            const entry = i18n.features[key];

                            return (
                                <span
                                    key={key}
                                    className={b('tab', {
                                        active: selected === key ? 'yes' : 'no'
                                    })}
                                    onClick={handleClick}
                                    data-key={key}
                                >
                                    {entry.label}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div className={b('wrapper')}>
                    <div className={b('description')}>
                        <div className={b('card')}>
                            <div className={b('card-title')}>{card.title}</div>
                            <div className={b('card-description')}>{card.descr}</div>
                            {featuresWithLink.includes(selected) && (
                                <Button
                                    size='menu'
                                    view='blue'
                                    type='link'
                                    className={b('card-link')}
                                    onClick={handleFeatureLinkClick}
                                >
                                    {i18n.featureLinks[selected as FeatureWithLink].text}
                                </Button>
                            )}
                        </div>
                        <div className={b('media', {type: 'video' in card ? 'video' : 'image'})}>
                            {'video' in card ? (
                                <iframe
                                    src={card.video}
                                    allow='autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media'
                                    frameBorder='0'
                                    scrolling='no'
                                    allowFullScreen
                                />
                            ) : (
                                <img src={card.image} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
