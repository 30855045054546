import React, {memo} from 'react';

import {ChatProps} from './types';

import {WidgetContextProvider} from './context';

import {WidgetMount} from './mount';

function Chat(props: ChatProps) {
    const {experiments, from} = props;

    return (
        <WidgetContextProvider>
            <WidgetMount experiments={experiments} from={from} />
        </WidgetContextProvider>
    );
}

export default memo(Chat);
