import b_ from 'b_';
import React, {Component} from 'react';

import {Icon} from 'client/common/components/lego';

import './notification.css';

const b = b_.with('notification');

export interface NotificationSettings {
    timeOut: number;
    message: string;
    type: 'info' | 'error' | 'warning' | 'success';
    onRequestHide?: () => void;
    x?: 'left' | 'center' | 'right';
    y?: 'top' | 'center' | 'bottom';
}

const icons = {
    success: 'check-filled',
    error: 'error-red',
    warning: 'check-filled',
    info: 'check-filled'
};

export default class Notification extends Component<NotificationSettings> {
    static defaultProps = {
        type: 'info',
        message: 'alert',
        timeOut: 3000,
        onRequestHide: () => {}
    };
    timer: any;

    componentDidMount = () => {
        const {timeOut} = this.props;
        const {onEndTimeOut} = this;

        if (timeOut) {
            this.timer = setTimeout(onEndTimeOut, timeOut);
        }
    };

    componentWillUnmount = () => {
        const {timer} = this;

        if (timer) {
            clearTimeout(timer);
        }
    };

    requestHide = () => {
        const {onRequestHide} = this.props;

        if (onRequestHide) {
            onRequestHide();
        }
    };

    onClickCloser = () => {
        this.requestHide();
    };

    onEndTimeOut = () => {
        this.requestHide();
    };

    render() {
        const {message, type, x = 'center', y = 'bottom'} = this.props;
        const {onClickCloser} = this;

        return (
            <div className={b({theme: type, x, y})}>
                {icons[type] && (
                    <Icon className={b('icon')} type={icons[type]} size='m' />
                )}
                {message}
                <div className={b('closer')} onClick={onClickCloser} />
            </div>
        );
    }
}
