import React from 'react';

import type {UserStore} from 'client/common/store/reducers/user/types';

const UserContext = React.createContext<UserStore | undefined>(undefined);

export function useUser() {
    return React.useContext(UserContext);
}

export const UserProvider = UserContext.Provider;
