import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './help.css';

export interface IconTypeHelpProps {
    type?: 'help';
}

export const withTypeHelp = withBemMod<IconTypeHelpProps>(cnIcon(), {type: 'help'});
