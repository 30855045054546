import {useCallback} from 'react';
import {QueryKey, UseQueryResult, useQueryClient, useMutation, useQuery} from 'react-query';

import {
    SurveyPrice,
    SurveySlug,
    Wallet,
    PaymentStatus,
    InterviewBalance,
    BillingPayer
} from 'client/common/types';

import {
    createPaymentLink,
    createSurveyInterviewPayLink,
    createWalletTopUpLink,
    getPayers,
    getSurveyPaymentStatus,
    getSurveyPrice,
    getWalletBalance,
    payWithWallet,
    payInterviewsWithWallet,
    getInterviewBalance,
    createWalletInterviewLink,
    topUpInterviewsFromWallet,
    payFromInterviewsWallet,
    refundSurvey,
    getUnlockedSurveysStatistics
} from 'client/common/utils/api-client';
import {onRequestSettled} from './utils';

export function getPriceKey(slug: SurveySlug): QueryKey {
    return ['price', slug];
}

export function getUnlockedSurveysStatisticsKey(slug: SurveySlug): QueryKey {
    return ['statistics', slug];
}

export function getPaymentStatusKey(slug: SurveySlug): QueryKey {
    return ['payment', slug, 'status'];
}

export function getPayersKey(): QueryKey {
    return ['payers'];
}

export function getWalletBalanceKey(): QueryKey {
    return ['wallet', 'balance'];
}

export function usePrice(slug: SurveySlug): UseQueryResult<SurveyPrice> {
    return useQuery(getPriceKey(slug), async () => {
        const {data} = await getSurveyPrice(slug);

        return data;
    });
}

export function useSurveyStatistics(slug: SurveySlug, condition: boolean) {
    const isQueryDisabled = condition;

    return useQuery(
        getUnlockedSurveysStatisticsKey(slug),
        async () => {
            const {data} = await getUnlockedSurveysStatistics({slug});

            return data;
        },
        {
            enabled: isQueryDisabled
        }
    );
}

export function usePayers(): UseQueryResult<BillingPayer[]> {
    return useQuery(getPayersKey(), async () => {
        const {data} = await getPayers();

        return data;
    });
}

export function useCreatePaymentLink(slug: SurveySlug) {
    const {mutateAsync} = useMutation(createPaymentLink, {onSettled: onRequestSettled});

    return useCallback(
        async (payer?: BillingPayer) => {
            const params = {slug, payer};

            const {data} = await mutateAsync(params);

            return data.redirect;
        },
        [slug, mutateAsync]
    );
}

export function usePaymentStatus(slug: SurveySlug): UseQueryResult<PaymentStatus> {
    const key = getPaymentStatusKey(slug);

    return useQuery(key, async () => {
        const {data} = await getSurveyPaymentStatus({slug});

        return data.status;
    });
}

export function useRefund(slug: SurveySlug) {
    const {mutateAsync} = useMutation(refundSurvey);

    return useCallback(async () => {
        const params = {slug};

        return mutateAsync(params);
    }, [mutateAsync, slug]);
}

export function useWallet(): UseQueryResult<Wallet> {
    return useQuery(getWalletBalanceKey(), async () => {
        const {data} = await getWalletBalance();

        return data;
    });
}

export function useInterviewBalance(): UseQueryResult<InterviewBalance> {
    return useQuery('interview', async () => {
        const {data} = await getInterviewBalance();

        return data;
    });
}

export function useCreateWalletTopUpLink() {
    const {mutateAsync} = useMutation(createWalletTopUpLink);

    return useCallback(
        async (amount: number, payer?: BillingPayer) => {
            const retpath = location.href;

            const params = {amount, payer, retpath};
            const {data} = await mutateAsync(params);

            return data.redirect;
        },
        [mutateAsync]
    );
}

export function usePayWithWallet(slug: SurveySlug) {
    const walletBalanceKey = getWalletBalanceKey();
    const paymentStatusKey = getPaymentStatusKey(slug);

    const queryClient = useQueryClient();

    const {mutateAsync} = useMutation(payWithWallet, {
        onSuccess: async () => {
            await queryClient.refetchQueries(walletBalanceKey);
            await queryClient.refetchQueries(paymentStatusKey);
        }
    });

    return useCallback(async () => {
        const params = {slug};

        return mutateAsync(params);
    }, [mutateAsync, slug]);
}

export function usePayInterviewsWithWallet(slug: string, count: number) {
    const walletBalanceKey = getWalletBalanceKey();
    const paymentStatusKey = getPaymentStatusKey(slug);

    const queryClient = useQueryClient();

    const {mutateAsync} = useMutation(payInterviewsWithWallet, {
        onSuccess: async () => {
            await queryClient.refetchQueries(walletBalanceKey);
            await queryClient.refetchQueries(paymentStatusKey);
        }
    });

    return useCallback(async () => {
        const params = {slug, count};

        return mutateAsync(params);
    }, [mutateAsync, slug, count]);
}

export function usePayForInterview(slug: SurveySlug) {
    const {mutateAsync} = useMutation(createSurveyInterviewPayLink);

    return useCallback(
        async ({interviewCount, payer}: {interviewCount: number; payer?: BillingPayer}) => {
            const retpath = location.href;
            const params = {slug, interviewCount, payer, retpath};

            const {data} = await mutateAsync(params);

            return data.redirect;
        },
        [mutateAsync, slug]
    );
}

export function usePayForWalletInterview() {
    const {mutateAsync} = useMutation(createWalletInterviewLink);

    return useCallback(
        async ({amount, payer}: {amount: number; payer?: BillingPayer}) => {
            const retpath = location.href;
            const params = {amount, payer, retpath};

            const {data} = await mutateAsync(params);

            return data.redirect;
        },
        [mutateAsync]
    );
}

export function useTopUpInterviewsFromWallet(count: number) {
    const walletBalanceKey = getWalletBalanceKey();

    const queryClient = useQueryClient();

    const {mutateAsync} = useMutation(topUpInterviewsFromWallet, {
        onSuccess: async () => {
            await queryClient.refetchQueries(walletBalanceKey);
        }
    });

    return useCallback(async () => {
        const params = {interview_count: count};

        return mutateAsync(params);
    }, [mutateAsync, count]);
}

export function usePayFromWalletInterview() {
    const {mutateAsync} = useMutation(payFromInterviewsWallet);

    return useCallback(
        async ({
            finished,
            unfinished,
            slug
        }: {
            finished: number;
            unfinished: number;
            slug: string;
        }) => {
            const retpath = location.href;
            const params = {finished, unfinished, slug, retpath};

            const {data} = await mutateAsync(params);

            return data.redirect;
        },
        [mutateAsync]
    );
}
