import {compose, composeU} from '@bem-react/core';

import {
    Link as LinkDesktop,
    withPseudo,
    withThemeBlack,
    withThemeStrong,
    withViewDefault
} from '@yandex-lego/components/Link/desktop';

export const Link = compose(
    withPseudo,
    composeU(withThemeBlack, withThemeStrong),
    withViewDefault
)(LinkDesktop);
