import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './success.css';

export interface ButtonViewSuccessProps {
    view?: 'success';
}

export const withViewSuccess = withBemMod<ButtonViewSuccessProps>(cnButton(), {view: 'success'});
