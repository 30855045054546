import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './lock-closed.css';

export interface IconTypeLockedProps {
    type?: 'locked';
}

export const withTypeLocked = withBemMod<IconTypeLockedProps>(cnIcon(), {type: 'locked'});
