import {withBemMod} from '@bem-react/core';

import {cnRadioButton} from '@yandex-lego/components/RadioButton';

import './dl.css';

export interface MenuViewDLProps {
    view?: 'dl';
}

export const withViewDL = withBemMod<MenuViewDLProps>(cnRadioButton(), {view: 'dl'});
