import cloneDeep from 'lodash/cloneDeep';

import {AudienceInterestInfo, AudienceInterestValue} from 'client/common/types';
import {Option} from 'client/common/components/inline-select/types';

interface AudienceInterestInfoWithChildren extends AudienceInterestInfo {
    children?: AudienceInterestInfoWithChildren[];
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TargetingInterests {
    static getInterestLabel(
        interests: AudienceInterestInfo[],
        selected: AudienceInterestValue
    ): string {
        return interests.find(({id}) => id === selected)?.name.toLowerCase() ?? '';
    }

    static getInterestOptions(interests: AudienceInterestInfo[]): Option[] {
        const nestedInterests = TargetingInterests.getNestedInterests(interests);

        const options: Option[] = [];

        nestedInterests.forEach((interest, interestIndex) => {
            options.push({
                value: interest.id,
                label: `${interestIndex + 1}. ${interest.name}`
            });

            const rest =
                interest.children
                    ?.map((item, itemIndex) => ({
                        prefix: `${interestIndex + 1}. ${itemIndex + 1}.`,
                        item
                    }))
                    .reverse() ?? [];

            while (rest.length > 0) {
                const {prefix, item} = rest.pop()!;

                options.push({
                    value: item.id,
                    label: `${prefix} ${item.name}`
                });

                if (item.children) {
                    const children = item.children
                        .map((child, childIndex) => ({
                            prefix: `${prefix} ${childIndex + 1}.`,
                            item: child
                        }))
                        .reverse();

                    rest.push(...children);
                }
            }
        });

        return options;
    }

    private static getNestedInterests(
        interests: AudienceInterestInfo[]
    ): AudienceInterestInfoWithChildren[] {
        const indexMapping = interests.reduce((acc: any, interest, index) => {
            acc[interest.id] = index;

            return acc;
        }, {});

        const nestedInterests: AudienceInterestInfoWithChildren[] = cloneDeep(interests);

        for (const interest of nestedInterests) {
            if (!interest.parentId) {
                continue;
            }

            const parent = nestedInterests[indexMapping[interest.parentId]];

            parent.children = parent.children ? [...parent.children, interest] : [interest];
        }

        return nestedInterests.filter(interest => !interest.parentId);
    }
}
