import React, {Fragment} from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/brandlift-project';

import {useUser} from 'client/common/contexts/user';
import {useIsAuthorized} from 'client/common/hooks/user';
import {SurveysGoal} from 'client/common/types';
import {sendGoal} from 'client/common/utils/metrika';

import {Button, Icon} from 'client/common/components/lego';
import {WidgetButton} from 'client/common/components/chat';
import HelpCreate from 'client/common/components/help-create';
import LangSwitcher from 'client/common/components/lang-switcher';
import {useModal} from 'client/common/components/modal';
import WalletButton from 'client/common/components/wallet';

import {CreativesHelpUrls} from './consts';
import {HeaderButton, HeaderProps} from './types';

const b = b_.with('header2');
const i18n = I18N.common.header2;

type HeaderButtonsProps = Pick<HeaderProps, 'boxType'> & {
    buttons: HeaderButton[];
    isLangSwitchable: boolean;
};

export function HeaderButtonsDesktop(props: HeaderButtonsProps) {
    const {buttons, boxType, isLangSwitchable} = props;
    const user = useUser();
    const isAuthorized = useIsAuthorized();

    const [showModal] = useModal();

    const handleShowHelpModal = () => {
        sendGoal(SurveysGoal.ClickHelpHeaderButton);

        showModal(<HelpCreate boxType={boxType} />, {withCross: true});
    };

    return (
        <Fragment>
            {isAuthorized && buttons.includes('wallet') && (
                <WalletButton className={b('button', {type: 'wallet'})} />
            )}
            {buttons.includes('help') && (
                <Button
                    className={b('button', {type: 'help', background: 'active'})}
                    iconLeft={(className: string) => {
                        return <Icon className={className} type='help' />;
                    }}
                    onClick={handleShowHelpModal}
                    size='s'
                />
            )}
            {buttons.includes('dialog') && <WidgetButton />}
            {buttons.includes('help-link') &&
                (boxType === 'creative' || boxType === 'bordomatik') && (
                    <Button
                        className={b('button', {type: 'help'})}
                        size='s'
                        view='clear'
                        type='link'
                        as='a'
                        target='_blank'
                        url={CreativesHelpUrls[boxType]}
                    >
                        {i18n.helpWithSurvey}
                    </Button>
                )}
            {isLangSwitchable && (
                <LangSwitcher className={b('button', {type: 'lang'})} lang={user?.lang ?? 'ru'} />
            )}
        </Fragment>
    );
}

export function HeaderButtonsDevice(props: HeaderButtonsProps) {
    const {buttons} = props;

    const isAuthorized = useIsAuthorized();

    return (
        <Fragment>
            {isAuthorized && buttons.includes('wallet') && (
                <WalletButton className={b('button', {type: 'wallet'})} />
            )}
        </Fragment>
    );
}
