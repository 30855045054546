import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './close.css';

export interface IconTypeCloseProps {
    type?: 'close';
}

export const withTypeClose = withBemMod<IconTypeCloseProps>(cnIcon(), {type: 'close'});
