import React, {useState, useEffect} from 'react';

import {BillingPayer, SurveysGoal} from 'client/common/types';
import {sendGoal} from 'client/common/utils/metrika';

import EditPage from './edit-page';
import ChoosePage from './choose-page';
import PaymentPage from './payment-page';

import {
    Page,
    convertFromBillingPayerFields,
    convertPayerToBillingFields,
    getInitialPayersList
} from './utils';

import {Payer, PayerCountry} from './types';

import './card-payment-form.css';

interface Props {
    createPaymentLink: (payer: BillingPayer) => Promise<string>;
    onError: (err: any) => void;
    onFinish: () => void;
    payers?: BillingPayer[];
    userCountry: PayerCountry;
}

const paymentStatusMessage = {
    complete: 'payment:complete',
    fail: 'payment:fail'
};

/* eslint max-statements:0 */
export default function CardPaymentForm(props: Readonly<Props>) {
    const {createPaymentLink, onError, onFinish, payers = [], userCountry} = props;

    const [page, setPage] = useState(Page.Edit);
    const [cardPayUrl, setCardPayUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [payersList, setPayersList] = useState(getInitialPayersList(userCountry));

    useEffect(() => {
        if (payers.length > 0) {
            setPayersList(payers.map(convertFromBillingPayerFields));
        }
    }, [payers]);

    useEffect(() => {
        const handleFrameMessages = (e: MessageEvent) => {
            if (paymentStatusMessage.complete === e.data) {
                sendGoal(SurveysGoal.MakePaymentSuccess);
            }
            if ([paymentStatusMessage.complete, paymentStatusMessage.fail].includes(e.data)) {
                onFinish();
            }
        };

        window.addEventListener('message', handleFrameMessages);

        return () => {
            window.removeEventListener('message', handleFrameMessages);
        };
    }, [onFinish]);

    const handlePay = async (payer: Payer) => {
        setLoading(true);
        const data = convertPayerToBillingFields(payer, userCountry);

        try {
            const redirect = await createPaymentLink(data);

            setCardPayUrl(redirect);
            setPage(Page.Payment);
        } catch (err) {
            onError(err);
        }
    };

    const handleChoosePageSave = (payerId: string) => {
        setPayersList(
            payersList.map(payr => {
                return {...payr, isSelected: payr.id === payerId};
            })
        );
        setPage(Page.Edit);
    };

    const handlePayerInput = (value: string, name: string, id: string) => {
        setPayersList(
            payersList.map(payr => {
                return payr.id === id ? {...payr, [name]: value} : payr;
            })
        );
    };

    const selectedPayer = payersList.find(payr => payr.isSelected) || payersList[0];

    if (page === Page.Edit) {
        return (
            <EditPage
                payer={selectedPayer}
                onClickPay={handlePay}
                loading={loading}
                onClickChoose={() => setPage(Page.Choose)}
                isMultipleAccs={payersList.length > 1}
                handlePayerInput={handlePayerInput}
                userCountry={userCountry}
            />
        );
    }

    if (page === Page.Choose) {
        return (
            <ChoosePage
                payersList={payersList}
                onSave={handleChoosePageSave}
                userCountry={userCountry}
            />
        );
    }

    return <PaymentPage source={cardPayUrl} />;
}
