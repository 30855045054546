import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './image.css';

export interface IconTypeImageProps {
    type?: 'image';
}

export const withTypeImage = withBemMod<IconTypeImageProps>(cnIcon(), {type: 'image'});
