import React from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import Ava1 from './avatar1.png';
import Ava2 from './avatar2.png';
import Ava3 from './avatar3.png';
import Ava4 from './avatar4.png';
import Ava5 from './avatar5.png';
import Ava6 from './avatar6.png';

import './reviews.css';

const i18n = I18N.reviews;
const b = b_.with('reviews');

interface ProfileProps {
    photo: string;
    name: string;
    role: string;
    company: string;
}

export const Profile = ({photo, name, role, company}: ProfileProps) => {
    return (
        <div className={b('profile')}>
            <img className={b('profile-photo')} src={photo} alt='' />
            <div className={b('profile-name')}>{name}</div>
            <div className={b('profile-text')}>
                {role}, <cite className={b('profile-company')}>{company}</cite>
            </div>
        </div>
    );
};

const PHOTO_MAP = {
    hoff: Ava1,
    vtb: Ava2,
    avito: Ava3,
    fsk: Ava4,
    ugoria: Ava5,
    renins: Ava6
};

type CompaniesReview = keyof typeof PHOTO_MAP;

const Reviews = () => {
    const handleClick = (e: any) => {
        e.target.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    };

    return (
        <div className={b()}>
            <h3 className={b('title')}>{i18n.title}</h3>
            <div className={b('cards-list-wrapper')}>
                <ul className={b('cards-list')}>
                    {i18n.list.order
                        .split(',')
                        .map((name: string) => name.trim())
                        .map((companyName: CompaniesReview) => {
                            const review = i18n.list[companyName];

                            return (
                                <li className={b('card')} key={companyName} onClick={handleClick}>
                                    <figure>
                                        <blockquote className={b('card-text')}>
                                            {review.text}
                                        </blockquote>
                                        <figcaption className={b('card-profile')}>
                                            <Profile
                                                photo={PHOTO_MAP[companyName]}
                                                name={review.name}
                                                role={review.role}
                                                company={review.companyName}
                                            />
                                        </figcaption>
                                    </figure>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default Reviews;
