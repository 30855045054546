import React from 'react';

import {ChatProps} from './types';

import { useWidgetContext } from './context';

export const WidgetMount: React.FC<ChatProps> = ({experiments, from}) => {

    const [messagesCount, setMessagesCount] = React.useState<number>(0);

    const { ui, widget } = useWidgetContext();

    React.useEffect(() => {
        
        ui.mount();
        widget.events.sendMessage.addListener(() => setMessagesCount(prev => prev + 1));

        return ():void => {
            widget.events.sendMessage.removeAllListener();
            widget.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect( () => {
        if (messagesCount === 1){
            void widget.api.serviceMeta(
                {
                    meta: [
                        `from: ${from}`,
                        experiments && `experiments: ${JSON.stringify(experiments, null, 2)}`
                    ]
                        .filter(Boolean)
                        .join(',')
                });
        }
    }, [messagesCount, experiments, widget.api, from]);
    
    return null;
};
