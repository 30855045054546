import React, {useState} from 'react';
import emailValidator from 'email-validator';

import b_ from 'b_';

import {Button, Textinput, Checkbox} from 'client/common/components/lego';

import {tld} from 'client/common/utils/tld';

import I18N from 'assets/i18n/landings';

import './subscribe-modal.css';

const b = b_.with('subscribe-modal');

const i18n = I18N.new.subscribeModal;

export interface SubscribeModalProps {
    defaultEmail?: string;
    onClose?: () => void;
    onSubscribe: (email: string) => Promise<void>;
}

export default function SubscribeModal({
    defaultEmail = '',
    onClose,
    onSubscribe
}: SubscribeModalProps) {
    const [isAgreed, setIsAgreed] = useState(false);

    const [email, setEmail] = useState<string>(defaultEmail);
    const [error, setError] = useState<string>();
    const [isEditing, setIsEditing] = useState(false);

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newEmail = e.target.value;

        setEmail(newEmail);

        const isNewEmailValid = emailValidator.validate(newEmail);

        setError(isNewEmailValid ? undefined : i18n.error.invalidEmail);
    }

    async function onClickSubscribe() {
        await onSubscribe(email);

        if (onClose) {
            onClose();
        }
    }

    return (
        <div className={b()}>
            <h2 className={b('title')}>{i18n.title}</h2>
            <Textinput
                className={b('input')}
                placeholder={i18n.placeholder.email}
                value={email}
                size='m'
                view='default'
                onChange={onChange}
                onBlur={() => setIsEditing(false)}
                onFocus={() => setIsEditing(true)}
                hint={isEditing ? undefined : error}
                state={error ? 'error' : undefined}
            />
            <label className={b('agreement')}>
                <Checkbox
                    onChange={event => setIsAgreed(event.target.checked)}
                    size='m'
                    checked={isAgreed}
                    view='default'
                />
                <span className={b('agreement-label')}>
                    {/* @ts-ignore */}
                    {i18n.agreement.label.replace('%s', i18n.company[tld] || i18n.company.ru)}{' '}
                    <a
                        href='https://yandex.ru/legal/confidential'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {i18n.agreement.confidential}
                    </a>
                </span>
            </label>
            <Button
                className={b('button')}
                size='m'
                view='action'
                disabled={!isAgreed || !email || Boolean(error)}
                onClick={onClickSubscribe}
            >
                {i18n.button.subscribe}
            </Button>
        </div>
    );
}
