import uniqueId from 'lodash/uniqueId';
import {ReactNode, useState, useCallback} from 'react';
import {useQuery} from 'react-query';
import {queryClient} from '../utils/react-query';

const MODAL_KEY = 'modal';

export const MODAL_LEVELS = {
    Popup: 1000,
    PopupOverPopup: 2000
};

export type ModalLevel = keyof typeof MODAL_LEVELS;

export type ModalOptions = Partial<{
    className: string;
    hideByOutsideClick: boolean;
    /**
     * Вызывается на закрытии модального окна
     */
    onClose: () => void;
    /**
     * Вызывается при нажатии на крестик или клик вне окна (при hideByOutsideClick = true)
     * Если вернет false - закрытие окна не случится
     * @returns false | void
     */
    onBeforeClose: () => false | void;
    wide: boolean;
    withCross: boolean;
    centered: boolean;
    level: ModalLevel;
}>;

interface ModalInfo {
    id: string;
    children: ReactNode;
    options?: ModalOptions;
}

type ModalState = ModalInfo[];

const noop = async (): Promise<ModalState> => {
    return Promise.resolve([]);
};

export function useLastModal(level: ModalLevel): ModalInfo | undefined {
    const {data} = useQuery(MODAL_KEY, noop, {enabled: false, initialData: []});

    if (!data) {
        return;
    }

    return data.filter(({options}) => (options?.level || 'Popup') === level).pop();
}

export function useModal(): [
    (children: ReactNode, options?: ModalOptions) => void,
    (beforeClose?: () => false | void) => void,
    string
] {
    const [key] = useState(uniqueId);

    const show = useCallback((children: ReactNode, options?: ModalOptions) => {
        showModal(key, children, options);
    }, [key]);

    const hide = useCallback((beforeClose?: () => false | void) => {
        hideModal(key, beforeClose);
    }, [key]);

    return [show, hide, key];
}

export function showModal(id: string, children: ReactNode, options?: ModalOptions) {
    queryClient.setQueryData(MODAL_KEY, (old: ModalState) => old.concat({id, children, options}));
}

export function hideModal(id: string, beforeClose?: () => false | void) {
    if (typeof beforeClose === 'function' && beforeClose() === false) return;

    queryClient.setQueryData(MODAL_KEY, (old: ModalState) => {
        old.find(({id: modalId}) => modalId === id)?.options?.onClose?.();

        return old.filter(({id: modalId}) => modalId !== id);
    });
}
