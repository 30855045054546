import {withBemMod} from '@bem-react/core';

import {cnCheckbox} from '@yandex-lego/components/Checkbox';

import './dl.css';

export interface MenuViewDLProps {
    view?: 'dl';
}

export const withViewDL = withBemMod<MenuViewDLProps>(cnCheckbox(), {view: 'dl'});
