import React from 'react';
import b_ from 'b_';

import {useModal} from 'client/common/hooks/modal';
import {useUpdateUserProfile} from 'client/common/hooks/user';

import {Button, Icon} from 'client/common/components/lego';
import SubscribeModal from 'client/common/components/subscribe-modal';
import {SubscribeModalProps} from 'client/common/components/subscribe-modal/subscribe-modal';

import I18N from 'assets/i18n/landings';

import './subscribe-notification.css';

const b = b_.with('subscribe-notification');

const i18n = I18N.new.subscribeNotification;

export interface SubscribeNotificationProps {
    defaultEmail?: SubscribeModalProps['defaultEmail'];
}

export default function SubscribeNotification({defaultEmail}: SubscribeNotificationProps) {
    const [showModal, hideModal] = useModal();

    const updateUserProfile = useUpdateUserProfile();

    const subscribeUser = async (email: string) => {
        await updateUserProfile({email, receive_mailings: true});
    };

    const handleClick = () => {
        showModal(
            <SubscribeModal
                onSubscribe={subscribeUser}
                defaultEmail={defaultEmail}
                onClose={hideModal}
            />,
            {
                withCross: true
            }
        );
    };

    return (
        <div className={b()}>
            <Icon size='m' type='magic' className={b('icon')} />
            <span className={b('label')}>{i18n.label}</span>
            <Button className={b('button')} size='s' view='light' onClick={handleClick}>
                {i18n.subscribe}
            </Button>
        </div>
    );
}
