import React from 'react';
import b_ from 'b_';
import {User} from '@yandex-lego/user/desktop';

import {SurveysGoal} from 'client/common/types';
import {sendGoal} from 'client/common/utils/metrika';
import {tld} from 'client/common/utils/tld';
import {useUser} from 'client/common/contexts/user';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';
import {UserStaff} from 'client/common/components/user/user-staff';

import './userpic.css';

const b = b_.with('userpic');

export function UserPic() {
    const user = useUser();
    const login = user?.info?.login;

    if (login === undefined) {
        return null;
    }

    const lang = user?.lang ?? 'ru';

    const handleLogin = () => {
        sendGoal(SurveysGoal.ClickHeaderEnter);
    };

    return isYandexTeamDomain ? (
        <div className={b('box')}>
            <UserStaff login={login} />
        </div>
    ) : (
        <div className={b('user')}>
            <User
                avatarId={user?.info?.avatar.default}
                tld={tld}
                lang={lang}
                fetchMail
                plus
                onClick={handleLogin}
            />
        </div>
    );
}
