import React, {ReactNode} from 'react';
import b_ from 'b_';

import {RootGoal} from 'client/common/types';

import I18N from 'assets/i18n/root';

import {TryBtn} from '../buttons';

import './main-slide.css';

const b = b_.with('main-slide');

const i18n = I18N.mainSlide;

const MainSlide = ({
    children,
    withNotification
}: {
    children: ReactNode;
    withNotification: boolean;
}) => {
    return (
        <div className={b()}>
            <div className={b('header', {notification: withNotification ? 'yes' : 'no'})}>
                {children}
            </div>
            <div className={b('wrapper')}>
                <h3 className={b('title')}>{i18n.title}</h3>
                <div className={b('text')}>&nbsp;</div>
                <TryBtn goal={RootGoal.ClickLandingTry} />
            </div>
        </div>
    );
};

export default MainSlide;
