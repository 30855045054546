import {compose} from '@bem-react/core';

import {
    Tooltip as TooltipDesktop,
    TooltipStateful as TooltipStatefulDesktop,
    withSizeS,
    withViewDefault
} from '@yandex-lego/components/Tooltip/desktop';

import './index.css';

export const Tooltip = compose(withSizeS, withViewDefault)(TooltipDesktop);

export const TooltipStateful = compose(withSizeS, withViewDefault)(TooltipStatefulDesktop);
