import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './random.css';

export interface IconTypeRandomProps {
    type?: 'random';
}

export const withTypeRandom = withBemMod<IconTypeRandomProps>(cnIcon(), {type: 'random'});
