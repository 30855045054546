import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './promo.css';

export interface ButtonSizePromoProps {
    size?: 'promo';
}

export const withSizePromo = withBemMod<ButtonSizePromoProps>(cnButton(), {size: 'promo'});
