import {SurveySlug} from 'client/common/types';
import {createUrl} from 'client/common/utils/history';

import {
    CreatePaymentLinkParams,
    CreatePaymentLinkResponse,
    CreateSurveyInterviewPayLinkParams,
    CreateSurveyInterviewPayLinkParamsResponse,
    GetSurveyInterviewCountParams,
    GetSurveyInterviewCountParamsResponse,
    GetSurveyPaymentStatusResponse,
    GetSurveyPriceResponse,
    PayFromInterviewsWalletParams,
    PayFromInterviewsWalletResponse,
    PayInterviewsWithWalletParams,
    PayInterviewWithWalletResponse,
    PayWithWalletParams,
    PayWithWalletResponse,
    RefundSurveyParams,
    RefundSurveyResponse,
    SetSurveyTotalCostParams
} from '../types';
import {_doRequest} from '../base';

/** Возврат денег за опрос */
export const refundSurvey = async (params: RefundSurveyParams): Promise<RefundSurveyResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/payment/refund`
    };

    return _doRequest(requestParams);
};

/** Получаем ссылку на оплату опроса */
export const createPaymentLink = async (
    params: CreatePaymentLinkParams
): Promise<CreatePaymentLinkResponse> => {
    const {slug, payer} = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/payment`,
        data: {
            retpath: createUrl({link: `/qs/${slug}`, relative: true}),
            payer
        }
    };

    return _doRequest(requestParams);
};

export const getSurveyPaymentStatus = async (params: {
    slug: string;
}): Promise<GetSurveyPaymentStatusResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `survey/${slug}/payment/status`
    };

    return _doRequest(requestParams);
};

/** Оплачиваем опрос с кошелька */
export const payWithWallet = async (
    params: PayWithWalletParams
): Promise<PayWithWalletResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/payment/wallet`
    };

    return _doRequest(requestParams);
};

/** Оплачиваем интервью с кошелька */
export const payInterviewsWithWallet = async (
    params: PayInterviewsWithWalletParams
): Promise<PayInterviewWithWalletResponse> => {
    const {slug, count} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/payment/wallet/interview`,
        data: {count}
    };

    return _doRequest(requestParams);
};

export const getSurveyPrice = async (slug: SurveySlug): Promise<GetSurveyPriceResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `survey/${slug}/payment/cost`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

/** Оплачиваем интервью с кошелька интервью */
export const payFromInterviewsWallet = async (
    params: PayFromInterviewsWalletParams
): Promise<PayFromInterviewsWalletResponse> => {
    const {finished, unfinished, slug} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/payment/wallet-interview`,
        data: {finished, unfinished}
    };

    return _doRequest(requestParams);
};

/** Получаем ссылку на оплату интервью */
export const createSurveyInterviewPayLink = async (
    params: CreateSurveyInterviewPayLinkParams
): Promise<CreateSurveyInterviewPayLinkParamsResponse> => {
    const {payer, retpath, interviewCount, slug} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/payment/interview`,
        data: {interviewCount, payer, retpath}
    };

    return _doRequest(requestParams);
};

export const getPrice = async ({
    slug,
    apiLocation = 'survey'
}: {
    slug: SurveySlug;
    apiLocation: 'survey' | 'public';
}) => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `${apiLocation}/${slug}/payment/cost`
    };

    return _doRequest(requestParams);
};

export const setSurveyTotalCost = async (params: SetSurveyTotalCostParams) => {
    const {totalCost, slug} = params;

    const requestParams = {
        method: 'PUT',
        url: `survey/${slug}/payment/cost/totalCost`,
        data: {totalCost}
    };

    return _doRequest(requestParams);
};

export const getSurveyInterviewCount = async (
    params: GetSurveyInterviewCountParams
): Promise<GetSurveyInterviewCountParamsResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'GET',
        url: `survey/${slug}/payment/interview`
    };

    return _doRequest(requestParams);
};
