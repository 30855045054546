import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './price.css';

export interface IconTypePriceProps {
    type?: 'price';
}

export const withTypePrice = withBemMod<IconTypePriceProps>(cnIcon(), {type: 'price'});
