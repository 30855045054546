import React from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';
import {createUrl} from 'client/common/utils/history';
import {tld} from 'client/common/utils/tld';

import './page-footer.css';

const b = b_.with('page-footer');

const i18n = I18N.footer;

interface TranslationLink {
    text: string;
    url: string;
}

const Link = ({
    link,
    target = undefined,
    rel = undefined
}: {
    link: TranslationLink;
    target?: string;
    rel?: string;
}) => {
    return (
        <a target={target} href={createUrl({link: link.url.replace('%tld', tld)})} rel={rel}>
            {link.text}
        </a>
    );
};

export const PageFooter = () => {
    return (
        <div className={b()}>
            <ul className={b('items-list')}>
                <li>
                    <ul className={b('list')}>
                        <li>
                            <h5 className={b('title')}>{i18n.research.title}</h5>
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.research.catalog} />
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.articles} />
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.articles.news} />
                        </li>
                    </ul>
                </li>
                <li>
                    <ul className={b('list')}>
                        <li>
                            <h5 className={b('title')}>{i18n.help.title}</h5>
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.help.howto} />
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.help.audience} />
                        </li>
                        <li className={b('text')}>
                            <Link link={i18n.help.payment} />
                        </li>
                    </ul>
                </li>
                <li>
                    <ul className={b('list')}>
                        <li>
                            <h5 className={b('title')}>{i18n.company.title}</h5>
                        </li>
                        {/* <li className={b('text')}>
                            <Link i18n={i18n.company.vacancies}></Link>
                        </li> */}
                        <li className={b('text')}>
                            <Link link={i18n.company.email} />
                        </li>
                    </ul>
                </li>
                <ul className={b('list')}>
                    <li className={b('copyright')}>
                        {i18n.copyright.before}
                        <Link link={i18n.copyright.link} />
                    </li>
                    <li className={b('text')}>
                        <Link
                            link={i18n.recommendations.rules}
                            target='_blank'
                            rel='noopener noreferrer'
                        />
                    </li>
                </ul>
            </ul>
        </div>
    );
};
