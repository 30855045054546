export enum Device {
    Mobile = 'MOBILE',
    Tablet = 'TABLET',
    Desktop = 'DESKTOP',
    Widescreen = 'WIDESCREEN'
}

export const DEVICE_WIDTHS = {
    [Device.Mobile]: 640,
    [Device.Tablet]: 960,
    [Device.Desktop]: 1366
};

export const PortableDevices = [Device.Mobile, Device.Tablet];

export function getDeviceByWidth(width: number | null): Device {
    const deviceWidth = width ?? DEVICE_WIDTHS[Device.Desktop];

    return (
        [Device.Mobile, Device.Tablet, Device.Desktop].find(
            (device: Device.Mobile | Device.Tablet | Device.Desktop) =>
                DEVICE_WIDTHS[device] >= deviceWidth
        ) ?? Device.Widescreen
    );
}
