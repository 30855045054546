import {withBemMod} from '@bem-react/core';

import {cnTextinput} from '@yandex-lego/components/Textinput';

import './dl.css';

export interface TextinputViewDLProps {
    view?: 'dl';
}

export const withViewDL = withBemMod<TextinputViewDLProps>(cnTextinput(), {
    view: 'dl'
});
