import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './check-black.css';

export interface IconTypeCheckBlackProps {
    type?: 'check-black';
}

export const withTypeCheckBlack = withBemMod<IconTypeCheckBlackProps>(cnIcon(), {type: 'check-black'});
