/* eslint-disable no-cyrillic-string/no-cyrillic-string */
import {GeoValue, BillingPayer, isPayerKZ, isPayerRU} from 'client/common/types';

import {Payer, PayerPersonalInfo, PayerFIO, PayerCountry} from './types';

export const PAYER_LABELS: {[key in keyof PayerPersonalInfo]: string} = {
    'first-name': 'Имя',
    'last-name': 'Фамилия',
    'middle-name': 'Отчество',
    email: 'Почта',
    phone: 'Телефон',
    postaddress: 'Почтовый адрес',
    city: 'Город',
    'kz-in': 'Индивидуальный идентификационный номер'
};
export const PAYER_PLACEHOLDERS: {[key in keyof PayerPersonalInfo]: string} = {
    'first-name': 'Иван',
    'last-name': 'Иванов',
    'middle-name': 'Иванович',
    email: 'who@yandex.ru',
    phone: '+7',

    postaddress: 'ул. Строителей, д. 123, корп. 4, кв. 567',
    city: 'Алматы',
    'kz-in': '123456789012'
};
export const PAYER_INPUT_TYPES: {[key in keyof PayerPersonalInfo]: string} = {
    'first-name': 'text',
    'last-name': 'text',
    'middle-name': 'text',
    email: 'email',
    phone: 'tel',

    postaddress: 'text',
    city: 'text',
    'kz-in': 'text'
};

export const getPayerFields = (userCountry: PayerCountry): (keyof PayerPersonalInfo)[] => {
    return userCountry === GeoValue.Russia
        ? ['last-name', 'first-name', 'middle-name', 'email', 'phone']
        : ['last-name', 'first-name', 'email', 'phone', 'postaddress', 'city', 'kz-in'];
};

// порядок в списке плательщиков
export const getPayerFieldsInForm = (userCountry: PayerCountry): (keyof PayerFIO)[] => {
    return userCountry === GeoValue.Russia
        ? ['last-name', 'first-name', 'middle-name']
        : ['last-name', 'first-name'];
};

export const getInitialPayersList = (userCountry: PayerCountry) => {
    return userCountry === GeoValue.Russia
        ? [
              {
                  id: 'user-account',
                  'first-name': '',
                  'last-name': '',
                  'middle-name': '',
                  email: '',
                  phone: ''
              } as Payer
          ]
        : [
              {
                  id: 'user-account',
                  'first-name': '',
                  'last-name': '',
                  email: '',
                  phone: '',
                  postaddress: '',
                  city: '',
                  'kz-in': ''
              } as Payer
          ];
};

export const getPayerFieldsLayout = (
    userCountry: PayerCountry
): Array<(keyof PayerPersonalInfo)[]> => {
    return userCountry === GeoValue.Russia
        ? [['last-name', 'first-name'], ['middle-name'], ['email', 'phone']]
        : [['last-name', 'first-name'], ['email', 'phone'], ['city', 'postaddress'], ['kz-in']];
};

// eslint-disable-next-line no-shadow
export enum Page {
    Edit,
    Choose,
    Payment
}

function addIfHaveKey<T extends object, TKey extends keyof T>(
    obj: T,
    objectKey: TKey,
    newObj: object,
    newKey: string
) {
    if (objectKey in obj) {
        Object.assign(newObj, {[newKey]: obj[objectKey]});
    }
}

export const convertFromBillingPayerFields = (payer: BillingPayer, index: number): Payer => {
    const {FNAME, LNAME, EMAIL, PHONE} = payer;

    const res = {
        'first-name': FNAME,
        'last-name': LNAME,

        email: EMAIL,
        phone: PHONE,
        id: `billing-account-${index}`
    } as Payer;

    if (isPayerRU(payer)) {
        addIfHaveKey(payer, 'MNAME', res, 'middle-name');
    }

    if (isPayerKZ(payer)) {
        addIfHaveKey(payer, 'CITY', res, 'city');
        addIfHaveKey(payer, 'POSTADDRESS', res, 'postaddress');
        addIfHaveKey(payer, 'KZ_IN', res, 'kz-in');
    }

    return res as Payer;
};

export const convertPayerToBillingFields = (
    payer: Payer,
    userCountry: PayerCountry
): BillingPayer => {
    const {
        'first-name': FNAME,
        'last-name': LNAME,
        'middle-name': MNAME,
        email: EMAIL,
        phone: PHONE,
        city: CITY,
        postaddress: POSTADDRESS,
        'kz-in': KZ_IN
    } = payer;

    return userCountry === GeoValue.Russia
        ? {
              TYPE: 'ph',
              FNAME,
              LNAME,
              MNAME,
              EMAIL,
              PHONE
          }
        : {
              TYPE: 'kzp',
              FNAME,
              LNAME,
              EMAIL,
              PHONE,
              CITY,
              POSTADDRESS,
              KZ_IN
          };
};

export function isObjKey<T extends object>(key: PropertyKey, obj: T): key is keyof T {
    return key in obj;
}
