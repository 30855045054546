import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './sad-face.css';

export interface IconTypeSadFaceProps {
    type?: 'sad-face';
}

export const withTypeSadFace = withBemMod<IconTypeSadFaceProps>(cnIcon(), {type: 'sad-face'});
