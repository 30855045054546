import {withBemMod} from '@bem-react/core';

import {cnTumbler} from '@yandex-lego/components/Tumbler';

import './dl.css';

export interface TumblerViewDLProps {
    view?: 'dl';
}

export const withViewDL = withBemMod<TumblerViewDLProps>(cnTumbler(), {
    view: 'dl'
});
