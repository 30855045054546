import {_doRequest} from '../../base';
import {
    CopyPageItemParams,
    CopyWidgetResponse,
    CreateWidgetParams,
    CreateWidgetResponse,
    RemoveItemParams,
    UpdateWidgetParams
} from '../../types';

export const createWidget = async (params: CreateWidgetParams): Promise<CreateWidgetResponse> => {
    const {insertAt, pageId, revisionId, slug, ...data} = params;

    const requestParams = {
        method: 'POST',
        // eslint-disable-next-line max-len
        url: `survey/${slug}/revision/${revisionId}/widget?group=${pageId}${
            insertAt === undefined ? '' : `&insertAt=${insertAt}`
        }`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
};
export const removeWidget = async ({slug, revisionId, id, force}: RemoveItemParams) => {
    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/widget/${id}?force=${force}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};
export const copyWidget = async (params: CopyPageItemParams): Promise<CopyWidgetResponse> => {
    const {slug, revisionId, id, pageId} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/widget/${id}/copy?group=${pageId}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};
export const updateWidget = async ({slug, revisionId, id, data}: UpdateWidgetParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/widget/${id}`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
};
