import {_doRequest} from '../../base';
import {
    CopyBoxCustomQuestionParams,
    CopyBoxCustomQuestionResponse,
    CreateBoxCustomQuestionParams,
    CreateBoxCustomQuestionResponse,
    RemoveBoxCustomQuestionParams,
    RemoveUxStepParams
} from '../../types';

export const createBoxCustomQuestion = async (
    params: CreateBoxCustomQuestionParams
): Promise<CreateBoxCustomQuestionResponse> => {
    const {slug, revisionId, ...data} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/box/ux`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const removeBoxCustomQuestion = async (
    params: RemoveBoxCustomQuestionParams
) => {
    const {slug, revisionId, id} = params;

    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/box/ux/${id}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const copyBoxCustomQuestion = async (
    params: CopyBoxCustomQuestionParams
): Promise<CopyBoxCustomQuestionResponse> => {
    const {slug, revisionId, id} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/box/ux/${id}/copy`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const removeUxStep = async (
    params: RemoveUxStepParams
) => {
    const {slug, revisionId, index} = params;

    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/box/ux/step/${index}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};
