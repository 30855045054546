import {SurveyPreset} from 'client/common/types/survey';

import {
    GetMetrikaCountersResponse,
    SyncAgencyClientsResponse,
    FetchPurposeConfigResponse,
    FetchAccountListResponse
} from '../types';

import {_doRequest} from '../base';

export const uploadFile = async ({file}: {file: any}) => {
    const requestParams = {
        method: 'POST',
        url: 'upload/',
        file
    };

    return _doRequest(requestParams);
};

export const syncAgencyClients = async (): Promise<SyncAgencyClientsResponse> => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: 'auth/list/agency-clients/sync'
    };

    return _doRequest(requestParams);
};

export const getMetrikaCounters = async (): Promise<GetMetrikaCountersResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'metrika/counters'
    };

    return _doRequest(requestParams);
};

export const fetchPurposeConfig = async ({
    purpose
}: {
    purpose: SurveyPreset;
}): Promise<FetchPurposeConfigResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `purpose/${purpose}`
    };

    return _doRequest(requestParams);
};

export const getAccountList = async (): Promise<FetchAccountListResponse> => {
    const requestParams = {
        method: 'GET',
        url: 'accounts'
    };

    return _doRequest(requestParams);
};
