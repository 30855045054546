import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './plus-blue.css';

export interface IconTypePlusBlueProps {
    type?: 'plus-blue';
}

export const withTypePlusBlue = withBemMod<IconTypePlusBlueProps>(cnIcon(), {type: 'plus-blue'});
