import React from 'react';

import {Button} from 'client/common/components/lego';
import {createUrl} from 'client/common/utils/history';
import {sendGoal} from 'client/common/utils/metrika';
import {RootGoal} from 'client/common/types';

import I18N from 'assets/i18n/root';

const i18n = I18N.buttons;

interface TryBtnProps {
    goal: RootGoal;
}

export const TryBtn = (props: TryBtnProps) => {
    const {goal} = props;

    const handleClick = () => {
        sendGoal(goal);
    };

    return (
        <Button
            size='promo'
            view='blue'
            type='link'
            onClick={handleClick}
            url={createUrl({link: '/quicksurvey'})}
        >
            {i18n.try}
        </Button>
    );
};
