import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './trash-small.css';

export interface IconTypeTrashSmallProps {
    type?: 'trash-small';
}

export const withTypeTrashSmall = withBemMod<IconTypeTrashSmallProps>(cnIcon(), {
    type: 'trash-small'
});
