import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './condition.css';

export interface IconTypeConditionProps {
    type?: 'condition';
}

export const withTypeCondition = withBemMod<IconTypeConditionProps>(cnIcon(), {type: 'condition'});
