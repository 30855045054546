import {useMutation, useQuery} from 'react-query';
import {useCallback} from 'react';

import {queryClient} from 'client/common/utils/react-query';
import {pushToSearch, getIntParamFromSearch} from 'client/common/utils/history';
import {
    chooseUserOrg,
    getOrgs,
    getOrgSurveys,
    OrgsResponse,
    OrgSurveyResponse,
    getOrg,
    OrgResponse
} from 'client/common/utils/api-client';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';
import {useUser} from 'client/common/contexts/user';

const ORG_PARAM_NAME = 'org';
const ORGS_KEY = 'ORGS';
const ORGS_CURRENT_KEY = [ORGS_KEY, 'current'] as const;

const getOrgKey = (orgId: number) => {
    return [ORGS_KEY, orgId] as const;
};

const getOrgSurveysKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'surveys'] as const;
};

export const getOrgUsersKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'users'] as const;
};

export const getOrgRolesKey = (orgId: number) => {
    return [ORGS_KEY, orgId, 'roles'] as const;
};

export const getOrgRoleKey = (orgId: number, roleId: number) => {
    return [ORGS_KEY, orgId, 'roles', roleId] as const;
};

export function useOrgsList() {
    return useQuery([ORGS_KEY], async (): Promise<OrgsResponse> => {
        const {data} = await getOrgs();

        return data ?? [];
    });
}

export function useOrg(orgId: number) {
    return useQuery(getOrgKey(orgId), async (): Promise<OrgResponse> => {
        const {data} = await getOrg(orgId);

        return data ?? [];
    });
}

export function useOrgSurveys(orgId: number) {
    return useQuery(getOrgSurveysKey(orgId), async (): Promise<OrgSurveyResponse> => {
        const {data} = await getOrgSurveys(orgId);

        return data ?? [];
    });
}

const noop = async () => Promise.resolve(undefined);

function setOrgIdToUrl(orgId: number | undefined) {
    pushToSearch({
        key: ORG_PARAM_NAME,
        value: orgId ? String(orgId) : undefined
    });
}

export function useCurrentOrg() {
    const orgIdFromUrl = getIntParamFromSearch(ORG_PARAM_NAME);

    const user = useUser();

    const orgIdFromDb = isYandexTeamDomain ? user?.info?.settings.selected_org_id : undefined;

    const {data} = useQuery(ORGS_CURRENT_KEY, noop, {
        enabled: false,
        initialData: orgIdFromUrl || orgIdFromDb
    });

    if (!isYandexTeamDomain) {
        return undefined;
    }

    if (orgIdFromDb && !orgIdFromUrl) {
        // дописываем в урл номер организации, если его не передали
        setOrgIdToUrl(orgIdFromDb);
    }

    return data;
}

export function getCurrentOrg(): number | undefined {
    return queryClient.getQueryData(ORGS_CURRENT_KEY);
}

export function useChooseUserOrg() {
    const {mutateAsync} = useMutation(chooseUserOrg, {
        onMutate: async (orgId: number) => {
            await queryClient.cancelQueries(ORGS_CURRENT_KEY);

            const shapshot = queryClient.getQueryData(ORGS_CURRENT_KEY);

            queryClient.setQueryData(ORGS_CURRENT_KEY, () => {
                if (orgId) {
                    return orgId;
                }

                // принудительно рефрешим, значение undefined/null не оповещают хуки-подписчики
                queryClient.refetchQueries(ORGS_CURRENT_KEY);

                return;
            });

            return shapshot;
        },
        onError: (_error, _, shapshot) => {
            queryClient.setQueryData(ORGS_CURRENT_KEY, shapshot);
        }
    });

    return useCallback(
        async (orgId: number | undefined) => {
            // json можно передавать только null, undefined недопустим
            await mutateAsync(orgId || null);

            setOrgIdToUrl(orgId || undefined);
        },
        [mutateAsync]
    );
}
