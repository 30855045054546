import React, {useRef, useMemo, useState, useLayoutEffect} from 'react';
import b_ from 'b_';
import {compose, composeU} from '@bem-react/core';

import {
    Menu as MenuDesktop,
    withSizeM,
    withViewDefault,
    withWidthAuto,
    withWidthMax
} from '@yandex-lego/components/Menu/desktop';
import {ChangeEvent} from '@yandex-lego/components/Menu/Menu';

import {Textinput} from '../textinput';

import {withViewDark} from './view/dark';
import {withViewDLClear} from './view/dl-clear';

import './index.css';

const b = b_.with('lego-menu');

export const Menu = compose(
    withSizeM,
    composeU(withViewDark, withViewDLClear, withViewDefault),
    composeU(withWidthAuto, withWidthMax)
)(MenuDesktop);

export const MenuTags = ({items, onChange, ...rest}: any) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        if (rest.minSelected > event.target.value.length) {
            return;
        }

        onChange(event);
    };

    return (
        <div className={b('items')}>
            <Menu {...rest} onChange={handleChange} items={items} />
        </div>
    );
};

export const MenuWithSearch = ({items, ...rest}: any) => {
    const [searchText, setSearchText] = useState('');
    const controlRef = useRef<HTMLInputElement | null>(null);
    const prevActiveNode = useRef<HTMLElement | null>(null);

    useLayoutEffect(() => {
        if (rest.focused) {
            prevActiveNode.current = document.activeElement as HTMLElement;
            // eslint-disable-next-line no-unused-expressions
            controlRef.current && controlRef.current.focus();
        } else if (
            controlRef.current === document.activeElement &&
            prevActiveNode.current !== null
        ) {
            prevActiveNode.current.focus();
        }
    }, [rest.focused]);

    function handleChange(e: any) {
        setSearchText(e.target.value);
    }

    const filteredItems = useMemo(() => {
        return items.filter((item: any) => {
            const term = searchText.toLocaleLowerCase();

            return item.content.toLocaleLowerCase().includes(term);
        });
    }, [items, searchText]);

    return (
        <div className={b('menu-wrapper')}>
            <div className={b('search')}>
                <Textinput
                    controlRef={controlRef}
                    view='default'
                    size='m'
                    value={searchText}
                    onChange={handleChange}
                />
            </div>
            <div className={b('items')}>
                <Menu {...rest} items={filteredItems} />
            </div>
        </div>
    );
};
