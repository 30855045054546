import React from 'react';
import {Lang} from 'client/common/types';

const LangContext = React.createContext<Lang | undefined>(undefined);

export function useLang() {
    return React.useContext(LangContext);
}

export const LangProvider = LangContext.Provider;
