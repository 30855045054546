import {withBemMod} from '@bem-react/core';

import {cnMenu} from '@yandex-lego/components/Menu';

import './dark.css';

export interface MenuViewDarkProps {
    view?: 'dark';
}

export const withViewDark = withBemMod<MenuViewDarkProps>(cnMenu(), {view: 'dark'});
