import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './magic.css';

export interface IconTypeMagicProps {
    type?: 'magic';
}

export const withTypeMagic = withBemMod<IconTypeMagicProps>(cnIcon(), {type: 'magic'});
