import React from 'react';
import {hot} from 'react-hot-loader';

import Modal from 'client/common/components/modal';
import {renderBundle} from 'client/common/utils/bundle';
import {BundleProps} from 'client/common/types/bundle';
import {UserProvider} from 'client/common/contexts/user';

import Root from './root';

function Bundle({state}: BundleProps) {
    return (
        <>
            <UserProvider value={state.user}>
                <Root />
                <Modal />
            </UserProvider>
        </>
    );
}

const HMRBundle = hot(module)(Bundle);

export default renderBundle(HMRBundle);
