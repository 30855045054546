import {_doRequest} from '../base';
import {
    DenyModerationParams,
    MethodReturnValue,
    DenyModerationResponse,
    FetchModerationTooltipsResponse
} from '../types';

export const denyModeration = async (
    params: DenyModerationParams
): MethodReturnValue<DenyModerationResponse> => {
    const {slugs, reasons, withEmail = false} = params;

    const requestParams = {
        method: 'POST',
        url: 'moderation/deny',
        data: {
            slugs,
            reasons,
            withEmail
        }
    };

    return _doRequest(requestParams);
};

export const fetchModerationTooltips = async ({
    reasons,
    lang
}: {
    reasons: number[];
    lang: Lang;
}): Promise<FetchModerationTooltipsResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `moderation/tooltip?lang=${lang}&id=${reasons.join('&id=')}`
    };

    return _doRequest(requestParams);
};
