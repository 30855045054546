import React from 'react';
import {PageOrder} from 'client/common/types';

interface PagesContext {
    pageOrder: PageOrder;
    isScreening: boolean;
}

const PagesContext = React.createContext<PagesContext>({
    pageOrder: [],
    isScreening: false
});

export function usePagesContext(): PagesContext {
    return React.useContext(PagesContext);
}

export const PagesContextProvider = PagesContext.Provider;
