import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './light.css';

export interface ButtonViewLightProps {
    view?: 'light';
}

export const withViewLight = withBemMod<ButtonViewLightProps>(cnButton(), {view: 'light'});
