import {compose} from '@bem-react/core';

import {
    MessageBox as MessageBoxDesktop,
    MessageBoxPopup as MessageBoxPopupDesktop,
    withSizeM,
    withViewDefault,
    Wrapper
} from '@yandex-lego/components/MessageBox/desktop';

export const MessageBox = compose(withSizeM, withViewDefault)(MessageBoxDesktop);

export const MessageBoxPopup = compose(withSizeM, withViewDefault)(MessageBoxPopupDesktop);

export const MessageBoxWrapper = Wrapper;
