import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './plus.css';

export interface IconTypePlusProps {
    type?: 'plus';
}

export const withTypePlus = withBemMod<IconTypePlusProps>(cnIcon(), {type: 'plus'});
