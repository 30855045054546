import {compose, composeU} from '@bem-react/core';

import {
    Button as ButtonDesktop,
    withPinBrickBrick,
    withPinBrickClear,
    withPinBrickRound,
    withPinClearBrick,
    withPinClearClear,
    withPinClearRound,
    withPinRoundBrick,
    withPinRoundClear,
    withSizeM,
    withSizeS,
    withSizeL,
    withTypeLink,
    withTypeSubmit,
    withViewAction,
    withViewClear,
    withViewDefault,
    withViewLink,
    withWidthAuto,
    withWidthMax
} from '@yandex-lego/components/Button/desktop';

import {withSizeIcon} from './size/icon';
import {withSizeWide} from './size/wide';
import {withSizePromo} from './size/promo';
import {withSizeMenu} from './size/menu';

import {withViewTip} from './view/tip';
import {withViewLight} from './view/light';
import {withViewSuccess} from './view/success';
import {withViewWhite} from './view/white';
import {withViewBlue} from './view/blue';
import {withViewBlueText} from './view/blue-text';
import {withViewDLAction} from './view/dl-action';
import {withViewDLDefault} from './view/dl-default';
import {withViewDLClear} from './view/dl-clear';
import {withViewDLLight} from './view/dl-light';

import './index.css';

export const Button = compose(
    composeU(
        withPinBrickBrick,
        withPinBrickClear,
        withPinBrickRound,
        withPinClearBrick,
        withPinClearClear,
        withPinClearRound,
        withPinRoundBrick,
        withPinRoundClear
    ),
    composeU(withSizeIcon, withSizeM, withSizeS, withSizeL, withSizeWide, withSizePromo, withSizeMenu),
    composeU(withTypeLink, withTypeSubmit),
    composeU(
        composeU(
            withViewAction,
            withViewClear,
            withViewDefault,
            withViewTip,
            withViewLight,
            withViewLink,
            withViewSuccess,
            withViewWhite
        ),
        composeU(
            withViewBlue,
            withViewBlueText,
            withViewDLAction,
            withViewDLDefault,
            withViewDLClear,
            withViewDLLight
        )
    ),
    composeU(withWidthAuto, withWidthMax)
)(ButtonDesktop);
