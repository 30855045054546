import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './menu.css';

export interface ButtonSizeMenuProps {
    size?: 'menu';
}

export const withSizeMenu = withBemMod<ButtonSizeMenuProps>(cnButton(), {size: 'menu'});
