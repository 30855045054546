import {withBemMod} from '@bem-react/core';

import {cnTextarea} from '@yandex-lego/components/Textarea';

import './dl.css';

export interface TextareaViewDLProps {
    view?: 'dl';
}

export const withViewDL = withBemMod<TextareaViewDLProps>(cnTextarea(), {
    view: 'dl'
});
