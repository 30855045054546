import React, {useCallback, useMemo, useState} from 'react';
import {useMutation} from 'react-query';
import uniqueId from 'lodash/uniqueId';

import {tld} from 'client/common/utils/tld';
import CountryModal from 'client/common/components/country-modal/country-modal';
import {showModal} from 'client/common/hooks/modal';
import {sendTemplateEmail} from 'client/common/utils/api-client';
import {isEmptyBillingInfoErrorResponse} from 'client/common/utils/api-client/constructor/_util';
import {logError} from '@yandex-int/pythia-libs/lib/rum';

export const onRequestSettled = (res: any, err: Error | null /* , snapshotValue?: unknown */) => {
    if (isEmptyBillingInfoErrorResponse(res)) {
        const id = uniqueId();

        return showModal(
            id,
            <CountryModal
                onDone={() => {
                    location.reload();
                }}
                tld={tld}
            />,
            {hideByOutsideClick: false}
        );
    }

    if (err) {
        logError({
            message: 'Unknown error when request settled',
            err,
            additional: {res: JSON.stringify(res)}
        });
    }
};

export function useSendTemplateEmails() {
    const {mutateAsync} = useMutation(sendTemplateEmail);

    return useCallback(
        async ({slugs, template, params}: {slugs: any[]; template: any; params: any}) => {
            return mutateAsync({slugs, template, params});
        },
        [mutateAsync]
    );
}

export function usePendingCallback<TParams, TResult>(
    cb: (params: TParams) => Promise<TResult>
): [(params: TParams) => Promise<TResult | undefined>, boolean] {
    const [isPending, setIsPending] = useState(false);

    return useMemo(
        () => [
            async function callbackWrapper(params: TParams) {
                if (isPending) {
                    return;
                }

                setIsPending(true);

                try {
                    const res = await cb(params);

                    setIsPending(false);

                    return res;
                } catch (e) {
                    setIsPending(false);

                    throw e;
                }
            },
            isPending
        ],
        [cb, isPending, setIsPending]
    );
}
