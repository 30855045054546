import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './edit-clear.css';

export interface IconTypeEditClearProps {
    type?: 'edit-clear';
}

export const withTypeEditClear = withBemMod<IconTypeEditClearProps>(cnIcon(), {type: 'edit-clear'});
