import {compose, composeU} from '@bem-react/core';

import {
    Icon as IconDesktop,
    withGlyphCaretsV,
    withGlyphXSign,
    withTypeCrossWebsearch
} from '@yandex-lego/components/Icon/desktop';

import {withTypeArrow} from './type/arrow';
import {withTypeArrowBlue} from './type/arrow-blue';
import {withTypeArrowDown} from './type/arrow-down';
import {withTypeArrowRight} from './type/arrow-right';
import {withTypeBill} from './type/bill';
import {withTypeBranching} from './type/branching';
import {withTypeCancel} from './type/cancel';
import {withTypeCheck} from './type/check';
import {withTypeCheckBlack} from './type/check-black';
import {withTypeCheckFilled} from './type/check-filled';
import {withTypeCheckFilledSmall} from './type/check-filled-small';
import {withTypeClose} from './type/close';
import {withTypeCondition} from './type/condition';
import {withTypeCopy} from './type/copy';
import {withTypeCopyActive} from './type/copy-active';
import {withTypeCross} from './type/cross';
import {withTypeCrossBold} from './type/cross-bold';
import {withTypeCrossLight} from './type/cross-light';
import {withTypeDialog} from './type/dialog';
import {withTypeDownload} from './type/download';
import {withTypeEdit} from './type/edit';
import {withTypeEditClear} from './type/edit-clear';
import {withTypeError} from './type/error';
import {withTypeErrorRed} from './type/error-red';
import {withTypeFilter} from './type/filter';
import {withTypeFilterFunnel} from './type/filter-funnel';
import {withTypeFilterWhite} from './type/filter-white';
import {withTypeFingerUpBlack} from './type/finger-up-black';
import {withTypeFingerUpWhite} from './type/finger-up-white';
import {withTypeGear} from './type/gear';
import {withTypeHashtag} from './type/hashtag';
import {withTypeHelp} from './type/help';
import {withTypeHelpFilled} from './type/help-filled';
import {withTypeHelpYellow} from './type/help-yellow';
import {withTypeImage} from './type/image';
import {withTypeInfo} from './type/info';
import {withTypeInfoOrange} from './type/info-orange';
import {withTypeInterview} from './type/interviews';
import {withTypeLock} from './type/lock';
import {withTypeLocked} from './type/lock-closed';
import {withTypeLogic} from './type/logic';
import {withTypeMagic} from './type/magic';
import {withTypeMagicBlack} from './type/magic-black';
import {withTypeMagicWhite} from './type/magic-white';
import {withTypeMenu} from './type/menu';
import {withTypeMenuBurger} from './type/menu-burger';
import {withTypeMoneySpent} from './type/money-spent';
import {withTypeOverview} from './type/overview';
import {withTypePage} from './type/page';
import {withTypePageless} from './type/pageless';
import {withTypePause} from './type/pause';
import {withTypePeople} from './type/people';
import {withTypePercent} from './type/percent';
import {withTypePlay} from './type/play';
import {withTypePlayFilled} from './type/play-filled';
import {withTypePlus} from './type/plus';
import {withTypePlusBlue} from './type/plus-blue';
import {withTypePlusFilled} from './type/plus-filled';
import {withTypePreview} from './type/preview';
import {withTypePrice} from './type/price';
import {withTypeRandom} from './type/random';
import {withTypeRefresh} from './type/refresh';
import {withTypeRefreshBlack} from './type/refresh-black';
import {withTypeSadFace} from './type/sad-face';
import {withTypeShare} from './type/share';
import {withTypeShareFilled} from './type/share-filled';
import {withTypeStar} from './type/star';
import {withTypeTime} from './type/time';
import {withTypeTrash} from './type/trash';
import {withTypeTrashSmall} from './type/trash-small';
import {withTypeUpload} from './type/upload';
import {withTypeUser} from './type/user';
import {withTypeVideo} from './type/video';
import {withTypeVideoCircle} from './type/video-circle';
import {withTypeVideoError} from './type/video-error';
import {withTypeWallet} from './type/wallet';

import {
    withTypeQuestionDate,
    withTypeQuestionEmail,
    withTypeQuestionEmotion,
    withTypeQuestionGeo,
    withTypeQuestionLong,
    withTypeQuestionMultichoice,
    withTypeQuestionDualchoice,
    withTypeQuestionNumber,
    withTypeQuestionPercent,
    withTypeQuestionRating,
    withTypeQuestionSbs,
    withTypeQuestionScale,
    withTypeQuestionShort,
    withTypeQuestionSinglechoice,
    withTypeQuestionTable,
    withTypeQuestionText
} from './type/questions';

export const Icon = compose(
    composeU(withGlyphCaretsV, withGlyphXSign, withTypeCross, withTypeCrossWebsearch),
    composeU(
        withTypeArrow,
        withTypeArrowBlue,
        withTypeArrowDown,
        withTypeArrowRight,
        withTypeBill,
        withTypeBranching,
        withTypeCancel,
        withTypeCheck,
        withTypeCheckBlack,
        withTypeCheckFilled,
        withTypeCheckFilledSmall,
        withTypeClose,
        withTypeCondition,
        withTypeCopy,
        withTypeCopyActive,
        withTypeCross,
        withTypeCrossBold,
        withTypeCrossLight,
        withTypeDialog,
        withTypeDownload,
        withTypeEdit,
        withTypeEditClear,
        withTypeError,
        withTypeErrorRed,
        withTypeFilter,
        withTypeFilterFunnel,
        withTypeFilterWhite,
        withTypeFingerUpBlack,
        withTypeFingerUpWhite,
        withTypeGear,
        withTypeHashtag,
        withTypeHelp,
        withTypeHelpFilled,
        withTypeHelpYellow,
        withTypeImage,
        withTypeInfo,
        withTypeInfoOrange,
        withTypeInterview,
        withTypeLock,
        withTypeLocked,
        withTypeLogic,
        withTypeMagic,
        withTypeMagicBlack,
        withTypeMagicWhite,
        withTypeMenu,
        withTypeMenuBurger,
        withTypeMoneySpent,
        withTypeOverview,
        withTypePage,
        withTypePageless,
        withTypePause,
        withTypePeople,
        withTypePercent,
        withTypePlay,
        withTypePlayFilled,
        withTypePlus,
        withTypePlusBlue,
        withTypePlusFilled,
        withTypePreview,
        withTypePrice,
        withTypeQuestionDate,
        withTypeQuestionEmail,
        withTypeQuestionEmotion,
        withTypeQuestionGeo,
        withTypeQuestionLong,
        withTypeQuestionMultichoice,
        withTypeQuestionDualchoice,
        withTypeQuestionNumber,
        withTypeQuestionPercent,
        withTypeQuestionRating,
        withTypeQuestionSbs,
        withTypeQuestionScale,
        withTypeQuestionShort,
        withTypeQuestionSinglechoice,
        withTypeQuestionTable,
        withTypeQuestionText,
        withTypeRandom,
        withTypeRefresh,
        withTypeRefreshBlack,
        withTypeSadFace,
        withTypeShare,
        withTypeShareFilled,
        withTypeStar,
        withTypeTime,
        withTypeTrash,
        withTypeTrashSmall,
        withTypeUpload,
        withTypeUser,
        withTypeVideo,
        withTypeVideoCircle,
        withTypeVideoError,
        withTypeWallet
    )
)(IconDesktop);
