import truncate from 'lodash/truncate';
import plural from 'plural-ru';

import {getSortedTranslations} from 'assets/i18n';
import I18N from 'assets/i18n/brandlift-project';

import {formatNumber} from 'client/common/utils/format';
import {AudienceSegmentInfo, AudienceSegmentValue} from 'client/common/types';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AudienceSegments {
    private static readonly MIN_SEGMENT_SIZE = 500000;

    static isAvailable(segment: AudienceSegmentInfo): boolean {
        const isSmall = segment.cookies_matched_quantity < this.MIN_SEGMENT_SIZE;
        // Внешние DMP не имеем права использовать
        const isDMP = segment.type === 'dmp';
        // Геосегменты с типом «сейчас находятся» считаются в RT,
        // использовать их тяжело и нет большого смысла (PYTHIADEV-1141)
        const isGeoRealtime = segment.type === 'geo';
        // Делаем недоступными сегменты в которых недоступно кол-во аудитории
        const isScopeAvailable = Boolean(segment.cookies_matched_quantity);

        return !isSmall && !isDMP && !isGeoRealtime && isScopeAvailable;
    }

    static getLabel(segment: AudienceSegmentInfo): string {
        const i18n = I18N.common.audienceSettings;

        const {name, cookies_matched_quantity: size} = segment;

        return `${truncate(name, {length: 26})} ${i18n.segments.size.replace(
            '%s',
            formatNumber(size)
        )}`;
    }

    static getShorthand(values: AudienceSegmentValue[], segments: AudienceSegmentInfo[]): string {
        if (values.length === 1) {
            const segment = segments.find(({id}) => id.toString() === values[0]);

            return segment?.name ?? values[0];
        }

        const i18n = I18N.common.utils.format;

        return plural(
            values.length,
            ...(getSortedTranslations(i18n.audience.segment) as [string, string, string])
        );
    }

    static getDefaultSegments(segments: AudienceSegmentInfo[]) {
        const firstAvailableSegment = segments.find(segment =>
            AudienceSegments.isAvailable(segment)
        );

        return firstAvailableSegment ? [firstAvailableSegment.id.toString()] : [];
    }
}
