import React, {Fragment, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import b_ from 'b_';

import I18N from 'assets/i18n/brandlift-project';

import {useRumCounter} from 'client/common/utils/rum';
import {tld} from 'client/common/utils/tld';

import {Chat} from 'client/common/components/chat';
import Header, {headerMeaningfulSelector} from 'client/common/components/header2';
import {HeaderButton} from 'client/common/components/header2/types';
import Modal from 'client/common/components/modal';
import Notifications from 'client/common/components/notifications';
import {createUrl} from 'client/common/utils/history';

import './crowd.css';
import {UserStore} from 'client/common/store/reducers/user/types';

const i18n = I18N.crowd;
const b = b_.with('crowd');

const FORMS_ORIGIN = 'https://forms.yandex.ru';
const FORM_ID = '13475650.2ae74ce11b20654c3bd1f93c27de7c23a771e661';

const headerButtons: HeaderButton[] = ['help', 'create', 'dialog', 'wallet'];

export default function CrowdPage({user}: {user: UserStore}) {
    const {experiments, lang, testBuckets} = user;

    useRumCounter({
        page: 'landing.crowd',
        meaningfulPaintSelector: headerMeaningfulSelector,
        testBuckets
    });

    return (
        <Fragment>
            <Helmet>
                <title>{i18n.pageTitle}</title>
                <meta property='og:url' content='https://surveys.yandex.ru/landing/crowd' />
            </Helmet>
            <Header className={b('header')} buttons={headerButtons} />
            <CrowdForm lang={lang} />

            <Chat experiments={experiments} from={`crowd form ${tld.toUpperCase()}`} />
            <Notifications />
            <Modal />
        </Fragment>
    );

    function CrowdForm(props: {lang: Lang}) {
        const [height, setHeight] = useState(150);

        const handleMessage = (e: MessageEvent) => {
            if (e.origin === FORMS_ORIGIN && e.data) {
                try {
                    const parsedData = JSON.parse(e.data);

                    // eslint-disable-next-line max-depth
                    if (parsedData['iframe-height']) {
                        setHeight(parsedData['iframe-height']);
                    }

                    // eslint-disable-next-line no-empty
                } catch (_) {}
            }
        };

        useEffect(() => {
            window.addEventListener('message', handleMessage);

            return () => window.removeEventListener('message', handleMessage);
        }, []);

        return (
            <div className={b()}>
                <h1 className={b('form-title')}>{i18n.formTitle}</h1>
                <iframe
                    className={b('form')}
                    style={{height}}
                    src={createUrl({
                        link: `${FORMS_ORIGIN}/surveys/${FORM_ID}/?iframe=1&theme=cloud-www&lang=${props.lang}`
                    })}
                />
            </div>
        );
    }
}
