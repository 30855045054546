export enum BrandliftGoal {
    OpenForm = 'open_form',
    CreateSurvey = 'create_survey',
    EditSurvey = 'edit_survey',
    OpenStatistics = 'open_statistics',
    OpenHints = 'open_hints'
}

export enum SurveysGoal {
    AudienceOpenSettings = 'audience_open_settings',
    ClickChat = 'click_chat',
    ClickConstructorCard = 'click_constructor_card',
    ClickConstructorTip = 'click_constructor_tip',
    ClickEnter = 'click_enter',
    ClickHeaderEnter = 'click_header_enter',
    ClickHelpCreateButton = 'click_help_create_button',
    ClickHelpHeaderButton = 'click_help_header_button',
    ClickLaunch = 'click_launch',
    ClickPay = 'click_pay',
    ClickPayPerson = 'click_pay_person',
    ClickPayWithWallet = 'click_pay_with_wallet',
    ClickPreview = 'click_preview',
    ClickSaveDraft = 'click_save_draft',
    ClickStartCreativeTest = 'click_start_creative_test',
    ClickSurveyCard = 'click_survey_card',
    ClickSurveyCardLaunchButton = 'click_survey_card_launch_button',
    ClickSurveyCardMenuArchive = 'click_survey_card_menu_archive',
    ClickSurveyCardMenuCancelModeration = 'click_survey_card_menu_cancel_moderation',
    ClickSurveyCardMenuCopy = 'click_survey_card_menu_copy',
    ClickSurveyCardMenuEdit = 'click_survey_card_menu_edit',
    ClickSurveyCardMenuPause = 'click_survey_card_menu_pause',
    ClickSurveyCardMenuResume = 'click_survey_card_menu_resume',
    ClickSurveyCardStatisticsButton = 'click_survey_card_statistics_button',
    ClickSurveyPreset = 'click_survey_preset',
    FirstEditBoxContext = 'first_edit_box_context',
    InputAudienceSize = 'input_audience_size',
    InputEmail = 'input_email',
    InputQuestionLabel = 'input_question_label',
    MakePayment = 'make_payment',
    MakePaymentSuccess = 'make_payment_success',
    MoveBackToCreate = 'move_back_to_create',
    QuestionsLimitReached = 'questions_limit_reached',
    SelectAudience = 'select_audience',
    SettingsPayAsEntity = 'survey-settings_pay_as_entity',
    SettingsPayAsIndividual = 'survey-settings_pay_as_individual',
    SettingsShowWallet = 'survey-settings_show_wallet',
    UnlockInterviewPopupClickPay = 'unlock_interview_popup_click_pay',
    UnlockInterviewPopupClickPayIndividual = 'unlock_interview_popup_click_pay_individual',
    UnlockInterviewPopupChangeInterviewCount = 'unlock_interview_popup_change_interview_count',
    UXSavedAreas = 'ux_saved_areas',
    StatisticsClickUnlockAnswers = 'statistics_click_unlock_answers',
    StatisticsFinishPayment = 'statistics_finish_payment',
    ChangeAudienceType = 'change_audience_type',
    ChangeAudienceCategory = 'change_audience_category',
    ConstructorFromCategory = 'constructor_from_category'
}

export enum RootGoal {
    ClickFeatureLink = 'click_feature_link',
    ClickLandingStart = 'click_landing_start',
    ClickLandingTry = 'click_landing_try',
    ClickLandingTryBottom = 'click_landing_try_bottom',
    ClickLandingFeedbackSent = 'click_landing_feedback_sent',
    ClickLandingBackToSurveys = 'click_landing_back_to_surveys'
}

export enum PollLandingGoal {
    Render = 'poll_landing_render',
    ActionTryBusinessButton = 'poll_landing_try_business_button',
    ActionLogoClick = 'poll_landing_action_logo_click',
    ActionFooterLinkClick = 'poll_landing_action_footer_link_click'
}

export enum BannerGoal {
    ActionClickCloseBanner = 'click_close_banner',
    ActionClickTryBanner = 'click_try_banner'
}

export type MetrikaGoal = BrandliftGoal | SurveysGoal | RootGoal | PollLandingGoal | BannerGoal;

export enum SurveysParam {
    QuestionSelectingTime = 'question_selecting_time',
    SeenCategoryCount = 'seen_category_count',
    SelectedCategory = 'selected_category',
    ConstructorFromCategory = 'constructor_from_category',
    LaunchedSurveyType = 'launched_survey_type',
    ConstructorSurveyType = 'constructor_survey_type',
    SelectedAudienceType = 'selected_audience_type',
    SelectedAudienceCategory = 'selected_audience_category'
}

export enum PollLandingParam {
    Status = 'status'
}

export enum RootParam {
    scrolledPage = 'scrolled_page'
}

export type MetrikaParam = SurveysParam | RootParam;
