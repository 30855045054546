import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './arrow.css';

export interface IconTypeArrowProps {
    type?: 'arrow';
}

export const withTypeArrow = withBemMod<IconTypeArrowProps>(cnIcon(), {type: 'arrow'});
