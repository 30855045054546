import React from 'react';
import b_ from 'b_';

import {RootGoal} from 'client/common/types';

import I18N from 'assets/i18n/root';

import {TryBtn} from '../buttons';

import './call-to-action.css';

const b = b_.with('call-to-action');

const i18n = I18N.callToAction;

const CallToAction = () => {
    return (
        <div className={b()}>
            <div className={b('text')}>{i18n.text}</div>
            <TryBtn goal={RootGoal.ClickLandingTryBottom} />
        </div>
    );
};

export default CallToAction;
