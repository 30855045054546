import {RequestError, SurveySlug} from 'client/common/types';

import {_doRequest} from '../base';
import {SurveyCreatorResponse, SuccessfulResponse} from '../types';

export const getSurveyCreator = async (slug: SurveySlug): Promise<SurveyCreatorResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `survey/${slug}/creator/`
    };

    return _doRequest(requestParams);
};

export const changeSurveyCreator = async ({
    slug,
    login
}: {
    slug: SurveySlug;
    login: string;
}): Promise<SuccessfulResponse> => {
    const requestParams = {
        method: 'PUT',
        url: `survey/${slug}/creator/${login}`,
        passQuery: false
    };

    const res = await _doRequest(requestParams);

    if (res.status >= 400) {
        throw new RequestError(res.status, res.data);
    }

    return res;
};
