import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './dl-clear.css';

export interface ButtonViewDLClearProps {
    view?: 'dl-clear';
}

export const withViewDLClear = withBemMod<ButtonViewDLClearProps>(cnButton(), {
    view: 'dl-clear'
});
