import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './pageless.css';

export interface IconTypePagelessProps {
    type?: 'pageless';
}

export const withTypePageless = withBemMod<IconTypePagelessProps>(cnIcon(), {type: 'pageless'});
