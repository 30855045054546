import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './arrow-down.css';

export interface IconTypeArrowDownProps {
    type?: 'arrow-down';
}

export const withTypeArrowDown = withBemMod<IconTypeArrowDownProps>(cnIcon(), {
    type: 'arrow-down'
});
