import {GeoCountry, GeoValue, PaymentStatus} from '../types';

export function checkPayed(paymentStatus: PaymentStatus | undefined): boolean {
    return Boolean(paymentStatus);
}

/**
 * Можно ли платить картой как физлицо, через Траст
 */
export const isPaymentAsIndividualAvailable = (userCountryId?: number): boolean => {
    return Boolean(
        userCountryId &&
            [GeoValue.Russia, GeoValue.Kazakhstan].includes(String(userCountryId) as GeoCountry)
    );
};
