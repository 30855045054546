import {withBemMod} from '@bem-react/core';

import {cnSelect} from '@yandex-lego/components/Select';

import './divider-round.css';

export interface SelectPinDividerRoundProps {
    pin?: 'divider-round';
}

export const withPinDividerRound = withBemMod<SelectPinDividerRoundProps>(cnSelect(), {
    pin: 'divider-round'
});
