import qs from 'qs';

import {SurveyState} from 'client/common/store/reducers/survey/types';
import {RequestError, SurveySlug} from 'client/common/types';
import {AudienceTargeting} from 'client/common/utils/audience';

import {_doRequest} from '../base';

import {
    SurveysFetchParams,
    GetSurveysResponse,
    GetSurveyResponse,
    CreateSurveyParams,
    CreateSurveyResponse,
    BindSurveyParams,
    BindSurveyResponse,
    UpdateSurveyParams,
    UpdateSurveyResponse,
    UpdateSurveyBulkParams,
    UpdateSurveyBulkResponse,
    PublishSurveyParams,
    MethodReturnValue,
    PublishSurveyResponse,
    CopySurveyParams,
    CopySurveyResponse,
    GetInterviewCostParams,
    GetInterviewCostParamsResponse,
    GetModerationVerdictResponse,
    GetSurveyFeaturesParams,
    GetSurveyFeaturesResponse,
    UpdateSurveyPaymentAccountParams,
    UpdateSurveyPaymentAccountResponse,
    UpdateAudienceRequestParams,
    UpdateAudienceRequestResponse
} from '../types';

export const getSurveys = async (params: SurveysFetchParams): Promise<GetSurveysResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `surveys?${qs.stringify(params, {indices: false})}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const getSurvey = async ({
    slug,
    apiLocation = 'survey'
}: {
    slug: string;
    apiLocation?: string;
}): Promise<GetSurveyResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `${apiLocation}/${slug}`
    };

    const res = await _doRequest(requestParams);

    // FIXME: Убрать, когда научимся выкидывать ошибки в ApiClient
    if (res.status >= 400) {
        throw new RequestError(res.status, res.data);
    }

    return res;
};

export const createSurvey = async (params: CreateSurveyParams): Promise<CreateSurveyResponse> => {
    const {
        survey: {box, name, purpose, clientId, audience, isTemporary, accountId, settings, orgId},
        apiLocation = 'survey'
    } = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: apiLocation,
        data: {
            box,
            name,
            purpose,
            clientId,
            is_temporary: isTemporary,
            audience: audience ?? AudienceTargeting.DEFAULT_AUDIENCE,
            accountId,
            settings,
            orgId
        }
    };

    return _doRequest(requestParams);
};

export const bindSurvey = async (params: BindSurveyParams): Promise<BindSurveyResponse> => {
    const {slug, clientId} = params;

    const requestParams = {
        method: 'PATCH',
        retries: [3000],
        url: `survey/${slug}/bindUser`,
        data: {clientId}
    };

    return _doRequest(requestParams);
};

export const updateSurvey = async (params: UpdateSurveyParams): Promise<UpdateSurveyResponse> => {
    const {slug, state} = params;

    const requestParams = {
        method: 'PATCH',
        retries: [3000],
        url: `survey/${slug}`,
        data: {
            state
        }
    };

    return _doRequest(requestParams);
};

export const updateSurveyBulk = async (
    params: UpdateSurveyBulkParams
): Promise<UpdateSurveyBulkResponse> => {
    const {apiLocation = 'survey', data, slug} = params;

    const requestParams = {
        method: 'PATCH',
        retries: [3000],
        url: `${apiLocation}/${slug}/bulkUpdate`,
        data
    };

    return _doRequest(requestParams);
};

export const publishSurvey = async (
    params: PublishSurveyParams
): MethodReturnValue<PublishSurveyResponse> => {
    const {slug, revisionId} = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/revision/${revisionId}/publish`
    };

    return _doRequest(requestParams);
};

export const copySurvey = async (params: CopySurveyParams): Promise<CopySurveyResponse> => {
    const {access, audience, clientId, metrika, redirects, slug, name, orgId} = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/copy`,
        data: {
            access,
            audience,
            clientId,
            metrika,
            redirects,
            name,
            orgId
        }
    };

    return _doRequest(requestParams);
};

export const closeSurvey = async ({slug}: {slug: string}) => {
    const requestParams = {
        method: 'PATCH',
        retries: [3000],
        url: `survey/${slug}`,
        data: {
            state: SurveyState.Closed
        }
    };

    return _doRequest(requestParams);
};

export const getInterviewCost = async (
    params: GetInterviewCostParams
): Promise<GetInterviewCostParamsResponse> => {
    const {interviewCount} = params;

    const requestParams = {
        method: 'GET',
        url: `survey/cost/interview/${interviewCount}`
    };

    return _doRequest(requestParams);
};

export const getSurveyFeatures = async (
    params: GetSurveyFeaturesParams
): Promise<GetSurveyFeaturesResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'GET',
        url: `survey/${slug}/features`
    };

    return _doRequest(requestParams);
};

export const shareSurvey = async ({slug}: {slug: SurveySlug}) => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/settings/share/`
    };

    return _doRequest(requestParams);
};

export const getModerationVerdict = async (
    slug: SurveySlug
): Promise<GetModerationVerdictResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `survey/${slug}/moderation/verdict`
    };

    return _doRequest(requestParams);
};

export const updateSurveyPaymentAccount = async (
    params: UpdateSurveyPaymentAccountParams
): Promise<UpdateSurveyPaymentAccountResponse> => {
    const {slug, accountId} = params;

    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/account`,
        data: {
            accountId
        }
    };

    return _doRequest(requestParams);
};

export const updateAudienceRequest = async (
    params: UpdateAudienceRequestParams
): Promise<UpdateAudienceRequestResponse> => {
    const {categories, interests, segments, size, slug, type, description, quotas} = params;

    const requestParams = {
        method: 'PATCH',
        retries: [3000],
        url: `survey/${slug}/bulkUpdate`,
        data: {
            audience: {
                size,
                type,
                quotas,
                segments,
                interests,
                categories,
                description
            }
        }
    };

    return _doRequest(requestParams);
};
