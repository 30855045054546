import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './check.css';

export interface IconTypeCheckProps {
    type?: 'check';
}

export const withTypeCheck = withBemMod<IconTypeCheckProps>(cnIcon(), {type: 'check'});
