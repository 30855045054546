import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './icon.css';

export interface ButtonSizeIconProps {
    size?: 'icon';
}

export const withSizeIcon = withBemMod<ButtonSizeIconProps>(cnButton(), {size: 'icon'});
