import {LogicScheme} from './logic';
import {ImageFields} from './image';
import {VideoFields} from './video';

interface WithRotation {
    rotation?: boolean;
    sampling?: number;
}

export interface PageOrderGroup extends WithRotation {
    pages: Group['id'][];
}

type PageOrderItem = Group['id'] | PageOrderGroup;

export type PageOrder = PageOrderItem[];

export function isPageOrderGroup(item: PageOrderItem): item is PageOrderGroup {
    return (item as PageOrderGroup).rotation !== undefined;
}

export interface WithLogic {
    showIf?: LogicScheme | null;
}

export interface WithTemplate {
    template?: ScreeningOptionTemplate;
}

export interface Group extends WithLogic, WithRotation {
    children: GroupChild[];
    condition?: LogicScheme | null;
    description?: string;
    id: number;
    isScreening?: boolean;
    nextBtnLabel: string;
    type: 'page';
}

export function isGroup(group: Question | Widget | Group): group is Group {
    return group.type === 'page';
}

export function isScreeningPageWithCondition(group: Question | Widget | Group) {
    return isGroup(group) && group.isScreening && group.condition;
}

export function pageIncludesTemplateQuestions(group: Group, revision: Revision) {
    return group.children.some(page => isChoiceTemplateQuestion(revision.questions[page.id]));
}

export type GroupChildType = 'question' | 'widget';

export interface GroupChild {
    id: number;
    type: GroupChildType;
}

export enum RevisionState {
    ACTIVE = 'ACTIVE',
    DRAFT = 'DRAFT',
    OBSOLETE = 'OBSOLETE'
}

export interface EntityDict<T extends {id: number} = {id: number}> {
    [id: string]: T;
}

export interface Revision {
    groups: EntityDict<Group>;
    id: number;
    name: string;
    pageOrder: PageOrder;
    questions: EntityDict<Question>;
    screening?: {
        enabled: boolean;
        pageOrder: PageOrder;
    };
    state: RevisionState;
    widgets: EntityDict<Widget>;
}

export interface RevisionContextWrapper {
    value: RevisionContext;
}

export interface RevisionContext {
    branding: Branding;
    feedback: RevisionContextFeedback;
    settings: {
        type?: 'services' | 'products';
    };
    template: any;
}

export const QuestionTypes = [
    'choice',
    'clickmapVideo',
    'date',
    'email',
    'emotion',
    'geoText',
    'longText',
    'number',
    'rating',
    'scale',
    'shortText',
    'sidebyside',
    'table',
    'tableScale',
    'ux'
] as const;

export const WidgetTypes = ['markup', 'video'] as const;

export type QuestionType = (typeof QuestionTypes)[number];
export type ExtendedQuestionType =
    | QuestionType
    | 'multichoice'
    | 'percent'
    | 'multitable'
    | 'dualChoice';

export type QuestionTypeWithLogic = QuestionType | 'multipleChoice' | 'dualChoice';

export const QuestionInputTypes = [
    'date',
    'email',
    'longText',
    'number',
    'percent',
    'shortText'
] as const;

export const ScreeningQuestionTemplates = {
    AGE: 'age',
    SEX: 'sex'
} as const;

export const ScreeningOptionsTemplates = {
    AGE_17_under: 'age_17_under',
    AGE_18_24: 'age_18_24',
    AGE_25_34: 'age_25_34',
    AGE_35_44: 'age_35_44',
    AGE_45_54: 'age_45_54',
    AGE_55_over: 'age_55_over',
    SEX_MALE: 'sex_male',
    SEX_FEMALE: 'sex_female'
} as const;

export const SCREENING_TEMPLATE_QUESTIONS = new Set<string>(
    Object.values(ScreeningQuestionTemplates)
);

export type QuestionInputType = (typeof QuestionInputTypes)[number];

export type WidgetType = (typeof WidgetTypes)[number];

export type BlockType = ExtendedQuestionType | WidgetType;

export type ScreeningQuestionTemplate =
    (typeof ScreeningQuestionTemplates)[keyof typeof ScreeningQuestionTemplates];

export type ScreeningOptionTemplate =
    (typeof ScreeningOptionsTemplates)[keyof typeof ScreeningOptionsTemplates];

export interface BrandingFooterLink {
    text: string;
    url: string;
}

export type BrandingState = 'yes' | 'no' | 'whenAvailable';
export type ThemeColorName = 'main' | 'internal' | 'bg' | 'border';

export interface Theme {
    attachment?: {
        justify?: string;
        marginBottom?: string;
        marginTop?: string;
    };
    bgCustom?: string;
    bgImg?: {
        position?: string;
        repeat?: string;
        size?: string;
        src?: string;
    };
    bgSelected?: 'color' | 'image' | 'custom';
    button?: {
        bgColor?: string;
        borderRadius?: string;
        focusVisible?: {
            border?: {
                color?: string;
            };
        };
        fontSize?: string;
        fontWeight?: string;
        height?: string;
        hover?: {
            bgColor?: string;
        };
        minWidth?: string;
        disabled?: {
            bgColor?: string;
            color?: string;
            textOpacity?: number;
        };
        margin?: {
            top?: string;
        };
    };
    choice?: {
        option?: {
            alignItems?: string;
            disabledColor?: string;
            fontSize?: string;
            fontWeight?: string;
            gap?: string;
            height?: string;
            marginInlineEnd?: string;
            marginTop?: string;
            checkmark?: {
                color?: string;
                left?: string;
                longSideWidth?: string;
                shortSideWidth?: string;
                top?: string;
                weight?: string;
            };
            control?: {
                border?: string;
                position?: 'left' | 'right';
                width?: string;
                colors?: {
                    bg?: string;
                    inactiveInternalBg?: string;
                    disabled?: string;
                };
                focusVisible?: {
                    border?: {
                        color?: string;
                    };
                };
                multiChoice?: {
                    borderRadius?: string;
                };
                singleChoice?: {
                    checkedSizeModifier?: number;
                    internalWidth?: string;
                    enableCheckmark?: boolean;
                };
            };
            padding?: {
                inlineStart?: string;
                left?: string;
                top?: string;
            };
        };
        answerWithText?: {
            placeholder?: string;
            inputType?: 'input' | 'textarea';
        };
    };
    colors?: Partial<Record<ThemeColorName, string>>;
    emotion?: {
        bg?: string;
        block?: {
            count2maxWidth?: string;
            count3maxWidth?: string;
            innerPosition?: 'start' | 'end' | 'space-between' | 'center';
            maxWidth?: string;
            position?: 'start' | 'end' | 'center';
        };
        border?: {
            radius?: string;
            width?: string;
            color?: string;
        };
        focusVisible?: {
            border?: {
                color?: string;
            };
        };
        gap?: string;
        height?: string;
        width?: string;
        selected?: {
            scale?: number;
            label?: {
                color?: string;
            };
        };
        hover?: {
            scale?: number;
            label?: {
                color?: string;
            };
        };
        selectedScale?: string;
        hoverScale?: string;
        label?: {
            bg?: string;
            border?: {
                radius?: string;
                width?: string;
                color?: string;
            };
            color?: string;
            deviceType?: 'mobile' | 'desktop' | 'all' | 'none';
            fontSize?: string;
            fontWeight?: string;
            lineHeight?: string;
            marginTop?: string;
        };
        mobile?: {
            gap?: string;
        };
    };
    error?: {
        color?: string;
        fontSize?: string;
        lineHeight?: string;
        margin?: string;
    };
    finalPage?: {
        fontSize?: string;
        fontWeight?: string;
        lineHeight?: string;
        padding?: string;
    };
    footerLinks?: BrandingFooterLink[];
    input?: {
        focusVisible?: {
            border?: {
                color?: string;
            };
        };
        lineHeight?: string;
        restriction?: {
            hidden?: boolean;
            bottom?: string;
            right?: string;
            fontSize?: string;
            fontWeight?: string;
            lineHeight?: string;
        };
        colors?: {
            bg?: string;
            placeholder?: string;
            text?: string;
        };
        border?: {
            bottomMargin?: string;
            color?: string;
            focusColor?: string;
            hoverColor?: string;
            radius?: string;
            type?: 'bottom' | 'around';
            width?: string;
        };
        autogrow?: boolean;
        fontSize?: string;
        mobile?: {
            restriction?: {
                hidden?: boolean;
                bottom?: string;
                right?: string;
            };
        };
        padding?: string;
        placeholders?: Partial<Record<QuestionInputType, string>>;
        textarea?: {
            autogrowTop?: string;
            fontSize?: string;
            lineHeight?: string;
            maxHeight?: string;
            minHeight?: string;
            padding?: string;
        };
    };
    legalText?: string;
    logo?: {
        imgSrc: string;
    };
    mainFont?: {
        defaultSelected?: string;
        color?: string;
    };
    markdown?: {
        fontSize?: string;
        fontWeight?: string;
        lineHeight?: string;
        h1FontSize?: string;
        h1FontWeight?: string;
        h1LineHeight?: string;
        h2FontSize?: string;
        h2FontWeight?: string;
        h2LineHeight?: string;
        h3FontSize?: string;
        h3LineHeight?: string;
        href?: {
            color: string;
            hoverColor: string;
            textDecoration?: string;
        };
    };
    progressBar?: {
        hide?: boolean;
    };
    questionCaption?: {
        color?: string;
        fontSize?: string;
        fontWeight?: string;
        margin?: {
            bottom?: string;
            top?: string;
        };
        padding?: {
            left?: string;
        };
    };
    questionLabel?: {
        fontSize?: string;
        fontWeight?: string;
        lineHeight?: string;
        requiredAsteriskColor?: string;
        textAlign?: string;
    };
    rating?: {
        block?: {
            count2maxWidth?: string;
            count3maxWidth?: string;
            innerPosition?: 'start' | 'end' | 'space-between' | 'center';
            maxWidth?: string;
            position?: 'start' | 'end' | 'center';
        };
        borderWidth?: number;
        colors?: {
            borderActive?: string;
            borderInactive?: string;
            borderInactiveHover?: string;
            fillActive?: string;
            fillInactive?: string;
            fillInactiveHover?: string;
        };
        focusVisible?: {
            border?: {
                color?: string;
            };
        };
        gap?: string;
        starType?: 'default' | 'portal';
    };
    scale?: {
        block?: {
            count2maxWidth?: string;
            count3maxWidth?: string;
            innerPosition?: 'start' | 'end' | 'space-between' | 'center';
            maxWidth?: string;
            position?: 'start' | 'end' | 'center';
        };
        padding?: {
            inline?: {
                ifMany?: string;
                ifFew?: string;
            };
        };
        option?: {
            bg?: string;
            border?: {
                color?: string;
                radius?: string;
                size?: string;
                style?: string;
                hover?: {
                    color?: string;
                };
            };
            fontSize?: string;
            focusVisible?: {
                border?: {
                    color?: string;
                };
            };
            hoverBg?: string;
            hoverColor?: string;
            lineHeight?: string;
            mobile?: {
                fontSize?: string;
                many?: {
                    fontSize?: string;
                    labelWidth?: string;
                    lineHeight?: string;
                    marginBottom?: string;
                };
            };
            width?: string;
        };
        labels?: {
            color?: string;
            fontSize?: string;
            lineHeight?: string;
            margin?: {
                bottom?: string;
                top?: string;
            };
        };
    };
    scroll?: {
        marginRight?: string;
        paddingRight?: string;
        trackColor?: string;
        thumbColot?: string;
    };
    specialButtons?: {
        marginTop?: string;
        button?: {
            bg?: {
                base?: string;
                hover?: string;
                selected?: string;
                selectedHover?: string;
            };
            border?: {
                colors?: {
                    bg?: string;
                    hover?: string;
                    selected?: string;
                };
                radius?: string;
                width?: string;
            };
            color?: string;
            fontSize?: string;
            lineHeight?: string;
            margin?: string;
            minHeight?: string;
            minWidth?: string;
            selectedColor?: string;
            textMargin?: string;
        };
        otherAnswerTop?: string;
    };
    spinner?: {
        view?: 'default' | 'portal';
        color?: string;
    };
    tooltip?: {
        bg?: string;
        border?: {
            color?: string;
            radius?: string;
            width?: string;
        };
        color?: string;
        deviceType?: 'mobile' | 'desktop' | 'all' | 'none';
        fontSize?: string;
        fontWeight?: string;
        lineHeight?: string;
        padding?: string;
        shadow?: string;
        top?: string;
    };
    underlay?: {
        transparency?: number;
        bg?: string;
        gap?: string;
        height?: string;
        padding?: string;
        margin?: string;
        mobile?: {
            padding?: string;
            margin?: string;
        };
        special?: {
            safariInTelegramPaddingBottom?: string;
        };
    };
    widget?: {
        fontSize?: string;
        fontWeight?: string;
        lineHeight?: string;
    };
}

export interface Branding extends Theme {
    darkTheme?: Theme;
    on?: BrandingState;
    skin?: string;
}

export interface RevisionContextFeedbackAdditionalConfig {
    count?: number;
    nullify?: {
        on: boolean;
        durationSec?: number;
        timerType?: 'page' | 'session';
    };
    on: boolean;
}

export interface RevisionContextFeedbackTimerConfig {
    duration?: number;
    on: boolean;
}

export interface RevisionContextFeedbackLangConfig {
    value?: string[];
    on: boolean;
}

export interface RevisionContextFeedbackUrlConfig {
    excludeRegexp?: string;
    includeRegexp?: string;
    navigations?: RevisionContextFeedbackAdditionalConfig;
    on: boolean;
    showDelay?: {
        on: boolean;
        delaySec?: number;
        timerType?: 'page' | 'session';
    };
}

export interface RevisionContextFeedbackSegmentsConfig {
    on: boolean;
    segment?: {
        id?: number;
        invert: boolean;
    };
}

export const FeedbackButtonDirections = ['left', 'right', 'top', 'bottom'] as const;
export const ActiveFeedbackDirections = [
    'bottom-left',
    'bottom-right',
    'top-left',
    'top-right',
    'center'
] as const;

export interface RevisionContextFeedbackAppearanceConfig {
    direction:
        | (typeof FeedbackButtonDirections)[number]
        | (typeof ActiveFeedbackDirections)[number];
    isButtonHidden: boolean;
}

export interface RevisionContextFeedbackCloseAfterSecondsConfig {
    time: number;
}

export interface RevisionContextFeedbackPercentConfig {
    on: boolean;
    value?: number;
}

export const TimeUnits = ['S', 'M', 'H', 'd', 'w', 'm', 'y'] as const;
export type TimeUnit = (typeof TimeUnits)[number];

export interface RevisionContextFeedbackNoAnnoyConfig {
    on: boolean;
    onSkipMS?: number;
    value?: number;
    onSkipUnit?: TimeUnit;
    notOftenUnit?: TimeUnit;
}

export interface RevisionContextFeedbackEventConfig {
    name?: string;
    on: boolean;
    showDelay?: {
        on: boolean;
        delaySec?: number;
        timerType?: 'page' | 'session';
    };
    triggers?: RevisionContextFeedbackAdditionalConfig;
}

export interface RevisionContextFeedbackScrollConfig {
    on: boolean;
    value?: number;
}

export interface RevisionContextFeedbackTestidConfig {
    on: boolean;
    value?: string[];
}

export interface RevisionContextFeedbackDeviceConfig {
    on: boolean;
    platform?: {
        values?: string[];
        invert?: boolean;
    };
    os_family?: {
        values?: string[];
        invert?: boolean;
    };
    browser_name?: {
        values?: string[];
        invert?: boolean;
    };
}

export interface RevisionContextFeedback {
    appearance?: RevisionContextFeedbackAppearanceConfig;
    closeAfterSeconds?: RevisionContextFeedbackCloseAfterSecondsConfig;
    event?: RevisionContextFeedbackEventConfig;
    noannoy?: RevisionContextFeedbackNoAnnoyConfig;
    on?: boolean;
    percent?: RevisionContextFeedbackPercentConfig;
    scroll?: RevisionContextFeedbackScrollConfig;
    showForGoingAwayUsers?: {
        on: boolean;
    };
    showOnlyInActiveTab?: {
        on: boolean;
    };
    timer?: RevisionContextFeedbackTimerConfig;
    url?: RevisionContextFeedbackUrlConfig;
    lang?: RevisionContextFeedbackLangConfig;
    testid?: RevisionContextFeedbackTestidConfig;
    device?: RevisionContextFeedbackDeviceConfig;
    segment?: RevisionContextFeedbackSegmentsConfig;
}

export interface WithLabel {
    label: string;
}

export type AttachmentPosition = 'top' | 'bottom';

export type AttachmentType = 'image' | 'video';

interface AbstractAttachment {
    position?: AttachmentPosition;
    type: AttachmentType;
}

export interface ImageAttachment extends AbstractAttachment, ImageFields {
    type: 'image';
}

export interface VideoAttachment extends AbstractAttachment, VideoFields {
    type: 'video';
}

export type Attachment = ImageAttachment | VideoAttachment;

interface WithAttachment {
    attachment?: Attachment | null;
}

interface WithContext {
    withContext?: boolean;
}

interface WithSpssLabel {
    spssLabel?: string;
}

interface AbstractQuestion
    extends WithLabel,
        WithLogic,
        WithAttachment,
        WithContext,
        WithSpssLabel {
    id: number;
    isDisabled?: boolean;
    isTemplate?: boolean;
    required: boolean;
    type: QuestionType;
}

export interface SpecialOption extends WithLabel {
    enabled: boolean;
}

export type SpecialOptionKey =
    | 'noOpinionAnswer'
    | 'noAnswer'
    | 'otherAnswer'
    | 'otherAnswerWithText';

interface QuestionWithNoOpinionAnswer extends AbstractQuestion {
    noOpinionAnswer: SpecialOption;
}

interface QuestionWithNoAnswer extends AbstractQuestion {
    noAnswer: SpecialOption;
}

interface QuestionWithOtherAnswer extends AbstractQuestion {
    otherAnswer: SpecialOption;
}

interface QuestionWithOtherAnswerWithText extends AbstractQuestion {
    otherAnswerWithText: SpecialOption;
}

export type QuestionWithSpecialOptions = QuestionWithNoOpinionAnswer &
    QuestionWithNoAnswer &
    QuestionWithOtherAnswer &
    QuestionWithOtherAnswerWithText;

export interface AbstractOption extends WithLabel {
    attachments?: Array<{
        type: 'image';
        url: string;
    }>;
    isTemplate?: boolean;
}

export interface QuestionOption extends AbstractOption, WithLogic, WithTemplate {
    id: number;
}

export type TableOptionHeader = AbstractOption;

export interface QuestionImageOption extends WithLabel {
    dimensions?: QuestionImageDimensions;
    id: number;
    url: string;
}

export interface ShortTextQuestion extends AbstractQuestion {
    type: 'shortText';
}

export interface ShortTextQuestionData extends Omit<ShortTextQuestion, 'id'> {}

export interface EmailQuestion extends AbstractQuestion {
    hideDescription?: boolean;
    type: 'email';
}

export interface EmailQuestionData extends Omit<EmailQuestion, 'id'> {}

export interface DateQuestion extends AbstractQuestion {
    placeholder: string;
    range: {
        enabled?: boolean;
        minDate?: string;
        maxDate?: string;
    };
    type: 'date';
}

export interface DateQuestionData extends Omit<DateQuestion, 'id'> {}

export interface LongTextQuestion extends AbstractQuestion {
    type: 'longText';
}

export interface LongTextQuestionData extends Omit<LongTextQuestion, 'id'> {}

export type GeoAccuracy = 'country' | 'province' | 'locality';

export interface GeoQuestion extends AbstractQuestion {
    accuracy: GeoAccuracy;
    type: 'geoText';
}

export interface GeoQuestionData extends Omit<GeoQuestion, 'id'> {}

export type NumberType = 'any' | 'int';

export interface NumberQuestion extends AbstractQuestion {
    maxValue?: number | null;
    minValue?: number | null;
    numberType?: NumberType;
    postfix?: string;
    type: 'number';
}

export interface NumberQuestionData extends Omit<NumberQuestion, 'id'> {}

export const MAX_RATING_VALUES = [2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export type MaxRatingValue = (typeof MAX_RATING_VALUES)[number];

export interface RatingQuestion extends AbstractQuestion {
    maxValue: MaxRatingValue;
    type: 'rating';
}

export interface RatingQuestionData extends Omit<RatingQuestion, 'id'> {}

export interface ChoiceQuestion
    extends QuestionWithNoOpinionAnswer,
        QuestionWithNoAnswer,
        QuestionWithOtherAnswer,
        QuestionWithOtherAnswerWithText,
        WithRotation {
    imageAlign?: boolean;
    template?: ScreeningQuestionTemplate;
    choiceType?: string;
    maxAnswerCount?: number | null;
    minAnswerCount?: number | null;
    multipleChoice: boolean;
    options: QuestionOption[];
    type: 'choice';
}

export interface ChoiceQuestionData extends Omit<ChoiceQuestion, 'id' | 'options'> {
    options: Omit<QuestionOption, 'id'>[];
}

export const MIN_SCALE_VALUES = [0, 1] as const;
export const MAX_SCALE_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export const SCALE_VALUES = Array.from(new Set([...MIN_SCALE_VALUES, ...MAX_SCALE_VALUES]));

export type MinScaleValue = (typeof MIN_SCALE_VALUES)[number];
export type MaxScaleValue = (typeof MAX_SCALE_VALUES)[number];

export interface ScaleLabels {
    enabled: boolean;
    high?: string;
    low?: string;
    middle?: string;
    positionBottom?: boolean;
}

export interface ScaleQuestion extends QuestionWithNoOpinionAnswer {
    maxValue: MaxScaleValue;
    minValue?: MinScaleValue;
    scaleLabels?: ScaleLabels;
    type: 'scale';
}

export interface ScaleQuestionData extends Omit<ScaleQuestion, 'id'> {}

export const EMOTION_OPTION_COUNTS = [2, 3, 5] as const;

export type EmotionOptionCount = (typeof EMOTION_OPTION_COUNTS)[number];

export const EMOTION_OPTIONS = {
    2: [1, 5],
    3: [1, 3, 5],
    5: [1, 2, 3, 4, 5]
};

export interface EmotionQuestion extends QuestionWithNoOpinionAnswer {
    labels?: string[];
    optionsCount?: EmotionOptionCount;
    type: 'emotion';
}

export interface EmotionQuestionData extends Omit<EmotionQuestion, 'id'> {}

export interface QuestionImageDimensions {
    height: number;
    width: number;
}

export interface SideBySideQuestion
    extends QuestionWithNoAnswer,
        QuestionWithNoOpinionAnswer,
        WithRotation {
    dimensions?: QuestionImageDimensions | null;
    options: QuestionImageOption[];
    type: 'sidebyside';
}

export interface SideBySideQuestionData extends Omit<SideBySideQuestion, 'id' | 'options'> {
    options: Omit<QuestionImageOption, 'id'>[];
}

export interface Point {
    x: number;
    y: number;
}

export interface Shape {
    data: {
        p1: Point;
        p2: Point;
    };
    type: 'rectangle';
}

export interface UXQuestion extends AbstractQuestion {
    areas: Shape[];
    id: number;
    image: {
        label: string;
        url: string;
    };
    imageLabel: string;
    type: 'ux';
}

export interface UXQuestionData extends Omit<UXQuestion, 'id'> {}

export interface ClickMapVideoQuestion extends AbstractQuestion {
    after: {
        label: string;
    };
    showClicks?: boolean;
    type: 'clickmapVideo';
}

export interface ClickMapVideoQuestionData extends Omit<ClickMapVideoQuestion, 'id'> {}

export interface TableQuestion {
    boundQuestions: number[];
    id: number;
    required: boolean;
    rows: TableOptionHeader[];
}

export interface TableChoiceQuestion
    extends TableQuestion,
        QuestionWithNoOpinionAnswer,
        QuestionWithNoAnswer,
        QuestionWithOtherAnswer,
        QuestionWithOtherAnswerWithText {
    columns: TableOptionHeader[];
    maxColumnsCount?: number;
    multipleChoice: boolean;
    maxAnswerCountPerRow?: number | null;
    type: 'table';
}

export type TableChoiceQuestionData = Omit<TableChoiceQuestion, 'id'>;

export interface TableScaleQuestion extends TableQuestion, QuestionWithNoOpinionAnswer {
    maxValue: MaxScaleValue;
    minValue?: MinScaleValue;
    scaleLabels?: ScaleLabels;
    type: 'tableScale';
}

export type TableScaleQuestionData = Omit<TableScaleQuestion, 'id'>;

export type Question =
    | ChoiceQuestion
    | ClickMapVideoQuestion
    | EmailQuestion
    | DateQuestion
    | EmotionQuestion
    | GeoQuestion
    | LongTextQuestion
    | NumberQuestion
    | RatingQuestion
    | ScaleQuestion
    | ShortTextQuestion
    | SideBySideQuestion
    | TableChoiceQuestion
    | TableScaleQuestion
    | UXQuestion;

export type QuestionData =
    | ChoiceQuestionData
    | ClickMapVideoQuestionData
    | EmailQuestionData
    | DateQuestion
    | EmotionQuestionData
    | GeoQuestionData
    | LongTextQuestionData
    | NumberQuestionData
    | RatingQuestionData
    | ScaleQuestionData
    | ShortTextQuestionData
    | SideBySideQuestionData
    | TableChoiceQuestionData
    | TableScaleQuestionData
    | UXQuestionData
    | MarkupWidgetData;

export type QuestionUpdate = Partial<QuestionData>;

interface AbstractWidget extends WithLogic {
    id: number;
    isDisabled?: boolean;
    isTemplate?: boolean;
    type: WidgetType;
}

export enum TimeLimitType {
    NOT_MORE,
    NOT_LESS,
    WAIT_VIDEO_END
}

export interface MarkupWidget extends AbstractWidget, WithLabel, WithAttachment {
    hasTimeLimit: boolean;
    timeLimit?: number;
    timeLimitType?: TimeLimitType;
}

export interface MarkupWidgetData extends Omit<MarkupWidget, 'id'> {}

export type Widget = MarkupWidget;

export type WidgetData = MarkupWidgetData;

export type WidgetUpdate = Partial<WidgetData>;

export function isChoiceQuestion(q: Question | Widget): q is ChoiceQuestion {
    return q.type === 'choice';
}

export function isDualChoiceQuestion(
    q: Question | Widget
): q is ChoiceQuestion & {choiceType: 'dualChoice'} {
    return q.type === 'choice' && q.choiceType === 'dualChoice';
}

export function isScaleQuestion(q: Question | Widget): q is ScaleQuestion {
    return q.type === 'scale';
}

export function isEmotionQuestion(q: Question | Widget): q is EmotionQuestion {
    return q.type === 'emotion';
}
export function isRatingQuestion(q: Question | Widget): q is RatingQuestion {
    return q.type === 'rating';
}

export function isNumberQuestion(q: Question | Widget): q is NumberQuestion {
    return q.type === 'number';
}

export function isSideBySideQuestion(q: Question | Widget): q is SideBySideQuestion {
    return q.type === 'sidebyside';
}

export function isGeoQuestion(q: Question | Widget): q is GeoQuestion {
    return q.type === 'geoText';
}

export function isEmailQuestion(q: Question | Widget): q is EmailQuestion {
    return q.type === 'email';
}

export function isDateQuestion(q: Question | Widget): q is DateQuestion {
    return q.type === 'date';
}

export function isTableChoiceQuestion(q: Question | Widget): q is TableChoiceQuestion {
    return q.type === 'table';
}

export function isTableScaleQuestion(q: Question | Widget): q is TableScaleQuestion {
    return q.type === 'tableScale';
}

export function isTableQuestion(
    q: Question | Widget
): q is TableChoiceQuestion | TableScaleQuestion {
    return q.type === 'tableScale' || q.type === 'table';
}

export function isQuestionContainsScale(
    q: Question | Widget
): q is ScaleQuestion | TableScaleQuestion {
    return q.type === 'scale' || q.type === 'tableScale';
}

export function isQuestionWithOptions(
    q: Question | Widget
): q is ChoiceQuestion | SideBySideQuestion {
    return q.type === 'choice' || q.type === 'sidebyside';
}

export function isQuestion(q: Question | Widget | Group): q is Question {
    return QuestionTypes.includes(q.type as QuestionType);
}

export function isMarkupWidget(q: Question | Widget): q is MarkupWidget {
    return q.type === 'markup';
}

export function isWidget(q: Question | Widget | Group): q is Widget {
    return WidgetTypes.includes(q.type as WidgetType);
}

export function isShortText(q: Question | Widget): q is TableChoiceQuestion {
    return q.type === 'shortText';
}

export function isLongText(q: Question | Widget): q is TableChoiceQuestion {
    return q.type === 'longText';
}

export function isUXQuestion(q: Question | Widget): q is UXQuestion {
    return q.type === 'ux';
}

export function isChoiceTemplateQuestion(
    q: Question | Widget
): q is ChoiceQuestion & {template: ScreeningQuestionTemplate} {
    return q.type === 'choice' && Boolean(q.template);
}

export function isAgeTemplateQuestion(
    q: Question | Widget
): q is ChoiceQuestion & {template: ScreeningQuestionTemplate} {
    return q.type === 'choice' && q.template === 'age';
}
