import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './menu-burger.css';

export interface IconTypeMenuBurgerProps {
    type?: 'menu-burger';
}

export const withTypeMenuBurger = withBemMod<IconTypeMenuBurgerProps>(cnIcon(), {
    type: 'menu-burger'
});
