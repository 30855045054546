import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './gear.css';

export interface IconTypeGearProps {
    type?: 'gear';
}

export const withTypeGear = withBemMod<IconTypeGearProps>(cnIcon(), {type: 'gear'});
