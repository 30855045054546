import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './info.css';

export interface IconTypeInfoProps {
    type?: 'info';
}

export const withTypeInfo = withBemMod<IconTypeInfoProps>(cnIcon(), {type: 'info'});
