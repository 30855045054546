/* eslint-disable prefer-destructuring */
import 'whatwg-fetch';

import {handleErrorStatus} from 'client/common/utils/fetch-error-handler';
import cfg from 'client/common/utils/client-config';

import type {ApiClientParams, MethodReturnValue} from './types';

type FetchResult =
    | {
          status: number;
      }
    | {
          data: any;
          reason: string;
          status: number;
      }
    | Error;

class BaseApiClient {
    private queue: Promise<any>;
    private host: string;
    private endpoint: string;
    private credentials: RequestCredentials;
    private headers: any;
    private csrfToken: string | null;

    constructor(props: ApiClientParams) {
        const {host, path, credentials, headers} = props;

        this.queue = Promise.resolve();
        this.host = host ?? location.host;
        this.endpoint = this._getEndpoint(path);

        this.credentials = credentials || 'include';
        this.headers = Object.assign(
            {},
            {
                'Content-Type': 'application/json'
            },
            headers
        );
        this.csrfToken = null;
    }

    _getEndpoint = (path: string) => `https://${this.host}${path}`;

    // eslint-disable-next-line max-statements, complexity
    _doRequest = async (
        params: {
            method: string;
            retries?: number[];
            url: string;
            data?: any;
            file?: any;
            blob?: boolean;
            passQuery?: boolean;
            disableCSRF?: boolean;
        } & ({prefix: string} | {endpoint?: string})
    ): MethodReturnValue<any> => {
        const {url, data, method, file, passQuery = true, disableCSRF = false, blob} = params;

        let {retries} = params;

        let endpoint: string | undefined;

        if ('prefix' in params) {
            endpoint = this._getEndpoint(params.prefix);
        } else {
            endpoint = params.endpoint || this.endpoint;
        }

        const {credentials, headers} = this;

        const requestUrl = new URL(`${endpoint}${url}`);

        if (passQuery && location.search) {
            const locationParams = new URLSearchParams(location.search);

            locationParams.forEach((value, key) => {
                if (!key.startsWith('utm_'))
                    requestUrl.searchParams.append(key, value);
            });
        }

        if (!retries) {
            retries = [];
        }

        const options: any = {
            method,
            credentials,
            headers
        };

        if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) && data) {
            options.body = JSON.stringify(data);
        }

        options.headers['Content-Type'] = 'application/json';
        if (file) {
            delete options.headers['Content-Type'];
            options.body = file;
        }

        const fetchParams = {
            endpoint,
            disableCSRF,
            method,
            credentials,
            headers,
            blob,
            requestUrl,
            options,
            retries
        };

        // для get точно не нужна очередь, отправляем запросы параллельно
        if (method === 'GET') {
            return this.doFetch(fetchParams);
        }

        // Складываем запрос в очередь и продолжаем цепочку промисов, чтобы переживать флапы сети
        this.queue = this.queue.then(
            async () => this.doFetch(fetchParams),
            async () => this.doFetch(fetchParams)
        );

        return this.queue;
    };

    // Проверяем есть ли у нас непротухший csrf токен, если нет то устанавливаем его. Возвращается промис
    private async setCSRF({
        disableCSRF,
        method,
        headers,
        credentials
    }: {
        disableCSRF: boolean;
        method: string;
        headers: any;
        credentials: any;
    }) {
        // всегда ходим в /api/v0/csrf
        const endpoint = this._getEndpoint('/api/v0');

        const getCSRFUrl = `${endpoint}/user/csrf`;

        // Для этих запросов csrf не нужен
        if (disableCSRF || ['GET', 'OPTIONS', 'HEAD'].includes(method)) {
            return Promise.resolve();
        }

        // Если есть токен, устанавливаем его в заголовки
        if (this.csrfToken) {
            headers['csrf-token'] = this.csrfToken;

            return Promise.resolve();
        }

        // Получаем csrf токен
        const res = await fetch(getCSRFUrl, {
            method: 'get',
            headers,
            credentials
        });
        const {csrfToken} = await res.json();

        this.csrfToken = csrfToken;
        headers['csrf-token'] = csrfToken;

        return Promise.resolve();
    }

    private async doFetch(arg: {
        credentials: any;
        endpoint?: string;
        headers: any;
        method: string;
        options: any;
        requestUrl: URL;
        disableCSRF: boolean;
        retries?: number[];
        blob?: any;
    }): Promise<FetchResult> {
        const {disableCSRF, method, credentials, headers, requestUrl, options, blob, retries} = arg;

        return this.setCSRF({disableCSRF, method, credentials, headers})
            .then(async () => fetch(requestUrl.toString(), options))
            .then((res: any) => {
                if (blob) {
                    return res.blob().then((resBlob: Blob) => {
                        return {data: resBlob, status: res.status};
                    });
                }

                // Пытаемся распарсить JSON из ответа, если произойдет ошибка, то отдаем только статус
                return res.json().then(
                    (fetchResponseJSON: any) => {
                        const {status} = res;
                        // FetchResponseJSON может быть null
                        const reason = fetchResponseJSON && fetchResponseJSON.reason;

                        // Если неверный csrf токен, то попробуем сходить за ним еще
                        if (status === 403 && reason && reason === 'csrf') {
                            this.csrfToken = null;
                            // Кидаю error, чтобы попасть в catch и сходить за новым токеном, если остались retries
                            throw new Error('Invalid csrf token');
                        }

                        return {data: fetchResponseJSON, reason, status: res.status};
                    },
                    () => {
                        const {status} = res;

                        handleErrorStatus(status);

                        return {status};
                    }
                );
            })
            .catch(async (e: Error) => {
                // Если произошла ошибка пытаемся сделаем ретрай, если еще не закончились попытки
                if (retries && retries.length) {
                    return new Promise<void>(resolve => {
                        setTimeout(() => resolve(), retries.shift());
                    }).then(async () => this.doFetch(arg));
                }

                throw e;
            });
    }
}

const baseApiClient = new BaseApiClient(cfg.api);

export const {_doRequest} = baseApiClient;
