import React from 'react';
import b_ from 'b_';

import {Button} from 'client/common/components/lego';

import './header-notification.css';

const b = b_.with('header-notification');

export interface Props {
    message: string;
    linkUrl: string;
    linkLabel: string;
}

export default function HeaderNotification({message, linkUrl, linkLabel}: Props) {
    return (
        <div className={b()}>
            <span className={b('label')}>{message}</span>
            <Button className={b('button')} size='s' view='light' url={linkUrl} type='link'>
                {linkLabel}
            </Button>
        </div>
    );
}
