import React from 'react';

import { ui, widget } from './widget';


const widgetContextValue = {
    ui,
    widget
};

export const WidgetContext = React.createContext(widgetContextValue);

export function useWidgetContext() {
    return React.useContext(WidgetContext);
}

export const WidgetContextProvider: React.FC = ({children}) => {
    return (
        <WidgetContext.Provider value={widgetContextValue}>
                {children}
        </WidgetContext.Provider>
    );
};
