import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import I18N from 'assets/i18n/brandlift-project';

import {
    AudienceCategoryId,
    AudienceCategoryInfo,
    AudienceCategories,
    BoxType,
    POPULATION_VALUES
} from 'client/common/types';

import {
    AudienceCategoryPossibleValues,
    AudienceCategoryProperties,
    AudienceCategoryValue,
    AudienceRequest,
    AudienceType,
    ExtendedAudienceCategoryId,
    AudienceCategoryValues,
    GeoCountry,
    GeoValue,
    AudienceSettingsType
} from 'client/common/types/audience';

import {GEO_VALUE_PRIORITY} from 'client/common/components/audience-settings/consts';
import {getIsCreativeBox} from 'client/common/utils/box';

const i18n = I18N.common.audienceSettings;
const geoTranslations = I18N.common.geo;

/**
 * Собираем тут логику, про options/value из стандартных таргетингов аудитории
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TargetingOptions {
    static getDefaultValues({
        categories,
        boxType,
        selectedCountry
    }: {
        categories: AudienceCategoryInfo[];
        boxType: BoxType | undefined;
        selectedCountry: GeoCountry | undefined;
    }) {
        const targetingOptions = TargetingOptions.getTargetingOptions({
            categories,
            boxType,
            country: selectedCountry
        });

        return {
            age: map(targetingOptions.age, 'value'),
            gender: map(targetingOptions.gender, 'value'),
            geo: TargetingOptions.getDefaultGeoValue(targetingOptions.geo)
        };
    }

    static getGeoPossibleValues(categories: AudienceCategoryInfo[]) {
        return (categories.find(({id}) => id === 'geo')?.possibleValues ?? []).map(currentGeo => ({
            ...currentGeo,
            label: geoTranslations[currentGeo.value as keyof typeof geoTranslations] || ''
        }));
    }

    static getCountriesOptions(categories: AudienceCategoryInfo[], boxType: BoxType | undefined) {
        let possibleCountryValues = TargetingOptions.getGeoPossibleValues(categories).filter(
            ({properties}) => properties.regionType === 'country'
        );

        // даем выбирать Узбекистан только для простых коробок
        if (!(boxType && ['videoBox'].includes(boxType))) {
            possibleCountryValues = possibleCountryValues.filter(
                ({value}) => value !== GeoValue.Uzbekistan
            );
        }

        // в креативах/бордоматиках из стран оставляем только Россию
        if (getIsCreativeBox(boxType)) {
            possibleCountryValues = possibleCountryValues.filter(
                ({value}) => value === GeoValue.Russia
            );
        }

        return possibleCountryValues;
    }

    /**
     * Возвращает для стандартных таргетингов список options для селектов
     */
    static getTargetingOptions(params: {
        categories: AudienceCategoryInfo[];
        boxType?: BoxType;
        country?: string;
    }) {
        const {categories, boxType, country} = params;

        // Фильтруем гео по типу коробки
        let geo = TargetingOptions.getGeoPossibleValues(categories);

        geo = geo.filter(({value, properties}) => {
            if (!boxType || boxType === 'ux') {
                return (
                    properties.regionType === 'country' ||
                    [GeoValue.SaintPetersburg, GeoValue.Moscow].includes(value as GeoValue)
                );
            }

            if (getIsCreativeBox(boxType)) {
                return !properties.population || POPULATION_VALUES.includes(properties.population);
            }

            if (['videoBox', 'videoBoxKZ', 'createSegment'].includes(boxType)) {
                return !properties.population || properties.population === '1M+';
            }

            return properties.regionType === 'country';
        });

        const genderOptions = (
            categories.find(({id}) => id === 'gender')?.possibleValues ?? []
        ).map(gender => ({
            ...gender,
            label: i18n.quotas.gender[gender.value as keyof typeof i18n.quotas.gender]
        }));

        const haveCarOptions = (
            categories.find(({id}) => id === 'have_car')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['have_car'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.haveCar[entry.value]
            })
        );

        const haveChildrenOptions = (
            categories.find(({id}) => id === 'have_children')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['have_children'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.haveChildren[entry.value]
            })
        );

        const familyStatusOptions = (
            categories.find(({id}) => id === 'family_status')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['family_status'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.familyStatus[entry.value]
            })
        );

        const cohabitantsAmountOptions = (
            categories.find(({id}) => id === 'cohabitants_amount')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: AudienceCategoryValues['cohabitants_amount'];
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.cohabitantsAmount[entry.value]
            })
        );

        const citySizeOptions = (
            categories.find(({id}) => id === 'city_size')?.possibleValues ?? []
        ).map(
            (entry: {
                label: string;
                value: '1' | '2' | '3' | '4' | '5' | '6'; // TODO
                properties: AudienceCategoryProperties;
            }) => ({
                ...entry,
                label: i18n.extendedTargeting.cohabitantsAmount[entry.value]
            })
        );

        // Фильтруем гео по выбранной стране
        if (country) {
            geo = geo.filter(({properties}) => properties.country === country);
        }

        return {
            age: sortBy(categories.find(({id}) => id === 'age')?.possibleValues ?? [], 'label'),
            gender: genderOptions,
            geo: this.sortGeoOptions(geo),
            cohabitants_amount: cohabitantsAmountOptions,
            family_status: familyStatusOptions,
            have_car: haveCarOptions,
            have_children: haveChildrenOptions,
            city_size: citySizeOptions
        };
    }

    static getDefaultGeoValue(possibleValues: AudienceCategoryPossibleValues) {
        return this.sortGeoOptions(possibleValues)
            .slice(0, 1)
            .map(({value}) => value);
    }

    static getActiveAudienceType(request: AudienceRequest, withGeoType?: boolean) {
        if (withGeoType && request.type === AudienceType.RespParams) {
            const age = TargetingOptions.getAgeValue(request.categories);
            const gender = TargetingOptions.getGenderValue(request.categories);

            const hasDemographyTargeting = [age, gender].some(value => !isEmpty(value));

            return hasDemographyTargeting ? AudienceType.RespParams : 'GEO';
        }

        return request.type as AudienceSettingsType;
    }

    static sortGeoOptions(options: AudienceCategoryPossibleValues) {
        return options.slice().sort((a, b) => {
            const indexA =
                GEO_VALUE_PRIORITY.indexOf(a.value as GeoValue) + 1 || GEO_VALUE_PRIORITY.length;
            const indexB =
                GEO_VALUE_PRIORITY.indexOf(b.value as GeoValue) + 1 || GEO_VALUE_PRIORITY.length;

            return indexA - indexB;
        });
    }

    static getTargetingValues(
        request: AudienceRequest
    ): {[key in AudienceCategoryId]?: string[]} &
        {
            [key in ExtendedAudienceCategoryId]?: AudienceCategoryValues[key];
        } {
        const {categories} = request;

        return {
            age: this.getAgeValue(categories),
            gender: this.getGenderValue(categories),
            geo: this.getGeoValue(categories),
            have_car: this.getHaveCarValue(categories),
            have_children: this.getHaveChildrenValue(categories),
            family_status: this.getFamilyStatusValue(categories),
            cohabitants_amount: this.getCohabitantsAmountValue(categories),
            city_size: this.getCitySizeValue(categories)
        };
    }

    static getAvailableCategories(
        boxType: BoxType | undefined,
        audienceType: AudienceSettingsType,
        categories: AudienceRequest['categories']
    ): AudienceCategoryId[] {
        if (!boxType || !getIsCreativeBox(boxType)) {
            return ['gender', 'age', 'geo'];
        }

        if (audienceType === 'GEO') {
            return ['geo'];
        }

        const geo = this.getGeoValue(categories);
        const isDefaultGeo =
            isEmpty(geo) ||
            isEqual(geo, [GeoValue.Russia]) ||
            // тесты не запускаются вне России, так как объем Толоки в других странах очень мал и не достаточен для реализации проекта,
            // с учетом выборки на каждый тест (300 респондентов).
            isEqual(geo, [GeoValue.Kazakhstan]) ||
            isEqual(geo, [GeoValue.Belarus]) ||
            isEqual(geo, [GeoValue.Uzbekistan]);

        return isDefaultGeo ? ['age', 'gender'] : ['age', 'gender', 'geo'];
    }

    private static getAgeValue(values: AudienceCategories[]): AudienceCategoryValue[] | undefined {
        const value = values.find(category => category.categoryId === 'age')?.value;

        return value as string[] | undefined;
    }

    private static getGenderValue(
        values: AudienceCategories[]
    ): AudienceCategoryValue[] | undefined {
        const value = values.find(category => category.categoryId === 'gender')?.value;

        // Из API прилетает строка, но мультиселекту нужен список
        return typeof value === 'string' ? [value] : undefined;
    }

    private static getGeoValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'geo')?.value as
            | string[]
            | undefined;
    }

    private static getHaveCarValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'have_car')
            ?.value as AudienceCategoryValues['have_car'];
    }

    private static getHaveChildrenValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'have_children')
            ?.value as AudienceCategoryValues['have_children'];
    }

    private static getFamilyStatusValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'family_status')
            ?.value as AudienceCategoryValues['family_status'];
    }

    private static getCohabitantsAmountValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'cohabitants_amount')
            ?.value as AudienceCategoryValues['cohabitants_amount'];
    }

    private static getCitySizeValue(values: AudienceCategories[]) {
        return values.find(category => category.categoryId === 'city_size')
            ?.value as AudienceCategoryValues['city_size'];
    }
}
