import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './arrow-blue.css';

export interface IconTypeArrowBlueProps {
    type?: 'arrow-blue';
}

export const withTypeArrowBlue = withBemMod<IconTypeArrowBlueProps>(cnIcon(), {type: 'arrow-blue'});
