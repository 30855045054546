import {RequestError, RevisionContext} from 'client/common/types';

import {_doRequest} from '../../base';
import {
    RevisionResponse,
    CreateRevisionParams,
    UpdateRevisionParams,
    RevisionContextResponse,
    UpdateComponentsParams,
    ChangePositionParams,
    GetConnectionsParams,
    GetConnectionsResponse,
    UpdateLabelsParams
} from '../../types';

export const getRevision = async ({
    slug,
    revisionIndex,
    apiLocation = 'survey'
}: {
    slug: string;
    revisionIndex: number;
    apiLocation: string;
}): Promise<RevisionResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `${apiLocation}/${slug}/revision/${revisionIndex}`
    };

    const res = await _doRequest(requestParams);

    // FIXME: Убрать, когда научимся выкидывать ошибки в ApiClient
    if (res.status >= 400) {
        throw new RequestError(res.status, res.data);
    }

    return res;
};

export const createRevision = async ({
    slug,
    revisionId
}: CreateRevisionParams): Promise<RevisionResponse> => {
    const requestParams = {
        method: 'POST',
        url: revisionId ? `survey/${slug}/revision?from=${revisionId}` : `survey/${slug}/revision`
    };

    return _doRequest(requestParams);
};

export const updateRevision = async ({slug, revisionId, data}: UpdateRevisionParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}`,
        data
    };

    return _doRequest(requestParams);
};

export const getRevisionContext = async ({
    slug,
    revisionIndex,
    apiLocation = 'survey'
}: {
    slug: string;
    revisionIndex: number;
    apiLocation?: string;
}): Promise<RevisionContextResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `${apiLocation}/${slug}/revision/${revisionIndex}/context`
    };

    const res = await _doRequest(requestParams);

    if (res.status >= 400) {
        throw new RequestError(res.status, res.data);
    }

    return res;
};

export const updateRevisionContextTemplate = async ({
    slug,
    revisionId,
    template
}: {
    slug: string;
    revisionId: number;
    template: RevisionContext['template'];
}): Promise<any> => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/context/template`,
        data: template
    };

    return _doRequest(requestParams);
};

export const updateRevisionContextSettings = async ({
    slug,
    revisionId,
    settings
}: {
    slug: string;
    revisionId: number;
    settings: RevisionContext['settings'];
}): Promise<any> => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/context/settings`,
        data: settings
    };

    return _doRequest(requestParams);
};

export const updateBranding = async ({
    slug,
    revisionId,
    branding
}: {
    slug: string;
    revisionId: number;
    branding: RevisionContext['branding'];
}): Promise<any> => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/context/branding`,
        data: branding
    };

    return _doRequest(requestParams);
};

export const updateRevisionContextFeedback = async ({
    slug,
    revisionId,
    feedback
}: {
    slug: string;
    revisionId: number;
    feedback: RevisionContext['feedback'];
}): Promise<any> => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/context/feedback`,
        data: feedback
    };

    return _doRequest(requestParams);
};

export const updateComponents = async ({
    slug,
    revisionId,
    components,
    fields
}: UpdateComponentsParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/components`,
        data: {
            components,
            fields
        }
    };

    return _doRequest(requestParams);
};

export const changePosition = async ({
    slug,
    revisionId,
    child,
    pageId,
    position
}: ChangePositionParams) => {
    const requestParams = {
        method: 'PUT',
        url: `survey/${slug}/revision/${revisionId}/groupChildPosition`,
        data: {
            child,
            groupId: pageId,
            position
        },
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const getConnections = async (
    params: GetConnectionsParams
): Promise<GetConnectionsResponse> => {
    const {componentIds, componentType, revisionId, scope, slug} = params;

    const idsString = componentIds.map(id => `id=${id}`).join('&');
    const requestParams = {
        method: 'GET',
        url: `survey/${slug}/revision/${revisionId}/${componentType}/connections?scope=${scope}&${idsString}`
    };

    const res = await _doRequest(requestParams);

    // FIXME: Поправить формат ответа, чтобы не приходилось оборачивать
    res.data = {
        ...res.data,
        groups: res.data.groups?.map((id: string) => ({id: parseInt(id, 10)})) ?? [],
        questions: res.data.questions?.map((id: string) => ({id: parseInt(id, 10)})) ?? [],
        rotations: (res.data.rotations || []).map((id: string) => ({id: parseInt(id, 10)})) ?? [],
        options:
            res.data.options?.map(({id, questionId}: {id: string; questionId: string}) => ({
                id: parseInt(id, 10),
                questionId: parseInt(questionId, 10)
            })) ?? [],
        widgets: res.data.widgets?.map((id: string) => ({id: parseInt(id, 10)})) ?? []
    };

    return res;
};

export const updateLabels = async ({slug, data}: UpdateLabelsParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/spss/update`,
        data
    };

    return _doRequest(requestParams);
};
