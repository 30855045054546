import React from 'react';
import b_ from 'b_';

import {createUrl} from 'client/common/utils/history';

import I18N from 'assets/i18n/root';

import './articles.css';

const i18n = I18N.articles;
const b = b_.with('articles');

const ArticlesInOrderOfAppearance = [1, 2, 3, 4, 5] as const;

const Articles = () => {
    return (
        <div className={b()}>
            <div className={b('container')}>
                {ArticlesInOrderOfAppearance.map(order => {
                    return (
                        <a
                            className={b('link')}
                            href={createUrl({link: i18n.article[order].link})}
                            key={order}
                        >
                            {i18n.article[order].title}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default Articles;
