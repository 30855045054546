import React from 'react';

type ApiLocation = 'public' | 'survey';

const SurveyApiLocationContext = React.createContext<ApiLocation>('survey');

export function useSurveyApiLocation() {
    return React.useContext(SurveyApiLocationContext) ?? 'survey';
}

/**
    public - для публичных страниц статистики
    survey - для всех остальных страниц
*/
export const SurveyApiLocationProvider = SurveyApiLocationContext.Provider;
