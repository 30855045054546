import React, {useEffect, useMemo, useState} from 'react';
import emailValidator from 'email-validator';
import b_ from 'b_';

import {Button, Spin} from 'client/common/components/lego';
import I18N from 'assets/i18n/brandlift-project';

import {getPayerFields, isObjKey} from './utils';
import {Payer, PayerCountry} from './types';
import Form from './form';

import './card-payment-form.css';

const b = b_.with('card-payment-form');

const i18n = I18N.cardPaymentForm;

interface Props {
    onClickPay: (payer: Payer) => void;
    isMultipleAccs: boolean;
    onClickChoose: () => void;
    handlePayerInput: (value: string, name: string, id: string) => void;
    loading: boolean;
    payer: Payer;
    userCountry: PayerCountry;
}

export interface FormValues {
    [key: string]: boolean;
}

const inputValidators = {
    email: (email: string) => {
        return emailValidator.validate(email);
    },
    'kz-in': (inn: string) => {
        return Boolean(inn.match(/^\d{12}$/));
    }
} as const;

export default function EditPage(props: Readonly<Props>) {
    const {
        onClickPay,
        isMultipleAccs,
        onClickChoose,
        handlePayerInput,
        loading,
        payer,
        userCountry
    } = props;

    const [formValuesValid, setFormValuesValid] = useState<FormValues>({});
    const [fieldsBlurred, setFieldsBlurred] = useState<FormValues>({});

    const payerFields = useMemo(() => getPayerFields(userCountry), [userCountry]);
    const isUseDataValid = payerFields.every(fieldName => formValuesValid[fieldName]);

    const onBlur = (fieldName: string) => {
        const changedField = {...fieldsBlurred};

        changedField[fieldName] = true;

        setFieldsBlurred(changedField);
    };

    useEffect(() => {
        const checkForm: FormValues = {};

        payerFields.forEach(fieldName => {
            const value = payer[fieldName];
            let valid = Boolean(value);

            if (isObjKey(fieldName, inputValidators) && value) {
                valid = inputValidators[fieldName](value);
                checkForm[fieldName] = valid;

                return inputValidators[fieldName](value);
            }

            checkForm[fieldName] = valid;
        });

        setFormValuesValid(checkForm);
    }, [payerFields, payer]);

    return (
        <form className={b('edit-page')}>
            {loading && (
                <div className={b('loading')}>
                    <div className={b('spinner')}>
                        <Spin size='m' progress />
                    </div>
                </div>
            )}
            <h3 className={b('title')}>{i18n.title}</h3>
            <h5 className={b('subtitle')}>{i18n.subtitle}</h5>
            <Form
                payer={payer}
                formValuesValid={formValuesValid}
                fieldsBlurred={fieldsBlurred}
                onBlur={onBlur}
                handlePayerInput={handlePayerInput}
                loading={loading}
                userCountry={userCountry}
            />
            <div className={b('buttons')}>
                {isMultipleAccs && (
                    <Button view='dl-default' size='m' onClick={onClickChoose} disabled={loading}>
                        {i18n.selectOtherAcc}
                    </Button>
                )}
                <Button
                    view='dl-action'
                    size='m'
                    className={b('pay-btn', {mode: isMultipleAccs ? 'couple' : 'single'})}
                    disabled={!isUseDataValid || loading}
                    onClick={() => onClickPay(payer)}
                >
                    {i18n.payBtn}
                </Button>
            </div>
        </form>
    );
}
