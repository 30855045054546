import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './play-filled.css';

export interface IconTypePlayFilledProps {
    type?: 'play-filled';
}

export const withTypePlayFilled = withBemMod<IconTypePlayFilledProps>(cnIcon(), {type: 'play-filled'});
