import {OptionSimple} from '@yandex-lego/components/Select';
import I18N from 'assets/i18n/brandlift-project';

const i18n = I18N.common.audienceSettings.audienceTargeting;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AudienceTargetingTabs {
    static getExtendedTabPreviewLabels() {
        return [
            i18n.regionTitle,
            i18n.genderTitle,
            i18n.ageTitle,
            i18n.haveChildrenTitle,
            i18n.familyStatusTitle,
            i18n.haveCarTitle,
            i18n.cohabitantsAmountTitle
        ];
    }
    static getStandardTabPreviewLabels(audienceTypeOptions: OptionSimple[]) {
        return audienceTypeOptions.map(i => (i.content ?? '').toString());
    }
}
