import cfg from 'client/common/utils/client-config';

import {_doRequest} from './base';
import {SuccessfulResponse} from './types';

export const getPassportRegistrationStatus = async (): Promise<
    SuccessfulResponse<{
        is_completion_recommended: boolean;
        is_completion_required: boolean;
    }>
> => {
    return _doRequest({
        method: 'GET',
        endpoint: cfg.passport.api.host,
        retries: [],
        url: '/registration_status/check',
        passQuery: false
    });
};
