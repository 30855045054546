import {compose} from '@bem-react/core';

import {
    Popup as PopupDesktop,
    withTargetAnchor,
    withViewDefault
} from '@yandex-lego/components/Popup/desktop';
import {withOutsideClick} from '@yandex-lego/components/withOutsideClick';

export const Popup = compose(withOutsideClick, withTargetAnchor, withViewDefault)(PopupDesktop);
