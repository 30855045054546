import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './share.css';

export interface IconTypeShareProps {
    type?: 'share';
}

export const withTypeShare = withBemMod<IconTypeShareProps>(cnIcon(), {type: 'share'});
