import React from 'react';
import {OptionSimple as LegoSelectOption} from '@yandex-lego/components/Select';

import {AudienceSegmentInfo, AudienceSegmentValue} from 'client/common/types';

import {AudienceSegments} from './segments';
import I18N from 'assets/i18n/brandlift-project';

const i18n = I18N.common.audienceSettings;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AudienceSegmentsOptions {
    static getSegmentOptions(
        segments: AudienceSegmentInfo[],
        selected: AudienceSegmentValue[]
    ): LegoSelectOption[] {
        const options: LegoSelectOption[] = segments
            .map(segment => {
                return {
                    checkedText: segment.name,
                    content: <span title={segment.name}>{AudienceSegments.getLabel(segment)}</span>,
                    disabled: !AudienceSegments.isAvailable(segment),
                    value: segment.id.toString()
                };
            }) // Cортируем сегменты в селекте по доступности
            .sort((a, b) => Number(a.disabled) - Number(b.disabled));

        // Добавляем в селект выбранные сегменты, к которым нет доступа
        for (const segment of selected) {
            if (!options.find(({value}) => value === segment)) {
                options.push({
                    content: i18n.segments.segmentDeleted.replace('%', segment),
                    value: segment
                });
            }
        }

        return options;
    }
}
