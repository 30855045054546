import {sendGoalToMetrika, sendParamsToMetrika} from '@yandex-int/pythia-libs/lib/metrika-postman';

import {Domain} from 'types/domain';

import {MetrikaGoal, MetrikaParam} from 'client/common/types';

import cfg from 'client/common/utils/client-config';
import {tld} from 'client/common/utils/tld';

export function getCounter(): number | undefined {
    const [, path] = window.location.pathname.split('/');

    return path === 'brandlift' ? cfg.metrika.counters?.brandlift : cfg.metrika.counters?.surveys;
}

export function sendGoal(goal: MetrikaGoal) {
    const counter = getCounter();

    if (!counter) {
        return;
    }

    const [, path] = window.location.pathname.split('/');

    // Добавляем префикс `kz_` к целям казахского миниконструктора
    if (tld === Domain.Kz && path === 'quicksurvey') {
        void sendGoalToMetrika(counter, `kz_${goal}`);
    } else {
        void sendGoalToMetrika(counter, goal);
    }
}

export function sendParams(params: Array<MetrikaParam | string | number>) {
    const counter = getCounter();

    if (!counter) {
        return;
    }

    void sendParamsToMetrika(counter, params);
}
