import {ExtendedAudienceCategoryId} from 'client/common/types';

export type MatrixRow = readonly [
    m1824: number, m2534: number, m3544: number, m4554: number, m5564: number,
    f1824: number, f2534: number, f3544: number, f4554: number, f5564: number
];

export type Matrix = [
    moscow: MatrixRow,
    spb: MatrixRow,
    novosib: MatrixRow,
    ekb: MatrixRow,
    kazan: MatrixRow,
    nnovgorod: MatrixRow,
    chelyabinsk: MatrixRow,
    samara: MatrixRow,
    omsk: MatrixRow,
    rostov: MatrixRow,
    ufa: MatrixRow,
    krasnoyarsk: MatrixRow,
    voronezh: MatrixRow,
    perm: MatrixRow,
    volgograd: MatrixRow,
    half_to_mln: MatrixRow,
    one_to_five_thousands: MatrixRow
];

type MatrixDataKey = Extract<ExtendedAudienceCategoryId, 'cohabitants_amount' | 'family_status' | 'have_car' | 'have_children'>;
export type MatrixData = {[key in MatrixDataKey]: Record<number, Matrix>};

export const AGE_MAP = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4
} as const;

export const LIMIT_MATRIXES: MatrixData = {
    have_children: {
        // Нет детей
        0: [
            [260, 137, 134, 121, 111, 357, 388, 272, 238, 156],
            [104, 51, 50, 66, 48, 156, 255, 142, 123, 83],
            [32, 16, 18, 18, 20, 57, 64, 39, 48, 30],
            [32, 24, 24, 26, 20, 75, 68, 40, 44, 28],
            [28, 16, 13, 8, 9, 70, 64, 26, 25, 16],
            [25, 22, 24, 24, 18, 57, 61, 33, 39, 22],
            [23, 14, 19, 16, 8, 44, 42, 28, 28, 16],
            [16, 18, 16, 24, 17, 47, 41, 40, 32, 28],
            [19, 9, 16, 13, 14, 38, 36, 20, 28, 19],
            [24, 16, 12, 16, 12, 48, 56, 32, 29, 17],
            [24, 15, 13, 12, 12, 44, 48, 25, 23, 20],
            [20, 9, 9, 9, 8, 38, 30, 22, 19, 12],
            [25, 14, 16, 16, 8, 42, 47, 29, 23, 16],
            [24, 11, 15, 19, 11, 52, 45, 28, 24, 19],
            [16, 9, 8, 15, 7, 35, 32, 20, 20, 20],
            [218, 141, 160, 156, 110, 461, 400, 259, 276, 167],
            [164, 118, 148, 140, 102, 286, 276, 243, 253, 162]
        ],
        // Есть дети
        1: [
            [56, 124, 221, 100, 18, 89, 598, 600, 150, 22],
            [12, 31, 83, 50, 8, 28, 260, 358, 81, 8],
            [2, 16, 38, 21, 3, 12, 108, 133, 22, 4],
            [7, 24, 48, 22, 3, 12, 118, 136, 30, 4],
            [4, 16, 27, 9, 0, 14, 168, 118, 22, 4],
            [4, 12, 50, 24, 6, 7, 99, 118, 26, 2],
            [2, 12, 35, 12, 4, 8, 82, 99, 15, 0],
            [2, 17, 48, 18, 3, 8, 105, 116, 28, 0],
            [1, 9, 32, 12, 1, 8, 72, 102, 19, 0],
            [5, 13, 32, 11, 0, 8, 108, 110, 24, 3],
            [2, 20, 43, 8, 4, 13, 143, 121, 22, 0],
            [1, 8, 17, 8, 0, 8, 59, 54, 9, 0],
            [3, 11, 28, 11, 1, 8, 91, 92, 14, 0],
            [2, 16, 31, 13, 0, 9, 104, 86, 22, 0],
            [2, 6, 21, 9, 1, 6, 68, 92, 18, 2],
            [28, 130, 356, 142, 21, 116, 954, 988, 178, 16],
            [22, 96, 291, 112, 14, 96, 743, 860, 156, 12]
        ]
    },
    cohabitants_amount: {
        // Я живу один
        1: [
            [79, 35, 40, 25, 18, 60, 83, 68, 44, 32],
            [38, 16, 16, 16, 9, 28, 64, 35, 24, 18],
            [4, 5, 9, 4, 4, 12, 13, 12, 8, 8],
            [3, 8, 12, 6, 5, 12, 18, 12, 9, 5],
            [4, 3, 3, 1, 0, 10, 15, 5, 3, 1],
            [4, 1, 6, 3, 3, 12, 13, 6, 7, 4],
            [4, 4, 6, 2, 2, 5, 11, 6, 6, 4],
            [1, 3, 5, 6, 3, 8, 9, 13, 6, 9],
            [3, 3, 3, 4, 0, 6, 6, 7, 5, 5],
            [3, 2, 4, 3, 1, 7, 14, 8, 4, 3],
            [4, 4, 4, 1, 1, 4, 12, 7, 3, 6],
            [6, 3, 3, 1, 0, 8, 8, 9, 4, 2],
            [6, 3, 5, 5, 0, 8, 10, 9, 4, 2],
            [5, 3, 5, 6, 2, 9, 10, 6, 4, 6],
            [3, 2, 1, 4, 1, 4, 7, 3, 3, 4],
            [48, 43, 62, 34, 20, 73, 96, 71, 54, 44],
            [33, 37, 60, 37, 16, 35, 64, 68, 56, 48]
        ],
        // Я и еще 1 человек
        2: [
            [48, 56, 63, 48, 48, 98, 228, 159, 107, 58],
            [22, 27, 28, 27, 21, 52, 150, 102, 69, 37],
            [8, 7, 9, 9, 9, 16, 40, 28, 25, 16],
            [8, 12, 12, 15, 9, 25, 44, 33, 25, 15],
            [5, 7, 8, 4, 4, 23, 43, 20, 12, 7],
            [6, 9, 13, 7, 9, 19, 41, 25, 16, 11],
            [6, 5, 9, 8, 4, 16, 28, 22, 16, 9],
            [3, 9, 8, 8, 8, 15, 28, 28, 16, 9],
            [4, 4, 10, 6, 6, 12, 29, 18, 20, 6],
            [5, 6, 9, 6, 6, 17, 40, 25, 18, 7],
            [5, 5, 8, 3, 4, 12, 34, 20, 11, 7],
            [4, 4, 6, 6, 3, 12, 21, 14, 6, 4],
            [7, 6, 9, 8, 4, 14, 32, 20, 12, 7],
            [5, 6, 9, 8, 6, 16, 33, 20, 12, 6],
            [3, 4, 4, 4, 1, 13, 22, 18, 12, 9],
            [64, 61, 89, 73, 61, 166, 286, 214, 150, 73],
            [41, 51, 84, 68, 59, 88, 195, 197, 140, 74]
        ],
        // Я и еще 2 человека
        3: [
            [84, 80, 107, 70, 30, 129, 306, 236, 118, 40],
            [28, 22, 43, 39, 15, 48, 148, 156, 59, 18],
            [9, 10, 16, 12, 5, 19, 56, 54, 20, 5],
            [12, 15, 20, 14, 4, 23, 63, 52, 19, 6],
            [9, 11, 12, 3, 0, 19, 80, 32, 14, 4],
            [9, 12, 23, 15, 5, 18, 52, 47, 20, 2],
            [6, 10, 13, 6, 3, 13, 40, 36, 12, 0],
            [6, 8, 20, 14, 4, 15, 54, 55, 19, 3],
            [4, 4, 13, 6, 4, 11, 36, 35, 14, 2],
            [11, 9, 15, 9, 2, 16, 54, 41, 16, 3],
            [6, 12, 16, 4, 4, 18, 56, 38, 16, 2],
            [4, 4, 8, 3, 2, 12, 29, 25, 9, 0],
            [5, 8, 16, 6, 2, 15, 53, 36, 11, 3],
            [4, 9, 12, 9, 1, 15, 47, 32, 16, 3],
            [4, 4, 7, 8, 2, 10, 31, 36, 12, 5],
            [63, 76, 148, 94, 27, 159, 464, 364, 122, 30],
            [43, 62, 120, 74, 20, 104, 337, 334, 112, 26]
        ],
        // Я и еще 3 человека
        4: [
            [54, 45, 80, 48, 14, 84, 225, 230, 69, 24],
            [14, 12, 28, 19, 4, 29, 97, 128, 32, 5],
            [8, 5, 13, 7, 1, 12, 46, 48, 9, 3],
            [7, 9, 20, 8, 2, 16, 43, 48, 12, 0],
            [6, 5, 12, 4, 0, 18, 65, 54, 8, 2],
            [4, 5, 16, 11, 3, 8, 35, 48, 16, 2],
            [4, 2, 12, 4, 0, 10, 31, 43, 5, 0],
            [3, 6, 17, 7, 2, 10, 40, 44, 8, 3],
            [3, 3, 14, 3, 0, 8, 24, 40, 4, 1],
            [6, 4, 10, 4, 0, 9, 38, 44, 10, 0],
            [4, 8, 16, 5, 3, 13, 59, 52, 8, 1],
            [3, 3, 4, 3, 0, 9, 20, 18, 2, 0],
            [4, 1, 6, 3, 0, 6, 29, 37, 5, 0],
            [6, 4, 12, 5, 0, 10, 41, 36, 8, 0],
            [3, 2, 9, 3, 0, 6, 28, 32, 8, 0],
            [45, 54, 134, 60, 9, 109, 358, 406, 70, 12],
            [38, 41, 115, 50, 8, 83, 287, 347, 60, 9]
        ],
        // Я и еще 4 человека
        5: [
            [22, 13, 20, 12, 6, 40, 76, 89, 19, 8],
            [4, 2, 9, 3, 0, 12, 28, 39, 12, 3],
            [1, 0, 3, 0, 0, 4, 6, 16, 2, 0],
            [3, 1, 4, 2, 0, 4, 9, 20, 4, 0],
            [3, 1, 2, 0, 0, 8, 16, 20, 3, 0],
            [3, 0, 5, 2, 0, 2, 9, 13, 1, 0],
            [0, 0, 4, 0, 0, 2, 6, 9, 0, 0],
            [0, 0, 3, 1, 0, 2, 8, 10, 1, 0],
            [1, 0, 1, 1, 0, 4, 6, 11, 0, 0],
            [1, 0, 1, 0, 0, 4, 9, 15, 0, 0],
            [2, 0, 4, 0, 0, 6, 13, 21, 3, 0],
            [0, 0, 0, 0, 0, 2, 3, 5, 1, 0],
            [1, 0, 0, 0, 0, 3, 4, 8, 0, 0],
            [0, 2, 3, 0, 0, 4, 12, 10, 0, 0],
            [0, 0, 3, 1, 0, 1, 5, 8, 0, 0],
            [17, 12, 31, 12, 3, 40, 90, 120, 22, 6],
            [16, 10, 31, 11, 1, 38, 86, 104, 16, 6]
        ],
        // Я и еще больше 4 людей
        6: [
            [12, 7, 12, 3, 0, 24, 32, 37, 8, 6],
            [3, 0, 1, 0, 0, 8, 6, 12, 3, 1],
            [0, 0, 0, 0, 0, 2, 3, 4, 0, 0],
            [0, 0, 0, 0, 0, 0, 3, 3, 0, 0],
            [0, 0, 0, 0, 0, 1, 7, 4, 0, 0],
            [0, 0, 0, 0, 0, 0, 3, 3, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 1, 1, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 3, 1, 3, 0, 0],
            [0, 1, 0, 0, 0, 0, 2, 2, 0, 0],
            [0, 0, 0, 0, 0, 2, 6, 4, 0, 0],
            [0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 2, 1, 0, 0],
            [0, 0, 0, 0, 0, 1, 1, 2, 0, 0],
            [0, 0, 0, 0, 0, 2, 1, 2, 0, 0],
            [9, 4, 7, 3, 0, 19, 31, 32, 6, 3],
            [9, 3, 6, 3, 0, 28, 31, 28, 8, 0]
        ]
    },
    family_status: {
        // Не в браке
        0: [
            [247, 96, 88, 35, 10, 336, 232, 174, 70, 22],
            [99, 36, 33, 18, 4, 136, 147, 89, 38, 12],
            [30, 14, 12, 3, 2, 48, 43, 28, 11, 6],
            [32, 14, 13, 9, 1, 65, 45, 29, 18, 4],
            [26, 11, 9, 3, 0, 60, 43, 20, 6, 1],
            [23, 13, 16, 5, 1, 48, 35, 19, 9, 1],
            [21, 9, 12, 3, 0, 37, 23, 19, 8, 1],
            [17, 10, 13, 8, 0, 40, 24, 25, 8, 2],
            [18, 7, 10, 3, 0, 37, 19, 22, 9, 3],
            [21, 11, 7, 3, 0, 44, 32, 25, 6, 2],
            [23, 12, 9, 2, 0, 42, 29, 19, 6, 3],
            [19, 6, 6, 2, 1, 34, 16, 16, 7, 1],
            [24, 8, 8, 6, 0, 36, 28, 15, 4, 3],
            [22, 9, 9, 6, 0, 43, 32, 19, 12, 3],
            [15, 7, 4, 5, 0, 30, 19, 10, 6, 1],
            [214, 104, 112, 37, 10, 411, 246, 191, 83, 26],
            [160, 92, 110, 32, 8, 264, 181, 166, 72, 28]
        ],
        // В обычном или гражданском браке
        1: [
            [51, 144, 231, 161, 99, 99, 699, 607, 236, 99],
            [14, 41, 90, 79, 40, 44, 339, 363, 128, 54],
            [3, 16, 36, 30, 18, 19, 120, 125, 41, 16],
            [6, 33, 54, 36, 18, 18, 136, 124, 44, 16],
            [4, 19, 26, 12, 8, 22, 180, 106, 32, 12],
            [6, 16, 51, 35, 20, 16, 116, 115, 41, 13],
            [3, 15, 36, 19, 10, 14, 96, 95, 26, 9],
            [1, 22, 47, 30, 16, 14, 115, 112, 37, 12],
            [1, 11, 35, 19, 13, 9, 83, 89, 24, 8],
            [6, 16, 32, 21, 12, 12, 122, 102, 32, 11],
            [1, 22, 40, 13, 12, 16, 150, 115, 28, 9],
            [2, 9, 18, 12, 5, 12, 67, 53, 16, 6],
            [4, 12, 32, 17, 8, 13, 103, 89, 22, 8],
            [2, 16, 33, 22, 9, 16, 108, 86, 22, 8],
            [3, 9, 22, 16, 7, 10, 74, 90, 24, 15],
            [27, 148, 354, 224, 102, 154, 1032, 914, 270, 89],
            [21, 112, 282, 186, 89, 109, 780, 812, 257, 82]
        ],
        // Разведен или не живем вместе
        2: [
            [1, 10, 22, 19, 12, 4, 39, 68, 54, 29],
            [0, 3, 9, 11, 8, 0, 19, 35, 32, 10],
            [0, 0, 4, 4, 1, 0, 6, 15, 11, 4],
            [0, 0, 3, 4, 3, 0, 4, 19, 9, 5],
            [0, 0, 3, 0, 0, 0, 7, 14, 5, 1],
            [0, 0, 4, 4, 0, 0, 6, 12, 9, 3],
            [0, 1, 2, 3, 0, 0, 3, 9, 5, 3],
            [0, 0, 1, 2, 1, 0, 6, 16, 9, 8],
            [0, 0, 2, 3, 0, 0, 4, 8, 8, 4],
            [0, 0, 3, 1, 1, 0, 6, 11, 10, 4],
            [0, 0, 3, 1, 1, 0, 6, 9, 6, 3],
            [0, 0, 0, 0, 0, 0, 4, 4, 1, 1],
            [0, 0, 2, 3, 0, 0, 6, 10, 8, 1],
            [0, 0, 0, 3, 0, 0, 7, 6, 9, 3],
            [0, 0, 0, 1, 0, 0, 5, 5, 6, 3],
            [2, 9, 36, 28, 12, 3, 60, 112, 61, 33],
            [0, 6, 40, 26, 12, 3, 48, 105, 59, 35]
        ],
        // Вдова/вдовец
        3: [
            [3, 0, 2, 1, 4, 2, 2, 4, 20, 25],
            [0, 0, 0, 4, 1, 0, 0, 4, 8, 10],
            [0, 0, 0, 0, 0, 0, 0, 2, 2, 4],
            [0, 0, 0, 0, 0, 0, 0, 2, 3, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 2, 2],
            [0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 0, 0, 1, 0, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 3, 1],
            [0, 0, 0, 0, 0, 0, 0, 0, 2, 0],
            [0, 0, 0, 0, 0, 0, 0, 1, 3, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 2, 0, 1],
            [0, 0, 0, 0, 0, 0, 0, 0, 1, 3],
            [0, 0, 0, 0, 0, 0, 0, 3, 0, 1],
            [0, 0, 3, 2, 3, 0, 4, 19, 25, 26],
            [0, 0, 1, 3, 2, 0, 3, 17, 19, 25]
        ]
    },
    have_car: {
        // Нет автомобиля
        0: [
            [188, 83, 83, 59, 40, 315, 384, 289, 163, 92],
            [76, 48, 56, 40, 19, 153, 283, 256, 121, 67],
            [25, 13, 17, 8, 8, 52, 95, 108, 49, 21],
            [27, 14, 17, 14, 7, 64, 108, 92, 48, 26],
            [19, 10, 12, 6, 2, 55, 100, 65, 24, 16],
            [17, 12, 16, 11, 6, 48, 84, 86, 46, 23],
            [19, 11, 19, 9, 5, 48, 68, 68, 33, 16],
            [13, 8, 15, 12, 6, 43, 83, 94, 41, 22],
            [15, 7, 15, 10, 4, 35, 65, 76, 32, 15],
            [17, 8, 8, 6, 6, 43, 89, 73, 31, 22],
            [16, 9, 12, 4, 4, 49, 99, 60, 29, 16],
            [15, 5, 9, 5, 3, 34, 54, 44, 22, 8],
            [16, 6, 11, 8, 4, 39, 76, 68, 26, 13],
            [22, 9, 12, 15, 4, 48, 91, 77, 34, 16],
            [10, 7, 10, 6, 3, 33, 61, 69, 28, 20],
            [183, 122, 182, 104, 44, 500, 792, 719, 316, 152],
            [300, 215, 360, 216, 99, 816, 1398, 1344, 638, 294]
        ],
        // Пользуюсь автомобилем родственника
        1: [
            [24, 16, 21, 12, 6, 28, 104, 76, 29, 12],
            [6, 6, 12, 5, 4, 12, 65, 76, 21, 7],
            [4, 2, 3, 3, 0, 6, 30, 26, 8, 3],
            [3, 2, 6, 3, 1, 6, 36, 35, 12, 2],
            [4, 3, 4, 0, 2, 14, 54, 32, 7, 2],
            [1, 2, 4, 1, 0, 3, 30, 29, 10, 0],
            [1, 3, 3, 2, 0, 2, 24, 26, 7, 1],
            [2, 3, 3, 2, 0, 4, 28, 27, 4, 0],
            [0, 0, 1, 2, 0, 3, 16, 22, 5, 0],
            [5, 3, 2, 0, 1, 4, 30, 28, 6, 2],
            [2, 3, 3, 2, 1, 8, 44, 33, 6, 2],
            [0, 0, 2, 0, 0, 3, 19, 12, 4, 0],
            [4, 0, 4, 0, 0, 3, 28, 24, 6, 0],
            [2, 3, 2, 2, 0, 2, 19, 19, 4, 0],
            [2, 1, 1, 0, 0, 3, 20, 19, 5, 3],
            [21, 23, 44, 28, 8, 44, 236, 204, 56, 19],
            [36, 43, 100, 45, 21, 70, 416, 406, 118, 40]
        ],
        // Есть личный автомобиль
        2: [
            [100, 77, 139, 96, 63, 47, 202, 217, 80, 36],
            [36, 41, 89, 73, 31, 12, 110, 152, 57, 28],
            [3, 14, 33, 25, 12, 3, 42, 60, 18, 9],
            [9, 20, 38, 25, 12, 8, 72, 72, 33, 8],
            [8, 23, 33, 16, 6, 9, 73, 60, 14, 3],
            [9, 16, 47, 28, 14, 5, 44, 69, 20, 3],
            [4, 12, 31, 15, 8, 3, 44, 47, 12, 3],
            [3, 19, 33, 25, 11, 3, 32, 53, 16, 6],
            [4, 11, 26, 14, 8, 2, 24, 38, 12, 3],
            [5, 16, 29, 16, 9, 3, 43, 60, 19, 6],
            [5, 19, 35, 17, 15, 4, 49, 60, 14, 0],
            [4, 12, 17, 13, 8, 3, 32, 28, 6, 4],
            [7, 12, 24, 13, 5, 5, 35, 45, 12, 3],
            [1, 9, 25, 14, 8, 3, 38, 40, 10, 2],
            [4, 7, 24, 19, 6, 2, 29, 41, 12, 2],
            [40, 137, 332, 177, 84, 52, 348, 385, 117, 31],
            [70, 212, 588, 361, 137, 70, 528, 707, 220, 76]
        ]
    }
};

export type LimitMatrixesType = typeof LIMIT_MATRIXES;
