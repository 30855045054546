import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './finger-up-black.css';

export interface IconTypeFingerUpBlackProps {
    type?: 'finger-up-black';
}

export const withTypeFingerUpBlack = withBemMod<IconTypeFingerUpBlackProps>(cnIcon(), {type: 'finger-up-black'});
