import {SurveyScope} from '../types';

export const getStaffUrl = (login: string) => `https://staff.yandex-team.ru/${login}`;
export const getStaffAvatarUrl = (login: string) =>
    `https://center.yandex-team.ru/api/v1/user/${login}/avatar/100.jpg`;

export const isStaffEmail = (email: string | undefined) => {
    return email && email.endsWith('@yandex-team.ru');
};

export const isYandexTeamDomain = window.location.host.endsWith('.yandex-team.ru');
export const YandexTeamSurveyScopes = new Set([SurveyScope.YandexNet, SurveyScope.YandexTeam]);

export const getABCUrl = (abc_id: string) => `https://abc.yandex-team.ru/services/${abc_id}`;
