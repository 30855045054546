import React from 'react';
import b_ from 'b_';

import {Textinput} from 'client/common/components/lego';
import {FormValues} from 'client/common/components/card-payment-form/edit-page';

import {Payer, PayerCountry, PayerPersonalInfo} from './types';

import {PAYER_LABELS, PAYER_INPUT_TYPES, PAYER_PLACEHOLDERS, getPayerFieldsLayout} from './utils';

import './card-payment-form.css';

const b = b_.with('card-payment-form');

const yesno = (condition: boolean) => (condition ? 'yes' : 'no');

interface Props {
    payer: Payer;
    handlePayerInput: (value: string, fieldName: string, payerId: string) => void;
    readonly?: boolean;
    formValuesValid: FormValues;
    fieldsBlurred: FormValues;
    onBlur: (fieldName: string) => void;
    loading: boolean;
    userCountry: PayerCountry;
}

interface InputProps extends Props {
    fieldName: keyof PayerPersonalInfo;
    isValid: boolean;
    onBlur: (fieldName: string) => void;
}

function GenericInput(props: InputProps) {
    const {
        payer,
        handlePayerInput = () => {},
        isValid,
        fieldName,
        onBlur,
        readonly = false,
        loading
    } = props;

    const value = payer[fieldName];

    return (
        <label key={fieldName} className={b('row')}>
            <span className={b('label')}>{PAYER_LABELS[fieldName]}</span>
            {readonly ? (
                <div className={b('text-value')}>{payer[fieldName]}</div>
            ) : (
                <Textinput
                    value={value}
                    view='dl'
                    placeholder={PAYER_PLACEHOLDERS[fieldName]}
                    size='m'
                    type={PAYER_INPUT_TYPES[fieldName]}
                    name={fieldName}
                    pin='round-round'
                    onBlur={() => onBlur(fieldName)}
                    state={isValid ? undefined : 'error'}
                    disabled={loading}
                    onChange={e => handlePayerInput(e.target.value, fieldName, payer.id)}
                />
            )}
        </label>
    );
}

export default function Form(props: Readonly<Props>) {
    const {userCountry, loading, formValuesValid, fieldsBlurred} = props;

    return (
        <div className={b('form', {loading: yesno(loading)})}>
            {getPayerFieldsLayout(userCountry).map(group => {
                return (
                    <div key={group[0]} className={b('form-input-group')}>
                        {group.map(fieldName => {
                            return (
                                <GenericInput
                                    key={fieldName}
                                    {...props}
                                    isValid={
                                        formValuesValid[fieldName] || !fieldsBlurred[fieldName]
                                    }
                                    fieldName={fieldName}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
