import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './wallet.css';

export interface IconTypeWalletProps {
    type?: 'wallet';
}

export const withTypeWallet = withBemMod<IconTypeWalletProps>(cnIcon(), {type: 'wallet'});
