import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './pause.css';

export interface IconTypePauseProps {
    type?: 'pause';
}

export const withTypePause = withBemMod<IconTypePauseProps>(cnIcon(), {type: 'pause'});
