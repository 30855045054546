import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './info-orange.css';

export interface IconTypeInfoOrangeProps {
    type?: 'info-orange';
}

export const withTypeInfoOrange = withBemMod<IconTypeInfoOrangeProps>(cnIcon(), {
    type: 'info-orange'
});
