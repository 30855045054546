import {compose, composeU} from '@bem-react/core';

import {
    Spin as SpinDesktop,
    withPositionCenter,
    withSizeXXS,
    withSizeXS,
    withSizeS,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Spin/desktop';

export const Spin = compose(
    withPositionCenter,
    composeU(withSizeXXS, withSizeXS, withSizeS, withSizeM),
    withViewDefault
)(SpinDesktop);
