import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './money-spent.css';

export interface IconTypeMoneySpentProps {
    type?: 'money-spent';
}

export const withTypeMoneySpent = withBemMod<IconTypeMoneySpentProps>(cnIcon(), {type: 'money-spent'});
