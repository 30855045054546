import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './error.css';

export interface IconTypeErrorProps {
    type?: 'error';
}

export const withTypeError = withBemMod<IconTypeErrorProps>(cnIcon(), {type: 'error'});
