import {SurveyState} from 'client/common/store/reducers/survey/types';
import {_doRequest} from 'client/common/utils/api-client/base';
import {SuccessfulResponse} from 'client/common/utils/api-client/types';

interface Orgs {
    id: number;
    name: string;
    description: string;
    created_at: string;
    owner_id: string;
    // countryid?: null;
    // currency?: null;
}

export interface OrgResponse {
    org: Orgs;
}

export interface OrgsResponse {
    orgs: Orgs[];
}

interface OrgSurvey {
    surveyId: number;
    slug: string;
    state: SurveyState;
    name: string;
    createdAt: string;
    box: any;
}

export interface OrgSurveyResponse {
    surveys: OrgSurvey[];
}

export const getOrg = async (orgId: number): Promise<SuccessfulResponse<OrgResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}`
    } as const;

    return _doRequest(requestParams);
};

export const getOrgs = async (): Promise<SuccessfulResponse<OrgsResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: '/orgs'
    } as const;

    return _doRequest(requestParams);
};

export const getOrgSurveys = async (
    orgId: number
): Promise<SuccessfulResponse<OrgSurveyResponse>> => {
    const requestParams = {
        method: 'GET',
        prefix: '/api/v1',
        url: `/orgs/${orgId}/surveys/`
    } as const;

    return _doRequest(requestParams);
};

export const chooseUserOrg = async (
    orgId: number | null
): Promise<SuccessfulResponse<OrgSurveyResponse>> => {
    const requestParams = {
        method: 'POST',
        prefix: '/api/v1',
        url: '/orgs/choose',
        data: {orgId: orgId ?? null},
        passQuery: false
    } as const;

    return _doRequest(requestParams);
};

export * from './roles';
export * from './users';
