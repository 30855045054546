import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './dl-action.css';

export interface ButtonViewDLActionProps {
    view?: 'dl-action';
}

export const withViewDLAction = withBemMod<ButtonViewDLActionProps>(cnButton(), {
    view: 'dl-action'
});
