import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './cancel.css';

export interface IconTypeCancelProps {
    type?: 'cancel';
}

export const withTypeCancel = withBemMod<IconTypeCancelProps>(cnIcon(), {type: 'cancel'});
