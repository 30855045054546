import useWindowSize from 'react-use/lib/useWindowSize';

import {Device, getDeviceByWidth} from 'client/common/utils/device-width';

export function useDevice() {
    const {width} = useWindowSize();

    return getDeviceByWidth(width);
}

export function useIsMobileOrTablet() {
    const device = useDevice();

    return device === Device.Mobile || device === Device.Tablet;
}
