import {QueryKey, useQuery} from 'react-query';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import useSessionStorage from 'react-use/lib/useSessionStorage';

import {getPassportRegistrationStatus} from 'client/common/utils/api-client/passport';
import {tld} from 'client/common/utils/tld';
import {useMemo} from 'react';

export function getPassportRegistrationStatusKey(): QueryKey {
    return ['user', 'passport', 'registration-status'];
}

const redirect = `https://passport.yandex.${tld}/auth/complete?retpath=${encodeURIComponent(
    location.href
)}`;

const prefix = 'pythia-passport-registration';

export function usePassportRegistrationStatus() {
    const [isRecommended, setIsRecommended] = useLocalStorage<true | false | undefined>(
        `${prefix}-recommended`,
        undefined
    );
    const [isRequired, setIsRequired] = useLocalStorage<true | false | undefined>(
        `${prefix}-required`,
        undefined
    );
    const [isAttemptedRedirect, setIsAttemptedRedirect] = useSessionStorage<boolean>(
        `${prefix}-attempted`,
        false
    );

    useQuery(
        getPassportRegistrationStatusKey(),
        async () => {
            const {data} = await getPassportRegistrationStatus();

            setIsRecommended(data.is_completion_recommended);
            setIsRequired(data.is_completion_required);

            return data;
        },
        // проверяем статус для недорегистрированных или для новых, статус которых еще проверяли
        {enabled: isRecommended || isRecommended === undefined}
    );

    // кидаем в паспорт если в текущей сессии уже не редиректили
    if (isRequired && !isAttemptedRedirect) {
        setIsAttemptedRedirect(true);

        setTimeout(() => {
            location.href = redirect;
        }, 1);
    }

    const shouldShow = isRecommended && !isAttemptedRedirect;

    return useMemo(() => {
        return {shouldShow, redirect};
    }, [shouldShow]);
}
