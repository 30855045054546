import {SurveySlug} from 'client/common/types';

import {_doRequest} from '../base';
import {SurveyPackageInfoResponse, SurveyBindedPackageResponse} from '../types';

export const getSurveyPackageInfo = async ({
    slug
}: {
    slug: SurveySlug;
}): Promise<SurveyPackageInfoResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `packages?slug=${slug}`
    };

    return _doRequest(requestParams);
};

export const getSurveyBindedPackage = async ({
    slug
}: {
    slug: SurveySlug;
}): Promise<SurveyBindedPackageResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `packages/survey/${slug}`
    };

    return _doRequest(requestParams);
};
