import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './copy-active.css';

export interface IconTypeCopyProps {
    type?: 'copy-active';
}

export const withTypeCopyActive = withBemMod<IconTypeCopyProps>(cnIcon(), {type: 'copy-active'});
