import React, {ChangeEvent, useState} from 'react';
import b_ from 'b_';
import {logError} from '@yandex-int/pythia-libs/lib/rum';

import {BillingPayer} from 'client/common/types';

import {Button, Textinput, Spin} from 'client/common/components/lego';
import CardPaymentForm from 'client/common/components/card-payment-form';

import {useModal} from 'client/common/hooks/modal';
import {useCreateWalletTopUpLink, usePayers, useWallet} from 'client/common/hooks/payment';
import {useIsAvailableUserCurrency, useUserCountryId} from 'client/common/hooks/user';

import {formatPriceValue, currencySymbolFromCurrency} from 'client/common/utils/format';
import {openLink, reload} from 'client/common/utils/window';
import {getWalletBalanceLabels} from 'client/common/utils/price';

import {isPaymentAsIndividualAvailable} from 'client/common/utils/payment';

import I18N from 'assets/i18n/brandlift-project';

import {WalletTopUpProps, WalletButtonProps} from './types';

import './wallet.css';
import {PayerCountry} from '../card-payment-form/types';

const DEFAULT_WALLET_TOPUP_AMOUNT = 0;

const i18n = I18N.common.wallet;

const b = b_.with('wallet');

// eslint-disable-next-line max-statements
export function WalletTopUp(props: WalletTopUpProps) {
    const {onClose, isAvailableCurrency} = props;

    const [isFetchingLink, setIsFetchingLink] = useState(false);
    const [amount, setAmount] = useState(DEFAULT_WALLET_TOPUP_AMOUNT);

    const walletQuery = useWallet();
    const payers = usePayers();

    const createWalletTopUpLink = useCreateWalletTopUpLink();
    const userCountryId = useUserCountryId();

    const canPayAsIndividual = isPaymentAsIndividualAvailable(userCountryId);

    const [showModal, hideModal] = useModal();

    if (walletQuery.status !== 'success') {
        return <Spin size='s' view='default' progress />;
    }

    const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
        const newAmount = parseInt(event.target.value, 10) || 0;

        setAmount(newAmount);
    };

    const handleClickEntityButton = async () => {
        setIsFetchingLink(true);

        const link = await createWalletTopUpLink(amount);

        openLink(link);
    };

    const handleCreatePaymentLink = async (payer: BillingPayer) => {
        return createWalletTopUpLink(amount, payer);
    };

    const handlePaymentFormFinish = () => {
        onClose();
        hideModal();
        reload();
    };

    const handleClickIndividualButton = () => {
        showModal(
            <CardPaymentForm
                createPaymentLink={handleCreatePaymentLink}
                onError={err => logError({message: 'Error when paying for wallet', err})}
                onFinish={handlePaymentFormFinish}
                payers={payers.data}
                userCountry={String(userCountryId) as PayerCountry}
            />,
            {withCross: true}
        );
    };

    const isValidAmount = amount > 0;

    const {balanceLabel, vatHint} = getWalletBalanceLabels(walletQuery.data);
    const {vat, currency, vat_multiplier: vatMultiplier} = walletQuery.data;

    return (
        <div className={b()}>
            <h2 className={b('header')}>
                {i18n.header.replace('%s', balanceLabel)}
                {vatHint && <span className={b('vat-label')}>{` ${vatHint} ${i18n.withVAT}`}</span>}
            </h2>
            {isAvailableCurrency ? (
                <>
                    <span className={b('description')}>{i18n.description}</span>
                    <div className={b('amount')}>
                        <Textinput
                            className={b('amount-input')}
                            type='number'
                            onChange={handleChangeAmount}
                            size='m'
                            value={amount.toString()}
                            view='default'
                        />
                        <span className={b('amount-currency')}>
                            {currencySymbolFromCurrency(currency || 'RUB')}
                        </span>
                    </div>
                    {!vat && vatMultiplier && (
                        <span className={b('amount-vat-hint')}>
                            {`${formatPriceValue({
                                value: parseFloat((amount * vatMultiplier).toFixed(2)),
                                currency: currency || 'RUB'
                            })} ${i18n.withVAT}`}
                        </span>
                    )}
                    <div className={b('buttons')}>
                        <Button
                            disabled={!isValidAmount}
                            onClick={handleClickEntityButton}
                            size='m'
                            view={canPayAsIndividual ? 'default' : 'action'}
                            width='max'
                            progress={isFetchingLink}
                        >
                            {i18n.topUpAsEntity}
                        </Button>
                        {canPayAsIndividual && (
                            <Button
                                disabled={!isValidAmount}
                                onClick={handleClickIndividualButton}
                                size='m'
                                view='action'
                                width='max'
                            >
                                {i18n.topUp}
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <span className={b('description')}>{i18n.replenishmentNotPossible}</span>
            )}
        </div>
    );
}

// eslint-disable-next-line max-statements
export function WalletButton(props: WalletButtonProps) {
    const {className} = props;

    const walletQuery = useWallet();

    const [showModal, hideModal] = useModal();
    const isAvailableCurrency = useIsAvailableUserCurrency();

    if (walletQuery.status !== 'success') {
        return <Spin className={className} size='xs' view='default' progress />;
    }

    const {
        currency,
        balance_with_vat: balanceWithVat,
        balance_without_vat: balanceWithoutVat
    } = walletQuery.data;

    if (!currency) {
        return null;
    }

    const handleClick = () => {
        showModal(<WalletTopUp onClose={hideModal} isAvailableCurrency={isAvailableCurrency} />, {
            withCross: true
        });
    };

    return (
        <div className={b('buttons-wrapper')}>
            <Button className={className} onClick={handleClick} size='s'>
                {formatPriceValue({value: balanceWithoutVat || balanceWithVat, currency})}
            </Button>
        </div>
    );
}
