import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './help-yellow.css';

export interface IconTypeHelpYellowProps {
    type?: 'help-yellow';
}

export const withTypeHelpYellow = withBemMod<IconTypeHelpYellowProps>(cnIcon(), {
    type: 'help-yellow'
});
