import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './check-filled-small.css';

export interface IconTypeCheckFilledSmallProps {
    type?: 'check-filled-small';
}

export const withTypeCheckFilledSmall = withBemMod<IconTypeCheckFilledSmallProps>(cnIcon(), {
    type: 'check-filled-small'
});
