import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './filter.css';

export interface IconTypeFilterProps {
    type?: 'filter';
}

export const withTypeFilter = withBemMod<IconTypeFilterProps>(cnIcon(), {type: 'filter'});
