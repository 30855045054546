/* eslint-disable quotes */
export const DIRECT_GEO_VALUES = {
    "WORLD": {
        "ALL": 0
    },
    "ADMINISTRATIVE_AREA": {
        "MOSCOW_AND_MOSCOW_OBLAST": 1,
        "CENTRAL_FEDERAL_DISTRICT": 3,
        "JEWISH_AUTONOMOUS_OBLAST": 10243,
        "NORTHWESTERN_FEDERAL_DISTRICT": 17,
        "SOUTHERN_FEDERAL_DISTRICT": 26,
        "VOLGA_FEDERAL_DISTRICT": 40,
        "NORTH_CAUCASIAN_FEDERAL_DISTRICT": 102444,
        "BELGRADE_DISTRICT": 104492,
        "URAL_FEDERAL_DISTRICT": 52,
        "SIBERIAN_FEDERAL_DISTRICT": 59,
        "FAR_EASTERN_FEDERAL_DISTRICT": 73,
        "ENGLAND": 104520,
        "CAMBRIDGESHIRE_COUNTY": 104612,
        "HONG_KONG": 10584,
        "BELGOROD_OBLAST": 10645,
        "BRYANSK_OBLAST": 10650,
        "VLADIMIR_OBLAST": 10658,
        "VORONEZH_OBLAST": 10672,
        "IVANOVO_OBLAST": 10687,
        "KALUGA_OBLAST": 10693,
        "KOSTROMA_OBLAST": 10699,
        "KURSK_OBLAST": 10705,
        "LIPETSK_OBLAST": 10712,
        "ORYOL_OBLAST": 10772,
        "RYAZAN_OBLAST": 10776,
        "SMOLENSK_OBLAST": 10795,
        "TAMBOV_OBLAST": 10802,
        "TVER_OBLAST": 10819,
        "TULA_OBLAST": 10832,
        "YAROSLAVL_OBLAST": 10841,
        "ARKHANGELSKAYA_OBLAST": 10842,
        "VOLOGDA_OBLAST": 10853,
        "KALININGRAD_OBLAST": 10857,
        "MURMANSK_OBLAST": 10897,
        "NOVGOROD_OBLAST": 10904,
        "PSKOV_OBLAST": 10926,
        "REPUBLIC_OF_KARELIA": 10933,
        "KOMI_REPUBLIC": 10939,
        "ASTRAKHAN_OBLAST": 10946,
        "VOLGOGRAD_OBLAST": 10950,
        "AKMOLINSK_DISTRICT": 29403,
        "ALMA_ATA_DISTRICT": 29406,
        "ATYRAU_DISTRICT": 29407,
        "EAST_KAZAKHSTAN_DISTRICT": 29408,
        "ZHAMBYLSK_DISTRICT": 29409,
        "KARAGANDA_DISTRICT": 29411,
        "KOSTANAI_DISTRICT": 29412,
        "KYZYLORDA_DISTRICT": 29413,
        "MANGYSTAU_DISTRICT": 29414,
        "PAVLODAR_DISTRICT": 29415,
        "NORTH_KAZAKHSTAN_DISTRICT": 29416,
        "TURKESTAN_DISTRICT": 29417,
        "KRASNODAR_KRAI": 10995,
        "REPUBLIC_OF_ADYGEA": 11004,
        "REPUBLIC_OF_DAGESTAN": 11010,
        "REPUBLIC_OF_INGUSHETIA": 11012,
        "THE_KABARDINO_BALKAR_REPUBLIC": 11013,
        "REPUBLIC_OF_KALMYKIA": 11015,
        "KARACHAY_CHERKESS_REPUBLIC": 11020,
        "REPUBLIC_OF_NORTH_OSSETIA_ALANIA": 11021,
        "CHECHEN_REPUBLIC": 11024,
        "ROSTOV_OBLAST": 11029,
        "STAVROPOL_KRAI": 11069,
        "KIROV_OBLAST": 11070,
        "MARI_EL_REPUBLIC": 11077,
        "NIZHNY_NOVGOROD_OBLAST": 11079,
        "ORENBURG_OBLAST": 11084,
        "PENZA_OBLAST": 11095,
        "PERM_KRAI": 11108,
        "REPUBLIC_OF_BASHKORTOSTAN": 11111,
        "REPUBLIC_OF_MORDOVIA": 11117,
        "REPUBLIC_OF_TATARSTAN": 11119,
        "SAMARA_OBLAST": 11131,
        "SARATOV_OBLAST": 11146,
        "UDMURT_REPUBLIC": 11148,
        "ULYANOVSK_OBLAST": 11153,
        "CHUVASH_REPUBLIC": 11156,
        "KURGAN_OBLAST": 11158,
        "SVERDLOVSK_OBLAST": 11162,
        "TYUMEN_OBLAST": 11176,
        "KHANTY_MANSI_AUTONOMOUS_OKRUG_YUGRA": 11193,
        "MOGILEV_DISTRICT": 29629,
        "MINSK_DISTRICT": 29630,
        "GOMEL_DISTRICT": 29631,
        "GRODNO_DISTRICT": 29634,
        "REPUBLIC_OF_CRIMEA": 977,
        "CHELYABINSK_OBLAST": 11225,
        "YAMALO_NENETS_AUTONOMOUS_OKRUG": 11232,
        "ALTAI_KRAI": 11235,
        "IRKUTSK_OBLAST": 11266,
        "KEMEROVO_OBLAST": 11282,
        "KRASNOYARSK_KRAI": 11309,
        "NOVOSIBIRSK_OBLAST": 11316,
        "OMSK_OBLAST": 11318,
        "REPUBLIC_OF_BURYATIA": 11330,
        "REPUBLIC_OF_KHAKASSIA": 11340,
        "TOMSK_DISTRICT": 11353,
        "AMUR_OBLAST": 11375,
        "KAMCHATKA_KRAI": 11398,
        "MAGADAN_DISTRICT": 11403,
        "PRIMORSKY_KRAI": 11409,
        "SAKHA_YAKUTIA_REPUBLIC": 11443,
        "SAKHALIN_DISTRICT": 11450,
        "KHABAROVSK_KRAI": 11457,
        "GURIA": 122055,
        "AUTONOMOUS_REPUBLIC_OF_ADJARA": 122054,
        "RACHA_LECHKHUMI_AND_KVEMO_SVANETI": 122059,
        "MTSKHETA_MTIANETI": 122058,
        "SAMTSKHE_JAVAKHETI": 122061,
        "SHIDA_KARTLI": 122063,
        "KVEMO_KARTLI": 122062,
        "ADANA": 103669,
        "AYDIN": 103671,
        "ADIYAMAN": 103670,
        "AMASYA": 103673,
        "AKSARAY": 103672,
        "ANTALYA": 103675,
        "ANKARA": 103674,
        "ARTVIN": 103677,
        "ARDAHAN": 103676,
        "BAYBURT": 103679,
        "AFYONKARAHISAR": 103678,
        "BALIKESIR": 103680,
        "BILECIK": 103683,
        "BATMAN": 103682,
        "BITLIS": 103685,
        "BINGOL": 103684,
        "BURDUR": 103687,
        "BOLU": 103686,
        "VAN": 103689,
        "BURSA": 103688,
        "GIRESUN": 103691,
        "GAZIANTEP": 103690,
        "DENIZLI": 103693,
        "GUMUSHANE": 103692,
        "DUZCE": 103695,
        "DIYARBAKIR": 103694,
        "IZMIR": 103697,
        "ZONGULDAK": 103696,
        "KAYSERI": 103699,
        "YOZGAT": 103698,
        "KARAMAN": 103701,
        "KARABUK": 103700,
        "KASTAMONU": 103703,
        "KARS": 103702,
        "KILIS": 103705,
        "KAHRAMANMARAS": 103704,
        "KONYA": 103707,
        "KOCAELI": 103706,
        "KIRSEHIR": 103709,
        "KIRKLARELI": 103708,
        "KUTAHYA": 103711,
        "KIRIKKALE": 103710,
        "MANISA": 103713,
        "MALATYA": 103712,
        "MERSIN": 103715,
        "MARDIN": 103714,
        "MUS": 103717,
        "MUGLA": 103716,
        "NIGDE": 103719,
        "NEVSEHIR": 103718,
        "ORDU": 103720,
        "RIZE": 103722,
        "SIVAS": 103725,
        "SAMSUN": 103724,
        "SINOP": 103727,
        "SIIRT": 103726,
        "TEKIRDAG": 103729,
        "ISTANBUL": 103728,
        "TRABZON": 103731,
        "TOKAT": 103730,
        "USAK": 103733,
        "TUNCELI": 103732,
        "HATAY": 103735,
        "HAKKARI": 103734,
        "CANKIRI": 103737,
        "CANAKKALE": 103736,
        "SANLIURFA": 103739,
        "IGDIR": 103741,
        "ARAGATSOTN": 105789,
        "SIRNAK": 103740,
        "EDIRNE": 103743,
        "ARMAVIR_ARMENIA": 105791,
        "ISPARTA": 103742,
        "ARARAT": 105790,
        "ERZINCAN": 103745,
        "KOTAYK": 105793,
        "ELAZIG": 103744,
        "GEGHARKUNIK": 105792,
        "ESKISEHIR": 103747,
        "SHIRAK": 105795,
        "ERZURUM": 103746,
        "YALOVA": 103748,
        "SYUNIK": 105796,
        "FREE_STATE_OF_BAVARIA": 103750,
        "VAYOTS_DZOR": 105798,
        "BUKHARA_PROVINCE": 105805,
        "ANDIJAN_PROVINCE": 105804,
        "QASHQADARYO_PROVINCE": 105807,
        "JIZZAKH_PROVINCE": 105806,
        "NAMANGAN_PROVINCE": 105809,
        "SURXONDARYO_PROVINCE": 105811,
        "TASHKENT_PROVINCE": 105813,
        "SIRDARYO_PROVINCE": 105812,
        "REPUBLIC_OF_KARAKALPAKSTAN": 105816,
        "ZABAYKALSKY_KRAI": 21949,
        "HAIFA_DISTRICT": 103901,
        "CENTRAL_DISTRICT": 103900,
        "TEL_AVIV_DISTRICT": 103905,
        "SOUTHERN_DISTRICT": 103904,
        "LOWER_AUSTRIA": 104130,
        "VILNIUS_COUNTY": 104260,
        "UUSIMAA": 104357,
        "SAINT_PETERSBURG_AND_LENINGRAD_OBLAST": 10174,
        "ALTAI_REPUBLIC": 10231,
        "TYVA_REPUBLIC": 10233
    },
    "CITY": {
        "SAINT_PETERSBURG": 2,
        "BELGOROD": 4,
        "IVANOVO": 5,
        "KALUGA": 6,
        "KOSTROMA": 7,
        "KURSK": 8,
        "LIPETSK": 9,
        "OREL": 10,
        "RYAZAN": 11,
        "SMOLENSK": 12,
        "TAMBOV": 13,
        "BAKU": 10253,
        "TVER": 14,
        "TULA": 15,
        "YAROSLAVL": 16,
        "PETROZAVODSK": 18,
        "SYKTYVKAR": 19,
        "GYUMRI": 10259,
        "ARHANGELSK": 20,
        "VOLOGDA": 21,
        "KALININGRAD": 22,
        "YEREVAN": 10262,
        "MURMANSK": 23,
        "VELIKY_NOVGOROD": 24,
        "PSKOV": 25,
        "MAKHACHKALA": 28,
        "NALCHIK": 30,
        "VLADIKAVKAZ": 33,
        "GRODNO": 10274,
        "KRASNODAR": 35,
        "STAVROPOL": 36,
        "ASTRAHAN": 37,
        "TBILISI": 10277,
        "VOLGOGRAD": 38,
        "BATUMI": 10278,
        "ROSTOV_NA_DONU": 39,
        "YOSHKAR_OLA": 41,
        "SARANSK": 42,
        "KAZAN": 43,
        "ELEKTROSTAL": 20523,
        "IZHEVSK": 44,
        "CHEBOKSARY": 45,
        "RUSTAVI": 10285,
        "KIROV": 46,
        "NIZHNY_NOVGOROD": 47,
        "ORENBURG": 48,
        "PENZA": 49,
        "PERM": 50,
        "SAMARA": 51,
        "ATYRAU": 10291,
        "KURGAN": 53,
        "YEKATERINBURG": 54,
        "TYUMEN": 55,
        "KOSTANAI": 10295,
        "CHELYABINSK": 56,
        "KHANTY_MANSIYSK": 57,
        "SALEKHARD": 58,
        "PETROPAVLOVSK": 78,
        "RUDNY": 10300,
        "KRASNOYARSK": 62,
        "IRKUTSK": 63,
        "TALDIKORGAN": 10303,
        "KEMEROVO": 64,
        "NOVOSIBIRSK": 65,
        "OMSK": 66,
        "UST_KAMENOGORSK": 10306,
        "TOMSK": 67,
        "CHITA": 68,
        "ZHEZKAZGAN": 10308,
        "BISHKEK": 10309,
        "OSH": 10310,
        "KISHINEV": 10313,
        "YAKUTSK": 74,
        "VLADIVOSTOK": 75,
        "KHABAROVSK": 76,
        "SAKY": 20556,
        "BLAGOVESHCHENSK": 77,
        "MAGADAN": 79,
        "YUZHNO_SAKHALINSK": 80,
        "ALMALIK": 10328,
        "ANDIJAN": 10329,
        "BUKHARA": 10330,
        "KARSHI": 10331,
        "ZHUKOVSKIY": 20571,
        "TASHKENT": 10335,
        "PROTVINO": 20576,
        "NUKUS": 10337,
        "TERMEZ": 10338,
        "STROITEL": 20587,
        "SHCHELKINO": 28786,
        "KRASNOARMEYSK": 100471,
        "VILEYKA": 26745,
        "TEL_AVIV": 131,
        "HAIFA": 132,
        "SIMFEROPOL": 146,
        "GORKI": 26774,
        "CAMBRIDGE": 10391,
        "GOMEL": 155,
        "MINSK": 157,
        "MOGILEV": 158,
        "ALMATY": 162,
        "ASTANA": 163,
        "KARAGANDA": 164,
        "SEMEY": 165,
        "UFA": 172,
        "NEVYANSK": 20654,
        "BOGORODITSK": 20667,
        "PAVLODAR": 190,
        "BRYANSK": 191,
        "VLADIMIR": 192,
        "SEVEROURALSK": 20672,
        "VORONEZH": 193,
        "SARATOV": 194,
        "TROITSK": 11220,
        "ULYANOVSK": 195,
        "BARNAUL": 197,
        "ULAN_UDE": 198,
        "UCHALY": 20680,
        "REZH": 20684,
        "KRASNOUFIMSK": 20691,
        "NOVOGRUDOK": 26836,
        "MOSCOW": 213,
        "CHIRCHIQ": 114900,
        "DOLGOPRUDNIY": 214,
        "DUBNA": 215,
        "ZELENOGRAD": 216,
        "PUSHINO": 217,
        "CHERNOGOLOVKA": 219,
        "ARMYANSK": 28892,
        "CHIMKENT": 221,
        "SLAVYANSK_NA_KUBANI": 20704,
        "KURCHATOV": 20707,
        "BELEBEY": 20714,
        "MAGNITOGORSK": 235,
        "MELEUZ": 20715,
        "NABEREZHNIE_CHELNY": 236,
        "SIBAY": 20716,
        "NOVOKUZNETSK": 237,
        "TUYMAZY": 20717,
        "NOVOCHERKASSK": 238,
        "ISHIMBAY": 20718,
        "SOCHI": 239,
        "TOGLIATTI": 240,
        "VERHNYAYA_PYSHMA": 20720,
        "VOLZHSK": 20721,
        "KOROLEV": 20728,
        "BOBRUISK": 20729,
        "HELSINKI": 10493,
        "ABIDJAN": 20734,
        "POKROV": 37129,
        "NOVOCHEBOKSARSK": 37133,
        "BELGRADE": 10522,
        "VAWKAVYSK": 26923,
        "SLUTSK": 26951,
        "KOKSHETAU": 20809,
        "ZHLOBIN": 26966,
        "DENAU": 196980,
        "GUBKIN": 10646,
        "STARIY_OSKOL": 10649,
        "KLINTSY": 10653,
        "ALEKSANDROV": 10656,
        "GUS_CHRUSTALNIY": 10661,
        "KIRZACH": 10663,
        "KOVROV": 10664,
        "MUROM": 10668,
        "ROSSOSH": 10681,
        "KINESHMA": 10689,
        "SHUYA": 10691,
        "MALOYAROSLAVETS": 10697,
        "ZHELEZNOGORSK": 20086,
        "YELETS": 10713,
        "APRELEVKA": 10715,
        "BALASHIHA": 10716,
        "BRONNIZI": 10717,
        "VIDNOE": 10719,
        "VOLOKOLAMSK": 10721,
        "VOSKRESENSK": 10722,
        "DMITROV": 10723,
        "DOMODEDOVO": 10725,
        "EGORIEVSK": 10727,
        "ZARAYSK": 10728,
        "ZVENIGOROD": 10729,
        "ISTRA": 10731,
        "KASHIRA": 10732,
        "KLIN": 10733,
        "KOLOMNA": 10734,
        "KRASNOGORSK": 10735,
        "LUHOVICY": 10737,
        "LUBERCY": 10738,
        "MOZHAYSK": 10739,
        "MYTISCHI": 10740,
        "NARO_FOMINSK": 10741,
        "NOGINSK": 10742,
        "ODINCOVO": 10743,
        "OREHOVO_ZUEVO": 10745,
        "PAVLOVSKIY_POSAD": 10746,
        "PODOLSK": 10747,
        "PUSHKINO": 10748,
        "RAMENSKOE": 10750,
        "RUZA": 10751,
        "SERGIEV_POSAD": 10752,
        "SERPUHOV": 10754,
        "SOLNECHNOGORSK": 10755,
        "STUPINO": 10756,
        "HIMKI": 10758,
        "CHEHOV": 10761,
        "SHATURA": 10762,
        "SHELKOVO": 10765,
        "KASIMOV": 10773,
        "VYAZMA": 10782,
        "GAGARIN": 10783,
        "YARTSEVO": 10801,
        "MICHURINSK": 10803,
        "BOLOGOE": 10805,
        "VISHNIY_VOLOCHEK": 10807,
        "KYZYLORDA": 21050,
        "KIMRY": 10811,
        "KONAKOVO": 10812,
        "TEMIRTAU": 35393,
        "OSTASHKOV": 10818,
        "RZHEV": 10820,
        "UDOMLYA": 10824,
        "EFREMOV": 10828,
        "NOVOMOSKOVSK": 10830,
        "BAHCHISARAJ": 27217,
        "ASHKELON": 100944,
        "MYSHKIN": 10836,
        "PERESLAVL_ZALESSKIY": 10837,
        "RYBINSK": 10839,
        "UGLICH": 10840,
        "KOTLAS": 10846,
        "SEVERODVINSK": 10849,
        "VELIKIY_USTUG": 10854,
        "TARAZ": 21094,
        "SOVETSK": 10860,
        "VOLHOV": 10864,
        "VSEVOLGSK": 10865,
        "TURKESTAN": 21106,
        "GATCHINA": 10867,
        "KINGISEPP": 10870,
        "KIRISHI": 10871,
        "KIROVSK": 10872,
        "LUGA": 10876,
        "PODPOROGIE": 10881,
        "PRIOZERSK": 10883,
        "PUSHKIN": 10884,
        "SLANTSY": 10888,
        "SOSNOVIY_BOR": 10891,
        "TIKHVIN": 10892,
        "TOSNO": 10893,
        "APATITY": 10894,
        "KANDALAKSHA": 10895,
        "MONCHEGORSK": 10896,
        "TIMASHOVSK": 21141,
        "LIDA": 21144,
        "BOROVICHY": 10906,
        "VALDAY": 10907,
        "TUTAEV": 21154,
        "STARAYA_RUSSA": 10923,
        "VELIKIE_LUKY": 10928,
        "KONDOPOGA": 10934,
        "KOSTOMUCSHA": 10935,
        "SEGEZHA": 10936,
        "SORTAVALA": 10937,
        "VORKUTA": 10940,
        "INTA": 10941,
        "PECHORA": 10942,
        "USINSK": 10944,
        "UHTA": 10945,
        "VOLZHSKIY": 10951,
        "BORISOV": 21193,
        "KAMISHIN": 10959,
        "MIHAYLOVKA": 10965,
        "BEREZOVSKIY_SVERDLOVSK_DISTRICT": 29397,
        "URYUPINSK": 10981,
        "ARMAVIR_KRASNODAR_KRAI": 10987,
        "BELORECHENSK": 10988,
        "GELENDGIK": 10990,
        "YESK": 10993,
        "KROPOTKIN": 10996,
        "KRYMSK": 10997,
        "TIKHORETSK": 11002,
        "BUYNAKSK": 11006,
        "DERBENT": 11007,
        "KASPIYSK": 11008,
        "KIZLYAR": 11009,
        "SANKT_POLTEN": 105219,
        "KHASAVURT": 11011,
        "AZOV": 11030,
        "BATAYSK": 11033,
        "BELAYA_KALITVA": 11034,
        "MOZYR": 21274,
        "VOLGODONSK": 11036,
        "GUKOVO": 11037,
        "KAMENSK_SHAKHTINSKY": 11043,
        "NOVOSHAHTINSK": 11049,
        "SHAKHTY": 11053,
        "BUDENNOVSK": 11055,
        "GEORGIEVSK": 11056,
        "ESSENTUKY": 11057,
        "XOJAYLI": 193331,
        "KISLOVODSK": 11062,
        "MINERALNIYE_VODI": 11063,
        "NEVINNOMISSK": 11064,
        "PYATIGORSK": 11067,
        "KIROVO_CHEPETSK": 11071,
        "NAMANGAN": 21314,
        "ARZAMAS": 11080,
        "PAVLOVO": 11082,
        "SAROV": 11083,
        "BUZULUK": 11086,
        "GAY": 11087,
        "NOVOTROITSK": 11090,
        "ORSK": 11091,
        "KUZNETSK": 11101,
        "SOLIKAMSK": 11110,
        "KUMERTAU": 11113,
        "NEPHTEKAMSK": 11114,
        "SALAVAT": 11115,
        "STERLITAMAK": 11116,
        "ALMETYEVSK": 11121,
        "BUGULMA": 11122,
        "NIZHNEKAMSK": 11127,
        "CHISTOPOL": 11129,
        "ZHIGULIOVSK": 11132,
        "NOVOKUIBISHEVSK": 11135,
        "SYZRAN": 11139,
        "BALAKOVO": 11143,
        "AKTAU": 29575,
        "BALASHEV": 11144,
        "ZHANAOZEN": 29577,
        "ENGELS": 11147,
        "VOTKINSK": 11149,
        "GLAZOV": 11150,
        "SARAPUL": 11152,
        "DIMITROVGRAD": 11155,
        "EKIBASTUZ": 29590,
        "SHADRINSK": 11159,
        "ASBEST": 11160,
        "VERHNYA_SALDA": 11161,
        "KAMENSK_URALSKIY": 11164,
        "KRASNOTURINSK": 11165,
        "KUSHVA": 11166,
        "LESNOY": 11167,
        "NIZHNIY_TAGIL": 11168,
        "NIGNAYA_TURA": 11169,
        "NOVOURALSK": 11170,
        "PERVOURALSK": 11171,
        "JANKOY": 27555,
        "SEROV": 11172,
        "ISHIM": 11173,
        "TOBOLSK": 11175,
        "UGORSK": 11177,
        "YALUTOROVSK": 11178,
        "KOGALYM": 11180,
        "LANGEPAS": 11181,
        "MEGION": 11182,
        "NEFTEUGANSK": 11184,
        "NYAGAN": 11186,
        "PYT_YAH": 11188,
        "RADUGNIY": 11189,
        "URAY": 11192,
        "ASHA": 11196,
        "SEVASTOPOL": 959,
        "VERHNIY_UFALEY": 11200,
        "ZLATOUST": 11202,
        "OBNINSK": 967,
        "KOPEYSK": 11207,
        "CHEREPOVETS": 968,
        "VYBORG": 969,
        "NOVOROSSIYSK": 970,
        "TAGANROG": 971,
        "DZERZHINSK_NIZHNY_NOVGOROD_DISTRICT": 972,
        "MIASS": 11212,
        "SURGUT": 973,
        "NAHODKA": 974,
        "OZERSK": 11214,
        "BIYSK": 975,
        "BRATSK": 976,
        "SATKA": 11217,
        "SNEGINSK": 11218,
        "UST_KATAV": 11223,
        "CHEBARKUL": 11224,
        "UGNOURALSK": 11227,
        "GUBKINSKIY": 11228,
        "NADYM": 11229,
        "NOVIY_URENGOY": 11230,
        "NOYABRSK": 11231,
        "ZARINSK": 11240,
        "NOVOALTAYSK": 11246,
        "RUBTSOVSK": 11251,
        "ANGARSK": 11256,
        "NIZHNEUDINSK": 11268,
        "TAYSHET": 11270,
        "TULUN": 11271,
        "USOLE_SIBIRSKOE": 11272,
        "UST_ILIMSK": 11273,
        "CHEREMKHOVO": 11274,
        "ANGERO_SUDGENSK": 11276,
        "BELOVO": 11277,
        "BEREZOVSKIY_KEMEROVO_DISTRICT": 11278,
        "IZBERBASH": 21521,
        "KISELEVSK": 11283,
        "LENINSK_KUZNETSKIY": 11285,
        "MEZGDURECHENSK": 11287,
        "MYSKI": 11288,
        "PROKOPEVSK": 11291,
        "POLYSAEVO": 11292,
        "TUAPSE": 1058,
        "URGA": 11299,
        "ACHINSK": 11302,
        "ENISEYSK": 11304,
        "KRASNOPEREKOPSK": 27693,
        "MINUSINSK": 11310,
        "NORILSK": 11311,
        "BERDSK": 11314,
        "GORNO_ALTAYSK": 11319,
        "SEVEROBAYKALSK": 11327,
        "NIZHNEVARTOVSK": 1091,
        "NAZRAN": 1092,
        "MAYKOP": 1093,
        "KYZYL": 11333,
        "ELISTA": 1094,
        "ABAKAN": 1095,
        "CHERKESSK": 1104,
        "GROZNIY": 1106,
        "ANAPA": 1107,
        "KOLPASHEVO": 11349,
        "SEVERSK": 11351,
        "STREZGEVOY": 11352,
        "DZHIZAK": 21606,
        "BELOGORSK": 11374,
        "FRYAZINO": 21619,
        "REUTOV": 21621,
        "IVANTEEVKA": 21623,
        "ZCHERBINKA": 21624,
        "KUBINKA": 21625,
        "SVOBODNIY": 11387,
        "DEDOVSK": 21627,
        "LYTKARINO": 21630,
        "TYNDA": 11391,
        "BIROBIDGAN": 11393,
        "LOSINO_PETROVSKY": 21635,
        "LOBNJA": 21641,
        "ELEKTROUGLI": 21642,
        "ARSENEV": 11405,
        "HOTKOVO": 21645,
        "ARTEM": 11406,
        "GOLICYNO": 21646,
        "KRASNOZNAMENSK": 21647,
        "DALNEGORSK": 11411,
        "BEKABAD": 101524,
        "STARAJA_KUPAVNA": 21656,
        "SPASSK_DALNIY": 11425,
        "USSURIYSK": 11426,
        "NERUNGRI": 11437,
        "ANGREN": 101550,
        "AMURSK": 11451,
        "KOMSOMOLSK_AT_AMUR": 11453,
        "EVPATORIA": 11463,
        "KERCH": 11464,
        "FEODOSIA": 11469,
        "YALTA": 11470,
        "ALUSHTA": 11471,
        "SUDAK": 11472,
        "VILNIUS": 11475,
        "POLEVSKOJ": 21726,
        "DZERZHINSKY": 21735,
        "INGOLSTADT": 101738,
        "SHAHRISABZ": 101761,
        "MOLODCHENO": 26001,
        "SALIHORSK": 26003,
        "RISHON_LE_ZION": 116114,
        "SVETLOGORSK": 26004,
        "SLONIM": 26005,
        "KLIMAVICHY": 26006,
        "MOSTI": 26007,
        "RECHITSA": 26008,
        "ASIPOVICHY": 26012,
        "KRICHEV": 26015,
        "SHKLOV": 26016,
        "SMARHON": 26017,
        "DZERZHINSK_MINSK_DISTRICT": 26018,
        "BIKHOV": 26021,
        "KRUPKI": 26022,
        "ROGACHEV": 26027,
        "ZHODZINA": 26034,
        "KOLPINO": 26081,
        "RUZAEVKA": 20010,
        "VYATSKIE_POLYANI": 20020,
        "VYKSA": 20040,
        "KSTOVO": 20044,
        "MARKS": 20063,
        "NOVOULYANOVSK": 20070,
        "SHUMERLYA": 20078,
        "ZELENOGORSK": 20088,
        "UST_KUT": 20097,
        "BARABINSK": 20098,
        "ISKITIM": 20100,
        "KARASUK": 20101,
        "NOVODVINSK": 20117,
        "ZELENOGRADSK": 20139,
        "PIONERSKY": 20144,
        "OLENEGORSK": 20155,
        "NEVELSK": 20160,
        "AHTUBINSK": 20167,
        "MAGAS": 20181,
        "ALEKSEEVKA": 20192,
        "SHEBEKINO": 20196,
        "REVDA": 20224,
        "KACHKANAR": 20234,
        "OKTYABRSKY": 20235,
        "BEREZNIKI": 20237,
        "CHAIKOVSKY": 20243,
        "LYSVA": 20244,
        "BELORETSK": 20259,
        "KALINOVICHI": 102235
    },
    "DISTRICT": {
        "PANFILOV_DISTRICT": 116777,
        "ILE_DISTRICT": 116779,
        "ZHAMBYL_DISTRICT": 116778,
        "TALGAR_DISTRICT": 116781,
        "KARASAY_DISTRICT": 116780,
        "AMURSKY_DISTRICT": 100398,
        "ENBEKSHIKAZAKH_DISTRICT": 116782,
        "BAKHCHISARAY_DISTRICT": 24696,
        "LENINSKY_DISTRICT": 98591,
        "KAZYGURT_DISTRICT": 116939,
        "ORDABASY_DISTRICT": 116941,
        "MAKTAARAL_DISTRICT": 116940,
        "SAYRAM_DISTRICT": 116943,
        "SARYAGASH_DISTRICT": 116944,
        "TULKIBAS_DISTRICT": 116947,
        "TOLE_BI_DISTRICT": 116946,
        "SHARDARA_DISTRICT": 116948,
        "MUNAILY_DISTRICT": 116954,
        "KORDAY_DISTRICT": 116960,
        "VOSKRESENSKY_DISTRICT": 98581,
        "VOLOKOLAMSKY_DISTRICT": 98580,
        "DMITROVSKY_URBAN_OKRUG": 98582,
        "ZARAYSK_URBAN_OKRUG": 98585,
        "YEGORYEVSK_URBAN_OKRUG": 98584,
        "KASHIRA_URBAN_OKRUG": 98587,
        "ISTRA_URBAN_OKRUG": 98586,
        "KLIN_URBAN_OKRUG": 98588,
        "KRASNOGORSK_URBAN_OKRUG": 98590,
        "LUKHOVITSY_URBAN_OKRUG": 98593,
        "MOZHAJSKIJ_URBAN_OKRUG": 98595,
        "LYUBERTSY_URBAN_OKRUG": 98594,
        "NARO_FOMINSKY_URBAN_OKRUG": 98597,
        "MYTISHCHI_URBAN_OKRUG": 98596,
        "ODINTSOVSKY_DISTRICT": 98599,
        "BOGORODSKIJ_URBAN_OKRUG": 98598,
        "PAVLOVSKY_POSAD_URBAN_OKRUG": 98602,
        "RAMENSKY_DISTRICT": 98605,
        "PUSHKINSKY_DISTRICT": 98604,
        "STUPINO_URBAN_OKRUG": 98607,
        "RUZSKY_URBAN_OKRUG": 98606,
        "SERGIYEVO_POSADSKY_DISTRICT": 98608,
        "SOLNECHNOGORSKY_DISTRICT": 98611,
        "SHATURA_URBAN_OKRUG": 98615,
        "CHEKHOV_URBAN_OKRUG": 98614,
        "SHCHELKOVSKY_DISTRICT": 98617,
        "VSEVOLOZHSK_DISTRICT": 98621,
        "VOLKHOV_DISTRICT": 98620,
        "GATCHINA_DISTRICT": 98623,
        "VYBORG_DISTRICT": 98622,
        "KIRISHSKY_DISTRICT": 98625,
        "KINGISEPP_DISTRICT": 98624,
        "KIROV_DISTRICT": 98626,
        "LUZHSKY_DISTRICT": 98629,
        "CHAYKOVSKY_DISTRICT": 110916,
        "PRIOZERSK_DISTRICT": 98631,
        "PODPOROZHSKY_DISTRICT": 98630,
        "TIKHVIN_DISTRICT": 98633,
        "SLANTSY_DISTRICT": 98632,
        "TOSNENSKY_DISTRICT": 98634,
        "SORTAVALSKY_DISTRICT": 110934,
        "DENAU_DISTRICT": 196979,
        "ALEKSEEVSKII_DISTRICT": 98697,
        "YAKOVLEVSKIJ_DISTRICT": 98717,
        "SHEBEKINSKY_DISTRICT": 98716,
        "ALEXANDROVSKY_DISTRICT": 98745,
        "KIRZHACHSKY_DISTRICT": 98750,
        "PETUSHINSKY_DISTRICT": 98755,
        "ROSSOSHANSKY_DISTRICT": 98787,
        "MALOYAROSLAVETSKY_DISTRICT": 98826,
        "GAGARINSKY_DISTRICT": 98959,
        "VYAZEMSKY_DISTRICT": 98958,
        "YARTSEVSKY_DISTRICT": 98981,
        "BOLOGOVSKY_DISTRICT": 99008,
        "KONAKOVSKY_DISTRICT": 99019,
        "OSTASHKOVSKY_DISTRICT": 99028,
        "UDOMELSKY_DISTRICT": 99039,
        "BOGORODITSKY_DISTRICT": 99044,
        "MYSHKINSKY_DISTRICT": 99070,
        "UGLICHESKY_DISTRICT": 99079,
        "TUTAEVSKY_DISTRICT": 99078,
        "VELIKOUSTYUGSKY_DISTRICT": 99105,
        "XOJAYLI_DISTRICT": 193330,
        "VALDAI_DISTRICT": 99147,
        "BOROVICHSKY_DISTRICT": 99146,
        "OLD_DISTRICT": 99161,
        "KONDOPOGA_DISTRICT": 99193,
        "SEGEZHA_DISTRICT": 99203,
        "PECHORA_DISTRICT": 99210,
        "AKHTUBINSK_DISTRICT": 99221,
        "BELORECHENSK_DISTRICT": 99269,
        "KAVKAZSKY_DISTRICT": 99275,
        "EISK_DISTRICT": 99274,
        "KRIMSKY_DISTRICT": 99281,
        "SLAVYANSKY_DISTRICT": 99293,
        "TIMASHYOVSK_DISTRICT": 99297,
        "TUAPSE_DISTRICT": 99299,
        "TIKHORETSKY_DISTRICT": 99298,
        "BELAYA_KALITVA_DISTRICT": 99411,
        "BUDYONNOVSK_DISTRICT": 99456,
        "OKHANGARON_DISTRICT": 189657,
        "KSTOVSKY_DISTRICT": 99555,
        "PAVLOVSKY_DISTRICT": 99560,
        "BELEBEEVSKY_DISTRICT": 99675,
        "BELORETSKY_DISTRICT": 99677,
        "ISHIMBAYSKY_DISTRICT": 99694,
        "KANDALAKSHSKY_DISTRICT": 101749,
        "MELEUZOVSKY_DISTRICT": 99703,
        "TUYMAZINSKY_DISTRICT": 99712,
        "UCHALINSKY_DISTRICT": 99714,
        "RUZAEVSKY_DISTRICT": 99737,
        "BALAKOVO_DISTRICT": 99817,
        "BALASHOV_DISTRICT": 99818,
        "MARKSOVSKY_DISTRICT": 99832,
        "ENGELSSKY_DISTRICT": 99850,
        "NERYUNGRINSKY_DISTRICT": 101987,
        "ASHINSKY_DISTRICT": 100005,
        "SATKINSKY_DISTRICT": 100020,
        "NADYMSKY_DISTRICT": 100028,
        "ZHLOBINSKY_DISTRICT": 102103,
        "KALINOVICHSKY_DISTRICT": 102104,
        "MOZIRSKY_DISTRICT": 102108,
        "ROGACHEVSKY_DISTRICT": 102113,
        "RECHITSKY_DISTRICT": 102112,
        "SVETLOGORSKY_DISTRICT": 102114,
        "VOLKOVISSKI_DISTRICT": 102118,
        "LIDSKY_DISTRICT": 102125,
        "NOVOGRUDSKY_DISTRICT": 102127,
        "MOSTOVSKY_DISTRICT": 102126,
        "SLONIMSKY_DISTRICT": 102131,
        "SMORGONSKY_DISTRICT": 102132,
        "BIKHOVSKY_DISTRICT": 102136,
        "GORETSKY_DISTRICT": 102138,
        "KLIMOVICHSKY_DISTRICT": 102141,
        "KRICHEVSKY_DISTRICT": 102144,
        "OSIPOVICHSKY_DISTRICT": 102149,
        "SHKLOVSKY_DISTRICT": 102154,
        "NIZHNEUDINSK_DISTRICT": 100109,
        "VILEYSKY_DISTRICT": 102157,
        "BORISOVSKY_DISTRICT": 102156,
        "DZERZHINSKY_DISTRICT": 102159,
        "TAYSHETSKY_DISTRICT": 100112,
        "KRUPSKY_DISTRICT": 102162,
        "MOLODECHNENSKY_DISTRICT": 102165,
        "UST_KUTSKY_DISTRICT": 100116,
        "SLUTSKY_DISTRICT": 102169,
        "SALIHORSKY_DISTRICT": 102170,
        "BARABINSK_DISTRICT": 100183,
        "KARASUKSKY_DISTRICT": 100189,
        "BARGUZIN_DISTRICT": 100244,
        "KARGASOKSKY_DISTRICT": 100278,
        "KOLPASHEVSKY_DISTRICT": 100280
    },
    "COUNTRY": {
        "UNITED_STATES": 84,
        "ARGENTINA": 93,
        "BRAZIL": 94,
        "CYPRUS": 20574,
        "CANADA": 95,
        "GERMANY": 96,
        "UNITED_KINGDOM": 102,
        "AUSTRIA": 113,
        "BELGIUM": 114,
        "BULGARIA": 115,
        "HUNGARY": 116,
        "LITHUANIA": 117,
        "NETHERLANDS": 118,
        "NORWAY": 119,
        "POLAND": 120,
        "SLOVAKIA": 121,
        "SLOVENIA": 122,
        "FINLAND": 123,
        "FRANCE": 124,
        "CZECH_REPUBLIC": 125,
        "SWITZERLAND": 126,
        "SWEDEN": 127,
        "CHINA": 134,
        "SOUTH_KOREA": 135,
        "JAPAN": 137,
        "NEW_ZEALAND": 139,
        "BELARUS": 149,
        "KAZAKHSTAN": 159,
        "AZERBAIJAN": 167,
        "ARMENIA": 168,
        "GEORGIA": 169,
        "TURKMENISTAN": 170,
        "UZBEKISTAN": 171,
        "SERBIA": 180,
        "ISRAEL": 181,
        "DENMARK": 203,
        "SPAIN": 204,
        "ITALY": 205,
        "KYRGYZSTAN": 207,
        "MOLDOVA": 208,
        "TAJIKISTAN": 209,
        "UNITED_ARAB_EMIRATES": 210,
        "AUSTRALIA": 211,
        "RUSSIA": 225,
        "GREECE": 246,
        "IVORY_COAST": 20733,
        "BAHRAIN": 10532,
        "JORDAN": 10535,
        "KUWAIT": 10537,
        "SAUDI_ARABIA": 10540,
        "CHILE": 20862,
        "DOMINICAN_REPUBLIC": 20917,
        "GUATEMALA": 20968,
        "CAMBODIA": 20975,
        "PERU": 21156,
        "COLOMBIA": 21191,
        "TAIWAN": 29385,
        "ABKHAZIA": 29386,
        "SOUTH_OSSETIA": 29387,
        "URUGUAY": 21289,
        "TURKEY": 983,
        "INDIA": 994,
        "THAILAND": 995,
        "QATAR": 21486,
        "EGYPT": 1056,
        "YEMEN": 21551,
        "OMAN": 21586,
        "MONTENEGRO": 21610,
        "PORTUGAL": 10074,
        "ROMANIA": 10077,
        "CROATIA": 10083,
        "VIETNAM": 10093,
        "INDONESIA": 10095,
        "MALAYSIA": 10097,
        "SINGAPORE": 10105,
        "PHILIPPINES": 10108
    },
    "VILLAGE": {
        "BELOYARSKIY": 20579,
        "VOZNYESYENJE": 22829,
        "UST_BARGUZIN": 21161,
        "BELOOZERSKIJ": 21632,
        "KARGASOK": 21675,
        "NAKHABINO": 21745
    },
    "CONTINENT": {
        "EUROPE": 111,
        "AUSTRALIA_AND_OCEANIA": 138,
        "ASIA": 183,
        "AFRICA": 241,
        "NORTH_AMERICA": 10002,
        "SOUTH_AMERICA": 10003
    },
    "REGION": {
        "EAST_OF_ENGLAND": 104584,
        "CIS_EXCEPT_RUSSIA": 166
    },
    "CITY_DISTRICT": {
        "KOLPINSKY_DISTRICT": 20293,
        "PUSHKINSKY_DISTRICT": 20297
    }
} as const;