import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './time.css';

export interface IconTypeTimeProps {
    type?: 'time';
}

export const withTypeTime = withBemMod<IconTypeTimeProps>(cnIcon(), {type: 'time'});
