import React from 'react';
import b_ from 'b_';

import {Button, TooltipStateful} from 'client/common/components/lego';
import {openLink} from 'client/common/utils/window';
import {tld} from 'client/common/utils/tld';
import {Lang} from 'client/common/types';
import {useUser} from 'client/common/contexts/user';

import ru from './ru.png';
import en from './en.png';
// import tr from './tr.png';

import './lang-switcher.css';

const b = b_.with('lang-switcher');

interface LangSwitcherProps {
    lang: Lang;
    className?: string;
    size?: 's' | 'm';
    withLabel?: boolean;
}

const SWITCHABLE_LANGS = ['ru', 'en'] as const;
const LANG_OPTIONS: Record<Lang, {label: string; img: string}> = {
    ru: {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        label: 'Русский',
        img: ru
    },
    en: {
        label: 'English',
        img: en
    } /* ,
    tr: {
        label: 'Türkçe',
        img: tr
    } */
};

// Формируем retpath без параметра lang, который перебивает язык, выбранный пользователем
function getRetpath() {
    const url = new URL(location.pathname, location.origin);

    url.searchParams.delete('lang');

    return url.href;
}

function LangSelect({lang, secretkey}: LangSwitcherProps & {secretkey: string}) {
    const switchLang = (selectedLang: Lang) => {
        if (lang === selectedLang || !secretkey) {
            return;
        }

        const retpath = encodeURIComponent(getRetpath());

        openLink(
            `https://yandex.${tld}/portal/set/lang/?intl=${selectedLang}&sk=${secretkey}&retpath=${retpath}`
        );
    };

    return (
        <ul className={b('select')}>
            {SWITCHABLE_LANGS.map(currentLang => {
                const url = new URL(window.location.href);

                url.searchParams.set('lang', currentLang);

                return (
                    <li
                        className={b('item')}
                        key={currentLang}
                        onClick={() => switchLang(currentLang)}
                    >
                        <a className={b('lang')} href={url.href} onClick={e => e.preventDefault()}>
                            <>
                                <img className={b('flag')} src={LANG_OPTIONS[currentLang].img} />
                                <span className={b('flag-label')}>
                                    {LANG_OPTIONS[currentLang].label}
                                </span>
                            </>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}

export default function LangSwitcher(props: LangSwitcherProps) {
    const {lang, className = '', size = 's', withLabel = false} = props;
    const user = useUser();

    return (
        <>
            <TooltipStateful
                content={<LangSelect lang={lang} secretkey={user?.secretkey ?? ''} />}
                className={b('tooltip')}
                direction={['bottom-end', 'bottom-start']}
                mainOffset={8}
                size='s'
                trigger='click'
                view='default'
                zIndex={1001}
                keepMounted={false}
            >
                <Button className={`${b({size})} ${className}`} size={size}>
                    {withLabel ? (
                        <>
                            <img className={b('flag')} src={LANG_OPTIONS[lang].img} />
                            <span className={b('flag-label')}>{LANG_OPTIONS[lang].label}</span>
                        </>
                    ) : (
                        <img className={b('flag')} src={LANG_OPTIONS[lang].img} />
                    )}
                </Button>
            </TooltipStateful>
        </>
    );
}
