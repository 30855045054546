export class RequestError extends Error {
    name: 'RequestError';
    constructor(public statusCode: number, public response?: any) {
        super();
        this.name = 'RequestError';
        this.statusCode = statusCode;
    }
}

export class EmptyUserBillingInfoError extends Error {
    name: 'EmptyUserBillingInfoError';
    constructor(public statusCode: number, public response?: any) {
        super();
        this.name = 'EmptyUserBillingInfoError';
    }
}

export type AppError = RequestError | Error;

export function isRequestError(error: AppError): error is RequestError {
    return error instanceof RequestError;
}
