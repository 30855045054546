import {useCallback, useMemo} from 'react';
import {UseQueryResult, useMutation, useQuery, useQueryClient} from 'react-query';

import {Experiments, UserProfile, GeoCountry, Currency, UserFeatures} from 'client/common/types';
import {isYandexTeamDomain} from 'client/common/utils/yandex-team';

import {
    getExperiments,
    getUserProfile,
    updateUserProfile,
    setUserCurrency,
    getUserAvailableCountries,
    getUserFeatures,
    editUserFeatures,
    getUserAvailableCurrencies
} from 'client/common/utils/api-client';
import {useUser} from 'client/common/contexts/user';
import {usePagesContext} from 'client/common/contexts/pages';

const USER_EXPERIMENTS_KEY = 'user-experiments';
const USER_PROFILE_KEY = 'user-profile';
const USER_AVAILABLE_COUNTRIES = 'user-available-countries';
const USER_FEATURES_KEY = 'user-features';
const USER_AVAILABLE_CURRENCIES = 'user-available-currencies';

export function useUserEmail(): string | undefined {
    const user = useUser();

    return user?.info?.email;
}

export function useUserProfile() {
    return useQuery(USER_PROFILE_KEY, async () => {
        const {data} = await getUserProfile();

        return data.profile;
    });
}

export function useUpdateUserProfile() {
    const queryClient = useQueryClient();
    const {mutateAsync} = useMutation(updateUserProfile, {
        onSuccess: async () => {
            await queryClient.refetchQueries(USER_PROFILE_KEY);
        }
    });

    return useCallback(
        async (profile: UserProfile) => {
            return mutateAsync(profile);
        },
        [mutateAsync]
    );
}

export function useIsAuthorized(): boolean {
    const user = useUser();

    return Boolean(user?.info?.uid);
}

export function useIsSuperuser(): boolean {
    const user = useUser();

    return user?.info?.isSuperuser ?? false;
}

export function useIsSuperreader(): boolean {
    const user = useUser();

    return user?.info?.isSuperreader ?? false;
}

export function useIsSuper(): boolean {
    const isSuperuser = useIsSuperuser();
    const isSuperreader = useIsSuperreader();

    return isSuperuser || isSuperreader;
}

export function useIsCreativeAssessor(): boolean {
    const user = useUser();

    return user?.info?.isCreativeAssessor ?? false;
}

export function useIsSupport(): boolean {
    const user = useUser();

    return user?.info?.isSupport ?? false;
}

export function useIsYandexPublisher(): boolean {
    const user = useUser();

    return user?.info?.isYandexPublisher ?? false;
}

export function useIsDeveloper(): boolean {
    const user = useUser();

    return user?.info?.isDeveloper ?? false;
}

export function useUserCurrency(): Currency | undefined {
    const user = useUser();

    return user?.info?.settings.currency;
}

export function useUserSelectedOrgId(): number | undefined {
    const user = useUser();

    return isYandexTeamDomain ? user?.info?.settings.selected_org_id : undefined;
}

export function useIsAvailableUserCurrency(): boolean {
    const userCurrency = useUserCurrency();
    const availableCurrencies = useUserAvailableCurrencies().data ?? [];

    return userCurrency ? availableCurrencies.includes(userCurrency) : false;
}

export function useExperiments(): UseQueryResult<Experiments> {
    return useQuery(USER_EXPERIMENTS_KEY, async () => {
        const {data} = await getExperiments();

        return data;
    });
}

export function useSetUserCurrency() {
    const {mutateAsync} = useMutation(setUserCurrency);

    return useCallback(
        async ({currency, countryId}: {currency: Currency; countryId: GeoCountry}) => {
            return mutateAsync({currency, countryId});
        },
        [mutateAsync]
    );
}

export function useUserCountryId(): number | undefined {
    const user = useUser();

    return user?.info?.settings.country_id ?? undefined;
}

export function useIsLauncherRole() {
    const isSuperuser = useIsSuperuser();
    const isCreativeAssessor = useIsCreativeAssessor();

    return isSuperuser || isCreativeAssessor;
}

export function useUserAvailableCountries(): UseQueryResult<Record<GeoCountry, Currency[]>> {
    return useQuery(USER_AVAILABLE_COUNTRIES, async () => {
        const {data} = await getUserAvailableCountries();

        return data?.countries;
    });
}

export function useUserAvailableCurrencies(): UseQueryResult<Currency[]> {
    return useQuery(USER_AVAILABLE_CURRENCIES, async () => {
        const {data} = await getUserAvailableCurrencies();

        return data?.currencies;
    });
}

export function useUserFeatures(): UseQueryResult<UserFeatures> {
    return useQuery(USER_FEATURES_KEY, async () => {
        const {data} = await getUserFeatures();

        return data;
    });
}

export function useEditUserFeatures() {
    const {mutateAsync} = useMutation(editUserFeatures);

    return useCallback(
        async ({logins, features}: {logins: any; features: any[]}) => {
            return mutateAsync({logins, features});
        },
        [mutateAsync]
    );
}

export function useIsNonSuperInScreenouts() {
    const isSuperuser = useIsSuperuser();
    const {isScreening} = usePagesContext();

    return isScreening && !isSuperuser;
}

export interface UserRoles {
    isAuthorized: boolean;
    isSuperuser: boolean;
    isSuperreader: boolean;
    isSuper: boolean;
    isCreativeAssessor: boolean;
    isSupport: boolean;
    isYandexPublisher: boolean;
    isLauncherRole: boolean;
    isDeveloper: boolean;
}

export function UserRoles(params?: Partial<UserRoles>): UserRoles {
    const defaultValues: UserRoles = {
        isAuthorized: false,
        isCreativeAssessor: false,
        isDeveloper: false,
        isLauncherRole: false,
        isSuper: false,
        isSuperreader: false,
        isSuperuser: false,
        isSupport: false,
        isYandexPublisher: false
    };

    return Object.assign(defaultValues, params);
}

export function useUserRoles(): UserRoles {
    const isAuthorized = useIsAuthorized();
    const isCreativeAssessor = useIsCreativeAssessor();
    const isLauncherRole = useIsLauncherRole();
    const isSuper = useIsSuper();
    const isDeveloper = useIsDeveloper();
    const isSuperreader = useIsSuperreader();
    const isSuperuser = useIsSuperuser();
    const isSupport = useIsSupport();
    const isYandexPublisher = useIsYandexPublisher();

    return useMemo(() => {
        return {
            isAuthorized,
            isCreativeAssessor,
            isLauncherRole,
            isSuper,
            isSuperreader,
            isSuperuser,
            isSupport,
            isYandexPublisher,
            isDeveloper
        };
    }, [
        isAuthorized,
        isCreativeAssessor,
        isLauncherRole,
        isSuper,
        isSuperreader,
        isSuperuser,
        isSupport,
        isYandexPublisher,
        isDeveloper
    ]);
}
