import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './cross-light.css';

export interface IconTypeCrossLightProps {
    type?: 'cross-light';
}

export const withTypeCrossLight = withBemMod<IconTypeCrossLightProps>(cnIcon(), {
    type: 'cross-light'
});
