import React from 'react';
import b_ from 'b_';

import {Modal as LegoModal, Icon, Button} from 'client/common/components/lego';

import {useDevice} from 'client/common/hooks/device';
import {useLastModal, hideModal, ModalLevel, MODAL_LEVELS} from 'client/common/hooks/modal';

import {Device} from 'client/common/utils/device-width';

import './modal.css';

const b = b_.with('modal');

interface ModalLevelProps {
    level: ModalLevel;
}

function ModalLevelComponent({level}: ModalLevelProps) {
    const device = useDevice();

    const lastModal = useLastModal(level);

    if (!lastModal) {
        return null;
    }

    const {id, children, options} = lastModal;

    const hideByOutsideClick = options?.hideByOutsideClick ?? true;
    const wide = options?.wide || device === Device.Mobile;
    const centered = options?.centered;
    const onBeforeClose = options?.onBeforeClose;

    const className = `${b({wide, centered})} ${options?.className ?? ''}`;

    const onClose = (e: Pick<Event, 'target'>) => {
        if (e.target instanceof HTMLElement && e.target.closest('.notification')) return;

        hideModal(id, onBeforeClose);
    };

    return (
        <LegoModal
            className={className}
            hasAnimation={!wide}
            keepMounted={false}
            key={id}
            onClose={hideByOutsideClick ? onClose : undefined}
            theme='normal'
            visible
            zIndex={MODAL_LEVELS[level]}
        >
            {options?.withCross && (
                <div className={b('button-wrapper')}>
                    <Button
                        size='icon'
                        view='clear'
                        icon={(iconClassName: string) => (
                            <Icon className={iconClassName} type='cross' />
                        )}
                        className={b('button', {type: 'close'})}
                        onClick={onClose}
                    />
                </div>
            )}
            <div className={b('children')}>{children}</div>
        </LegoModal>
    );
}

export default function Modal() {
    return (
        <>
            <ModalLevelComponent level='Popup' />
            <ModalLevelComponent level='PopupOverPopup' />
        </>
    );
}
