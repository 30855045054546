export function isWithinDates({from = new Date(), to = new Date()}) {
    const now = new Date();

    return now >= from && now < to;
}

export function parseISOString(data: string) {
    const date = new Date(data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return {
        day,
        month,
        year
    };
}
