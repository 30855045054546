import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './blue-text.css';

export interface ButtonViewBlueTextProps {
    view?: 'blue-text';
}

export const withViewBlueText = withBemMod<ButtonViewBlueTextProps>(cnButton(), {view: 'blue-text'});
