import {_doRequest} from '../base';

import {
    AddUserToAccessListParams,
    AddUserToAccessListResponse,
    GetAccessListParams,
    GetAccessListResponse,
    RemoveUserFromAccessListParams,
    RemoveUserFromAccessListResponse
} from '../types';

export const getAccessList = async (
    params: GetAccessListParams
): Promise<GetAccessListResponse> => {
    const {slug} = params;

    const requestParams = {
        method: 'GET',
        url: `survey/${slug}/access`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const addUserToAccessList = async (
    params: AddUserToAccessListParams
): Promise<AddUserToAccessListResponse> => {
    const {slug, login} = params;

    const requestParams = {
        method: 'PUT',
        url: `survey/${slug}/access/${login}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const removeUserFromAccessList = async (
    params: RemoveUserFromAccessListParams
): Promise<RemoveUserFromAccessListResponse> => {
    const {slug, login} = params;

    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/access/${login}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};
