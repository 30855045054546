import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './bill.css';

export interface IconTypeBillProps {
    type?: 'bill';
}

export const withTypeBill = withBemMod<IconTypeBillProps>(cnIcon(), {type: 'bill'});
