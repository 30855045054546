import {withBemMod} from '@bem-react/core';

import {cnMenu} from '@yandex-lego/components/Menu';

import './dl-clear.css';

export interface MenuViewDLClearProps {
    view?: 'dl-clear';
}

export const withViewDLClear = withBemMod<MenuViewDLClearProps>(cnMenu(), {view: 'dl-clear'});
