import React, {Fragment, useEffect, useState} from 'react';
import b_ from 'b_';

import './card-payment-form.css';

const b = b_.with('card-payment-form');
const mobileVersionParam = '&template_tag=mobile/form';

const Iframe = ({src}: {src: string}) => {
    return (
        <Fragment>
            <iframe src={src} />
        </Fragment>
    );
};

interface Props {
    source: string;
}

export default function PaymentPage({source}: Readonly<Props>) {
    const [src, setSrc] = useState(source);

    useEffect(() => {
        const {width} = document.body.getBoundingClientRect();

        if (width < 800) {
            setSrc(`${source}${mobileVersionParam}`);
        } else {
            setSrc(source);
        }
    }, [source]);

    return (
        <div className={b('payment-page')}>
            <Iframe src={src} />
        </div>
    );
}
