import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './cross.css';

export interface IconTypeCrossProps {
    type?: 'cross';
}

export const withTypeCross = withBemMod<IconTypeCrossProps>(cnIcon(), {type: 'cross'});
