import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './finger-up-white.css';

export interface IconTypeFingerUpWhiteProps {
    type?: 'finger-up-white';
}

export const withTypeFingerUpWhite = withBemMod<IconTypeFingerUpWhiteProps>(cnIcon(), {type: 'finger-up-white'});
