import React, {useCallback, useRef} from 'react';

import b_ from 'b_';

import {Button, Icon} from 'client/common/components/lego';

import {sendGoal} from 'client/common/utils/metrika';

import {SurveysGoal} from 'client/common/types';

import {useWidgetContext, WidgetContextProvider} from './context';

const b = b_.with('header2');

const WidgetActivationButton = () => {
    const buttonRef = useRef(null);
    const { widget } = useWidgetContext();
 
    const handleClick = useCallback(() => {
        sendGoal(SurveysGoal.ClickChat);
        widget.show();
    }, [widget]);

    return (
        <Button
            innerRef={buttonRef.current}
            className={b('button', {type: 'help', background: 'active'})}
            iconLeft={(className: string) => {
                return <Icon className={className} type='dialog' />;
            }}
            size='s'
            onClick={handleClick}
        />
    ); 
};

export const WidgetButton = () => {
    return (
        <WidgetContextProvider>
            <WidgetActivationButton/>
        </WidgetContextProvider>
    );
};
