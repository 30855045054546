import {_doRequest} from '../base';

export const sendTemplateEmail = async ({
    slugs,
    template,
    params
}: {
    slugs: string[];
    template: number;
    params: string[];
}): Promise<any> => {
    const requestParams = {
        method: 'POST',
        url: 'sendEmail',
        data: {
            slugs,
            template,
            params
        }
    };

    return _doRequest(requestParams);
};
