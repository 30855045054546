import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './plus-filled.css';

export interface IconTypePlusFilledProps {
    type?: 'plus-filled';
}

export const withTypePlusFilled = withBemMod<IconTypePlusFilledProps>(cnIcon(), {
    type: 'plus-filled'
});
