import {withBemMod} from '@bem-react/core';

import {cnTextinput} from '@yandex-lego/components/Textinput';

import './borderless.css';

export interface TextinputViewBorderlessProps {
    view?: 'borderless';
}

export const withViewBorderless = withBemMod<TextinputViewBorderlessProps>(cnTextinput(), {
    view: 'borderless'
});
