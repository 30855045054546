import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './video-circle.css';

export interface IconTypeVideoCircleProps {
    type?: 'video-circle';
}

export const withTypeVideoCircle = withBemMod<IconTypeVideoCircleProps>(cnIcon(), {
    type: 'video-circle'
});
