import I18N from 'assets/i18n/brandlift-project';
import {GeoValue} from 'client/common/types';

const i18n = I18N.common.audienceSettings;

export const POPULATION_SELECT_OPTIONS = [
    {
        value: 'allRussia',
        content: i18n.groups.allRussia
    },
    {
        value: '1M+',
        content: i18n.groups.millions
    },
    {
        value: '500k+',
        content: i18n.groups.halfMillions
    }
];

export const GEO_VALUE_PRIORITY = [
    GeoValue.Russia,
    GeoValue.Kazakhstan,
    GeoValue.Belarus,
    GeoValue.Moscow,
    GeoValue.SaintPetersburg,
    GeoValue.AkmolinskRegion,
    GeoValue.MinskRegion,
    GeoValue.Uzbekistan,
    GeoValue.Tashkent
];

export const CREATIVE_ALLOWED_GEO_VALUES = [
    GeoValue.Russia,
    GeoValue.Moscow,
    GeoValue.SaintPetersburg
];
