import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './overview.css';

export interface IconTypeOverviewProps {
    type?: 'overview';
}

export const withTypeOverview = withBemMod<IconTypeOverviewProps>(cnIcon(), {type: 'overview'});
