import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './lock.css';

export interface IconTypeLockProps {
    type?: 'lock';
}

export const withTypeLock = withBemMod<IconTypeLockProps>(cnIcon(), {type: 'lock'});
