import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './share-filled.css';

export interface IconTypeShareFilledProps {
    type?: 'share-filled';
}

export const withTypeShareFilled = withBemMod<IconTypeShareFilledProps>(cnIcon(), {type: 'share-filled'});
