import React from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import Avito from './avito.png';
import Avito2x from './avito_2x.png';
import Beeline from './beeline.png';
import Beeline2x from './beeline_2x.png';
import Fsk from './fsk.png';
import Fsk2x from './fsk_2x.png';
import Hoff from './hoff.png';
import Hoff2x from './hoff_2x.png';
import Reckitt from './reckitt.png';
import Reckitt2x from './reckitt_2x.png';
import Rolf from './rolf.png';
import Rolf2x from './rolf_2x.png';
import Ugoria from './ugoria.png';
import Ugoria2x from './ugoria_2x.png';
import Vtb from './vtb.png';
import Vtb2x from './vtb_2x.png';

import './our-clients.css';

const b = b_.with('our-clients');

const i18n = I18N.ourClients;

export default function OurClients() {
    return (
        <div className={b()}>
            <div className={b('wrapper')}>
                <h1 className={b('header')}>{i18n.title}</h1>
                <div className={b('list')}>
                    <img srcSet={`${Avito2x} 2x`} src={Avito} />
                    <img srcSet={`${Vtb2x} 2x`} src={Vtb} />
                    <img srcSet={`${Beeline2x} 2x`} src={Beeline} />
                    <img srcSet={`${Rolf2x} 2x`} src={Rolf} />
                    <img srcSet={`${Reckitt2x} 2x`} src={Reckitt} />
                    <img srcSet={`${Hoff2x} 2x`} src={Hoff} />
                    <img srcSet={`${Ugoria2x} 2x`} src={Ugoria} />
                    <img srcSet={`${Fsk2x} 2x`} src={Fsk} />
                </div>
            </div>
        </div>
    );
}
