import React, {Component, ErrorInfo} from 'react';
import b_ from 'b_';
import {logError} from '@yandex-int/pythia-libs/lib/rum';

import I18N from 'assets/i18n/brandlift-project';

import {AppError, isRequestError} from 'client/common/types';
import {Button} from 'client/common/components/lego';
import {reload} from 'client/common/utils/window';

import {ErrorProps, ErrorBoundaryProps, ErrorBoundaryState} from './types';

import './error.css';

const i18n = I18N.common.error;
const b = b_.with('error');

export function Error(props: ErrorProps) {
    const {details, message, position, withReloadButton = false} = props;

    return (
        <div className={b({position})}>
            <span className={b('message')} title={details}>
                {message}
            </span>
            {withReloadButton && (
                <Button className={b('reload-button')} onClick={reload} size='m' view='clear'>
                    {i18n.reload}
                </Button>
            )}
        </div>
    );
}

function getErrorMessage(error: AppError): string {
    if (isRequestError(error)) {
        switch (error.statusCode) {
            case 403:
                return i18n.noAccess;

            case 404:
                return i18n.notFound;

            default:
                return i18n.error;
        }
    }

    return i18n.error;
}

function getErrorDetails(error: AppError): string {
    if (isRequestError(error)) {
        return JSON.stringify(error.response);
    }

    return error.message;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {};

    static getDerivedStateFromError(error: AppError): ErrorBoundaryState {
        return {error};
    }

    componentDidCatch(error: AppError, info: ErrorInfo) {
        console.error(error);

        logError({
            message: error.message,
            additional: {info},
            type: 'error-boundary',
            err: error
        });
    }

    render() {
        const {position, children} = this.props;
        const {error} = this.state;

        if (error) {
            const message = getErrorMessage(error);
            const details = getErrorDetails(error);

            return (
                <Error
                    details={details}
                    message={message}
                    position={position}
                    withReloadButton={!isRequestError(error)}
                />
            );
        }

        return children;
    }
}
