import {Group, Question, QuestionOption, Widget} from './revision';

export interface PageNode {
    type: 'page';
    id: Group['id'];
}

export interface QuestionNode {
    type: 'question';
    id: Question['id'];
}

export interface OptionNode {
    type: 'option';
    id: QuestionOption['id'];
    questionId: Question['id'];
}

export interface WidgetNode {
    type: 'widget';
    id: Widget['id'];
}

export type LogicNode = PageNode | QuestionNode | OptionNode | WidgetNode;

export type LogicConditionNodeType = 'questions' | 'query';

export type LogicConditionNode = [LogicConditionNodeType, number | string];

export type LogicConditionOperator =
    | 'exist'
    | 'equal'
    | 'notEqual'
    | 'gt'
    | 'lt'
    | 'includes'
    | 'any'
    | 'notIncludesAll'
    | 'notIncludesAny';

export interface LogicCondition {
    type: 'condition';
    node: LogicConditionNode;
    op: LogicConditionOperator;
    value?: number | string | number[] | string[];
}

export type LogicBlockOperator = 'and' | 'or' | 'not';

export interface LogicBlock {
    type: 'block';
    op: LogicBlockOperator;
    content: LogicElement[];
}

export type LogicElement = LogicBlock | LogicCondition;

export type LogicScheme = LogicBlock;

export function isLogicBlock(el: LogicElement): el is LogicBlock {
    return el.type === 'block';
}

export type ConnectionComponent = 'group' | 'question' | 'widget';

export type ConnectionScope = 'inner' | 'outer';

export type Connection = Pick<LogicNode, 'id'>;

export type OptionConnection = Connection & {
    questionId: Question['id'];
};

export interface Connections {
    connected: boolean;
    groups: Connection[];
    widgets: Connection[];
    options: OptionConnection[];
    questions: Connection[];
    rotations: Connection[];
}

export type LogicMode = 'simple' | 'advanced';
