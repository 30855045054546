import {compose, composeU} from '@bem-react/core';

import {
    Checkbox as CheckboxDesktop,
    withIndeterminate,
    withLinesMulti,
    withLinesOne,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Checkbox/desktop';

import {withViewDL} from './view/dl';

import './index.css';

export const Checkbox = compose(
    withIndeterminate,
    composeU(withLinesMulti, withLinesOne),
    withSizeM,
    composeU(withViewDefault, withViewDL)
)(CheckboxDesktop);
