import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './dl-default.css';

export interface ButtonViewDLDefaultProps {
    view?: 'dl-default';
}

export const withViewDLDefault = withBemMod<ButtonViewDLDefaultProps>(cnButton(), {
    view: 'dl-default'
});
