import {SurveyType} from 'client/common/types';

import {QuicksurveyContext} from 'client/common/store/reducers/context/types';

export enum SurveyState {
    Draft = 'DRAFT',
    Moderation = 'MODERATION',
    Active = 'ACTIVE',
    Paused = 'PAUSED',
    Completed = 'COMPLETED',
    Closed = 'CLOSED'
}

export interface Survey {
    slug: string;
    name: string;
    state: SurveyState;
    updatedAt: string;
    clientId?: string;
    context: QuicksurveyContext;
    isTemporary: boolean;
    boxName: SurveyType;
}

export type SurveyStore = Partial<Omit<Survey, 'name'>>;

export enum EditMode {
    Everything,
    Launched,
    Activated,
    Completed,
    ReadOnly
}
