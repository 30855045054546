import { UserRoles } from '../hooks/user';
import {BoxType} from './survey';

export enum AudienceType {
    FixedTotalCost = 'FIXED_TOTAL_COST',
    Manual = 'MANUAL',
    MediaTargeting = 'MEDIA_TARGETING',
    None = 'NONE',
    Quotas = 'QUOTAS',
    RespParams = 'RESP_PARAMS',
    RespParamsOmi = 'RESP_PARAMS_OMI',
    YaAudience = 'YA_AUDIENCE'
}

export type AudienceInterestValue = string;

export type AudienceInterestPayload = AudienceInterestValue;

export interface AudienceInterestInfo {
    amount: number;
    description: string;
    id: AudienceInterestValue;
    name: string;
    parentId?: AudienceInterestValue;
}

export type AudienceCategoryId = 'age' | 'gender' | 'geo';
export type ExtendedAudienceCategoryId =
    | 'city_size'
    | 'cohabitants_amount'
    | 'family_status'
    | 'have_car'
    | 'have_children';

export type AudienceCategoryValue = string;

export interface AudienceCategoryValues {
    age: Array<'1' | '2' | '3' | '4' | '5'>;
    gender: '0' | '1' | ['0', '1'];
    geo: Array<string>;
    city_size: ['1'] | ['2'] | ['3', '4'] | ['5'] | ['6'];
    cohabitants_amount: '1' | '2' | '3' | '4' | '5' | '6';
    family_status: '0' | '1' | '2';
    have_car: '0' | '1' | '2';
    have_children: '0' | '1';
}

export type ExtendedCategoryValues = AudienceCategoryValues[ExtendedAudienceCategoryId];

type CategoryValue<TKey extends AudienceCategoryInfo['id']> =
    TKey extends AudienceCategoryInfo['id']
        ? {
              categoryId: TKey;
              value: AudienceCategoryValues[TKey];
          }
        : never;

export type AgeCategory = CategoryValue<'age'>;
export type GenderCategory = CategoryValue<'gender'>;
export type GeoCategory = CategoryValue<'geo'>;
export type CitySizeCategory = CategoryValue<'city_size'>;
export type CohabitantsCategory = CategoryValue<'cohabitants_amount'>;
export type FamilyStatusCategory = CategoryValue<'family_status'>;
export type HaveCarCategory = CategoryValue<'have_car'>;
export type HaveChildrenCategory = CategoryValue<'have_children'>;

export const isAgeCategory = (c: AudienceCategories): c is AgeCategory => c.categoryId === 'age';
export const isGenderCategory = (c: AudienceCategories): c is GenderCategory =>
    c.categoryId === 'gender';
export const isGeoCategory = (c: AudienceCategories): c is GeoCategory => c.categoryId === 'geo';

export const isCitySizeCategory = (c: AudienceCategories): c is CitySizeCategory =>
    c.categoryId === 'city_size';
export const isCohabitantsCategory = (c: AudienceCategories): c is CohabitantsCategory =>
    c.categoryId === 'cohabitants_amount';
export const isFamilyStatusCategory = (c: AudienceCategories): c is FamilyStatusCategory =>
    c.categoryId === 'family_status';
export const isHaveCarCategory = (c: AudienceCategories): c is HaveCarCategory =>
    c.categoryId === 'have_car';
export const isHaveChildrenCategory = (c: AudienceCategories): c is HaveChildrenCategory =>
    c.categoryId === 'have_children';

export type AudienceCategories =
    // | {categoryId: AudienceCategoryId; value: AudienceCategoryValue | AudienceCategoryValue[]}
    | GeoCategory
    | AgeCategory
    | GenderCategory
    | CohabitantsCategory
    | FamilyStatusCategory
    | HaveCarCategory
    | HaveChildrenCategory
    | CitySizeCategory;

export enum AudienceCategoryViewType {
    List = 'list'
}

export const POPULATION_VALUES = ['1M+', '500k+'] as const;

export type Population = (typeof POPULATION_VALUES)[number];
export type RegionType = 'country';

export interface AudienceCategoryProperties {
    population?: Population;
    regionType?: RegionType;
    country?: GeoCountry;
}

export type AudienceCategoryPossibleValues = {
    value: string;
    label: string;
    properties: AudienceCategoryProperties;
}[];

export interface AudienceCategoryInfo {
    id: AudienceCategoryId | ExtendedAudienceCategoryId;
    label: string;
    parameters: {
        multipleChoice: boolean;
    };
    possibleValues: AudienceCategoryPossibleValues;
    viewType: AudienceCategoryViewType;
}

export type AudienceSegmentValue = string;

export type AudienceSegmentPayload = AudienceSegmentValue;

export interface AudienceSegmentInfo {
    content_type: 'yuid' | 'idfa_gaid' | 'client_id' | 'mac' | 'crm';
    cookies_matched_quantity: number;
    create_time: string;
    geo_segment_type?: 'last' | 'regular' | 'home' | 'work' | 'condition';
    id: number;
    name: string;
    owner: string;
    status:
        | 'uploaded'
        | 'is_processed'
        | 'processed'
        | 'processing_failed'
        | 'is_updated'
        | 'few_data';
    type: 'uploading' | 'dmp' | 'geo' | 'lookalike';
}

export type AudienceSize = number;

export interface AudienceRequest {
    id: string;
    size: AudienceSize;
    type: AudienceType;
    questionsLimit?: number;
    quotas: Quota[] | null;
    segments: AudienceSegmentPayload[];
    interests: AudienceInterestPayload[];
    categories: AudienceCategories[];
    description?: string;
}

/*
    всегда передаем type, чтобы не было возможности сохранить categories несовместимые с типом аудитории,
    например categories от resp_params_omi, когда у опроса стоит type=resp_params
*/
export type AudienceRequestUpdate = Pick<AudienceRequest, 'type'> &
    Partial<Pick<AudienceRequest, 'categories' | 'segments' | 'size' | 'description'>> & {
        quotas: Quota[] | null;
        interests: AudienceInterestPayload[] | null;
    };

export type QuotaType = 'RESP_PARAMS' | 'YA_AUDIENCE' | 'INTERESTS';

export interface Quota {
    id?: string;
    type: QuotaType;
    sizePerc: number;
    segments: AudienceSegmentPayload[];
    interests: AudienceInterestPayload[];
    categories: AudienceCategories[];
}

export interface SurveyAudience extends Omit<AudienceRequest, 'questionsLimit'> {
    finished: number;
}

export enum GeoValue {
    Russia = '225',
    Kazakhstan = '159',
    Belarus = '149',
    Ukraine = '187',
    Turkey = '983',
    Uzbekistan = '171',

    Moscow = '213',
    SaintPetersburg = '2',
    AkmolinskRegion = '29403',
    MinskRegion = '29630',
    Tashkent = '10335'
}

export const COUNTRIES = [
    GeoValue.Belarus,
    GeoValue.Kazakhstan,
    GeoValue.Russia,
    GeoValue.Turkey,
    GeoValue.Ukraine,
    GeoValue.Uzbekistan
] as const;

export const SUPPORTED_COUNTRIES = [
    GeoValue.Belarus,
    GeoValue.Kazakhstan,
    GeoValue.Russia,
    GeoValue.Uzbekistan
] as const;

/** Страны которые поддерживаем и на которые даем старгетиться */
export type SupportedCountries = (typeof SUPPORTED_COUNTRIES)[number];

/** Фоллбэки для случая когда снимаем выделение со страны (самый крупный регион страны) */
export const COUNTRIES_REGION_FALLBACKS: {
    [country in SupportedCountries]: GeoValue;
} = {
    [GeoValue.Russia]: GeoValue.Moscow,
    [GeoValue.Kazakhstan]: GeoValue.AkmolinskRegion,
    [GeoValue.Belarus]: GeoValue.MinskRegion,
    [GeoValue.Uzbekistan]: GeoValue.Tashkent
} as const;

export type GeoCountry = (typeof COUNTRIES)[number];

export type AudienceSettingsType =
    | Exclude<AudienceType, AudienceType.None | AudienceType.FixedTotalCost>
    | 'GEO';

export interface AudienceUpdateParams {
    type?: AudienceSettingsType;
    country?: GeoCountry;
    categories?: AudienceRequestUpdate['categories'];
    description?: AudienceRequest['description'];
}

export interface SizeDataFields {
    boxType: BoxType | undefined;
    categories: AudienceCategories[];
    targetingCountry: GeoCountry | undefined;
    userRoles: UserRoles;
    type: AudienceType;
    size: AudienceSize;
}
