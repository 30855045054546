import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './play.css';

export interface IconTypePlayProps {
    type?: 'play';
}

export const withTypePlay = withBemMod<IconTypePlayProps>(cnIcon(), {type: 'play'});
