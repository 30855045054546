import React, {useRef, ChangeEvent, ComponentProps} from 'react';

import b_ from 'b_';

import {Select} from '@yandex-lego/components/Select/desktop';

import I18N from 'assets/i18n/brandlift-project';

import {Button} from '../../button';

import {Icon} from '../../icon';

import './trigger-with-tags.css';

const b = b_.with('trigger-with-tags');

const i18n = I18N.common;

type TriggerProps = ComponentProps<typeof Select> & {
    tags: Array<{
        content: string;
        value: any;
    }>;
    allSelected: boolean;
    minSelected?: number;
    handleTagRemove: (value: any) => any[];
};

export function TriggerWithTags(props: TriggerProps) {
    const iconDirection = props['aria-expanded'] ? 'top' : 'bottom';

    const {handleTagRemove, minSelected, children, tags, allSelected, onChange, ...rest} = props;

    const controlInternalRef = useRef<HTMLInputElement>(null);

    const internalClick = (event: MouseEvent, value: any) => {
        if (controlInternalRef.current && onChange) {
            const syntheticEvent = Object.create(event);

            syntheticEvent.target = controlInternalRef.current;
            syntheticEvent.currentTarget = controlInternalRef.current;
            syntheticEvent.currentTarget.value = handleTagRemove(value);

            onChange(syntheticEvent as ChangeEvent<HTMLSelectElement>);
        }
    };

    interface OptionSimple {
        value: any;
        content: string;
    }

    const renderOptionItem = (item: OptionSimple) => {
        return (
            <span className={b('tag')} key={item.value}>
                {item.content}

                {(minSelected === undefined || minSelected < tags.length) && (
                    <Icon
                        type='cross-bold'
                        size='xs'
                        onClick={(e: MouseEvent) => {
                            e.stopPropagation();

                            internalClick(e, item.value);
                        }}
                    />
                )}
            </span>
        );
    };

    return (
        <div className={b('wrapper')} ref={controlInternalRef}>
            <Button
                {...(rest as Partial<ComponentProps<typeof Button>>)}
                checked={false}
                iconRight={(iconClassName: string) => (
                    <Icon className={iconClassName} type='arrow' direction={iconDirection} />
                )}
            >
                {!allSelected &&
                    tags.map((item: OptionSimple | {items: OptionSimple[]}) => {
                        return 'items' in item
                            ? item.items.map(itm => renderOptionItem(itm))
                            : renderOptionItem(item);
                    })}

                {allSelected && <span className={b('no-text')}>{i18n.select.labelAll}</span>}

                {!allSelected && tags.length === 0 && (
                    <span className={b('no-text')}>{children}</span>
                )}
            </Button>
        </div>
    );
}
