import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './logic.css';

export interface IconTypeLogicProps {
    type?: 'logic';
}

export const withTypeLogic = withBemMod<IconTypeLogicProps>(cnIcon(), {type: 'logic'});
