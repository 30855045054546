import {getCurrentOrg} from 'client/common/hooks/orgs';
import {createUrl} from './history';

export function openLink(link: string, newWindow = false, noOpener = true) {
    const org = getCurrentOrg();

    window.open(
        createUrl({link, extraSearchParams: {org}}),
        newWindow ? '_blank' : '_self',
        noOpener ? 'noopener,noreferrer' : undefined
    );
}

export function reload() {
    location.reload();
}
