import _every from 'lodash/every';
import _isEqual from 'lodash/isEqual';
import Mitt from 'mitt';

import {NotificationSettings} from './notification/notification';

interface Notification extends NotificationSettings {
    id: number;
}

const emitter: mitt.Emitter = new Mitt();
const DEFAULTS: Partial<NotificationSettings> = {
    type: 'info',
    timeOut: 3000,
    message: 'alert!'
};

const getNewId = (() => {
    let counter = 0;

    /* eslint no-plusplus:0 */
    return () => counter++;
})();

let list: Notification[] = [];

const manager = {
    add: (params: NotificationSettings) => {
        list.push(Object.assign({id: getNewId()}, DEFAULTS, params));
        emitter.emit('change', list);
    },

    remove: (notification: any) => {
        list = list.filter(i => notification.id !== i.id);
        emitter.emit('change', list);
    },

    removeByParams: (paramsObject: any) => {
        list = list.filter(n => {
            const isSuitable = _every(paramsObject, (val, key) => {
                // @ts-ignore
                return _isEqual(val, n[key]);
            });

            return !isSuitable;
        });
        emitter.emit('change', list);
    },

    getList: () => list,

    addChangeListener: (cb: any) => {
        emitter.on('change', cb);
    },

    removeChangeListener: (cb: any) => {
        emitter.off('change', cb);
    },

    ...Object.create(emitter)
};

export default manager;
