import {createBrowserHistory} from 'history';

import {SurveyScope} from 'client/common/types';
import cfg from 'client/common/utils/client-config';

const history = createBrowserHistory();

export default history;

export const pushToSearch = ({
    searchParams,
    key,
    value
}: {
    searchParams?: URLSearchParams;
    key: string;
    value: string | undefined;
}) => {
    if (!searchParams) {
        searchParams = new URLSearchParams(location.search);
    }

    if (value) {
        searchParams.set(key, value);
    } else {
        searchParams.delete(key);
    }

    history.push({search: searchParams.toString()});
};

export function getIntParamFromSearch(paramName: string): number | undefined {
    const param = new URLSearchParams(location.search).get(paramName) ?? undefined;

    return param ? parseInt(param, 10) : undefined;
}

export function createUrl({
    link,
    relative = false,
    surveyScope = SurveyScope.Yandex,
    extraSearchParams
}: {
    link: string;
    relative?: boolean;
    surveyScope?: SurveyScope;
    extraSearchParams?: Record<string, string | number | undefined>;
}): string {
    const searchParams = new URLSearchParams(location.search);
    let baseUrl = location.origin;

    if (surveyScope === SurveyScope.YandexNet || surveyScope === SurveyScope.YandexTeam) {
        baseUrl = cfg.teamEndpoint;
    }

    const url = new URL(link, baseUrl);

    for (const [key, value] of searchParams.entries()) {
        url.searchParams.append(key, value);
    }

    if (extraSearchParams) {
        Object.entries(extraSearchParams).forEach(([key, value]) => {
            if (value) {
                url.searchParams.set(key, String(value));
            } else {
                url.searchParams.delete(key);
            }
        });
    }

    return relative ? `${url.pathname}${url.search}` : url.href;
}
