import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './filter-funnel.css';

export interface IconTypeFilterFunnelProps {
    type?: 'filter-funnel';
}

export const withTypeFilterFunnel = withBemMod<IconTypeFilterFunnelProps>(cnIcon(), {type: 'filter-funnel'});
