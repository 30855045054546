import {_doRequest} from '../../base';
import {
    AddOptionParams,
    AddOptionResponse,
    RemoveOptionParams,
    UpdateOptionParams,
    UpdateOptionsAsBulkParams
} from '../../types';

export const addOption = async (params: AddOptionParams): Promise<AddOptionResponse> => {
    const {slug, revisionId, questionId} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/option`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const removeOption = async ({
    slug,
    revisionId,
    questionId,
    optionId
}: RemoveOptionParams) => {
    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/option/${optionId}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const updateOption = async ({
    slug,
    revisionId,
    questionId,
    optionId,
    data
}: UpdateOptionParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/option/${optionId}`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export function addQuestionOptionsAsBulk({
    slug,
    revisionId,
    questionId,
    data
}: UpdateOptionsAsBulkParams) {
    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/options`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
}
