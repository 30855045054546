import {compose, composeU} from '@bem-react/core';

import {
    Tumbler as TumblerDesktop,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Tumbler/desktop';

import {withViewDL} from './view/dl';

import './index.css';

export const Tumbler = compose(withSizeM, composeU(withViewDefault, withViewDL))(TumblerDesktop);
