import {withBemMod} from '@bem-react/core';

import {cnTextinput} from '@yandex-lego/components/Textinput';

import './m-bold.css';

export interface TextinputSizeMBoldProps {
    size?: 'm-bold';
}

export const withSizeMBold = withBemMod<TextinputSizeMBoldProps>(cnTextinput(), {size: 'm-bold'});
