import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './copy.css';

export interface IconTypeCopyProps {
    type?: 'copy';
}

export const withTypeCopy = withBemMod<IconTypeCopyProps>(cnIcon(), {type: 'copy'});
