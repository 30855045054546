import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './dl-light.css';

export interface ButtonViewDLLightProps {
    view?: 'dl-light';
}

export const withViewDLLight = withBemMod<ButtonViewDLLightProps>(cnButton(), {view: 'dl-light'});
