import {withBemMod} from '@bem-react/core';

import {cnButton} from '@yandex-lego/components/Button';

import './wide.css';

export interface ButtonSizeWideProps {
    size?: 'wide';
}

export const withSizeWide = withBemMod<ButtonSizeWideProps>(cnButton(), {size: 'wide'});
