import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './page.css';

export interface IconTypePageProps {
    type?: 'page';
}

export const withTypePage = withBemMod<IconTypePageProps>(cnIcon(), {type: 'page'});
