import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';

import {DIRECT_GEO_VALUES} from 'assets/direct/geo_values';

import {
    AudienceCategories,
    ExtendedAudienceCategoryId,
    GeoValue,
    isCitySizeCategory,
    isGenderCategory,
    isAgeCategory,
    isGeoCategory,
    COUNTRIES,
    GeoCountry
} from 'client/common/types';

import {LimitMatrixesType, LIMIT_MATRIXES, AGE_MAP, Matrix} from './size-extended-limits';

const GEO_TO_ROW_INDEX: Partial<Record<number, keyof Matrix>> = {
    [DIRECT_GEO_VALUES.CITY.MOSCOW]: 0,
    [DIRECT_GEO_VALUES.CITY.SAINT_PETERSBURG]: 1,
    [DIRECT_GEO_VALUES.CITY.NOVOSIBIRSK]: 2,
    [DIRECT_GEO_VALUES.CITY.YEKATERINBURG]: 3,
    [DIRECT_GEO_VALUES.CITY.KAZAN]: 4,
    [DIRECT_GEO_VALUES.CITY.NIZHNY_NOVGOROD]: 5,
    [DIRECT_GEO_VALUES.CITY.CHELYABINSK]: 6,
    [DIRECT_GEO_VALUES.CITY.SAMARA]: 7,
    [DIRECT_GEO_VALUES.CITY.OMSK]: 8,
    [DIRECT_GEO_VALUES.CITY.ROSTOV_NA_DONU]: 9,
    [DIRECT_GEO_VALUES.CITY.UFA]: 10,
    [DIRECT_GEO_VALUES.CITY.KRASNOYARSK]: 11,
    [DIRECT_GEO_VALUES.CITY.VORONEZH]: 12,
    [DIRECT_GEO_VALUES.CITY.PERM]: 13,
    [DIRECT_GEO_VALUES.CITY.VOLGOGRAD]: 14
} as const;

const MILLION_CITIES = Object.keys(GEO_TO_ROW_INDEX);

const EXTRA_CATEGORIES: readonly ExtendedAudienceCategoryId[] = [
    'cohabitants_amount',
    'have_car',
    'have_children',
    'family_status'
] as const;

const getMatrixForExtraCategory = (
    matrixes: LimitMatrixesType,
    {categoryId, value}: AudienceCategories
) => {
    if (
        categoryId === 'cohabitants_amount' ||
        categoryId === 'have_car' ||
        categoryId === 'have_children' ||
        categoryId === 'family_status'
    ) {
        return matrixes[categoryId][parseInt(value, 10)];
    }

    throw new Error(`Not matrix for targeting ${JSON.stringify({categoryId, value})}`);
};

const toArray = (arr: unknown) => (Array.isArray(arr) ? arr : [arr]);

const getValueForTargeting = (
    matrixes: LimitMatrixesType,
    targeting:
        | {
              age: 1 | 2 | 3 | 4 | 5;
              gender: number;
              geo: number;
              extraCategory: AudienceCategories;
          }
        | {
              age: 1 | 2 | 3 | 4 | 5;
              gender: number;
              extraCategory: AudienceCategories;
              citySize: ['1'] | ['2'] | ['3', '4'] | ['5'] | ['6'];
          }
): number => {
    const {age, gender, extraCategory} = targeting;

    const matrix = getMatrixForExtraCategory(matrixes, extraCategory);

    let matrixRow: keyof Matrix = 0;

    if ('geo' in targeting) {
        matrixRow = GEO_TO_ROW_INDEX[targeting.geo as keyof typeof GEO_TO_ROW_INDEX] ?? 0;
    } else if (isEqual(targeting.citySize, ['2'])) {
        matrixRow = 15;
    } else if (isEqual(targeting.citySize, ['3', '4'])) {
        matrixRow = 16;
    }

    const row = matrix[matrixRow];

    const columnIndex = AGE_MAP[age] + (gender === 1 ? 5 : 0);

    // @ts-ignore
    return row[columnIndex];
};

const UNSUPPORTED_CITY_SIZES = ['1', '5', '6'];

// eslint-disable-next-line max-statements
export const getMaxAudienceSize = ({
    matrixes = LIMIT_MATRIXES,
    categories
}: {
    matrixes?: typeof LIMIT_MATRIXES;
    categories: AudienceCategories[];
}): number => {
    let max = 0;

    // @ts-ignore
    const extraCategory = categories.find(({categoryId}) => EXTRA_CATEGORIES.includes(categoryId));

    if (!extraCategory || !extraCategory.value) {
        throw new Error(
            `OMI categories must have at least one targeting of ${EXTRA_CATEGORIES.join('/')}`
        );
    }

    const [geoCategory = {categoryId: 'geo', value: [GeoValue.Russia]}] =
        categories.filter(isGeoCategory);
    const [ageCategory = {categoryId: 'age', value: ['1', '2', '3', '4', '5']}] =
        categories.filter(isAgeCategory);
    const [genderCategory = {categoryId: 'gender', value: ['0', '1']}] =
        categories.filter(isGenderCategory);
    const [citySizesCategory = {categoryId: 'city_size', value: []}] =
        categories.filter(isCitySizeCategory);

    if (intersection(UNSUPPORTED_CITY_SIZES, citySizesCategory.value).length) {
        throw new Error(`Received unsupported city_size value ${citySizesCategory.value}`);
    }

    const ages = ageCategory.value.map(value => parseInt(value, 10)) as (1 | 2 | 3 | 4 | 5)[];

    const geoCategoryArray = toArray(geoCategory.value);
    let geoids = geoCategoryArray;
    let citySizes = [citySizesCategory?.value ?? []];

    const isHaveCountryTargeting = geoCategory.value.some(val =>
        COUNTRIES.includes(val as GeoCountry)
    );

    if (isHaveCountryTargeting) {
        geoids = MILLION_CITIES;
        citySizes = [['2'], ['3', '4']]; // 500-1000 + 250-500 + 100-250
    }

    let genders = [0, 1];

    if (genderCategory) {
        genders = toArray(genderCategory.value).map(value => parseInt(value, 10));
    }

    ages.forEach(age => {
        genders.forEach(gender => {
            geoids.forEach(geo => {
                max =
                    max +
                    getValueForTargeting(matrixes, {
                        extraCategory,
                        age,
                        gender,
                        geo: parseInt(geo, 10)
                    });
            });

            if (citySizes.length) {
                citySizes.forEach(citySize => {
                    max =
                        max +
                        getValueForTargeting(matrixes, {
                            extraCategory,
                            age,
                            gender,
                            // @ts-ignore
                            citySize
                        });
                });
            }
        });
    });

    return max;
};
