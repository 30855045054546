import {SurveySlug} from 'client/common/types';

import {_doRequest} from '../base';
import {
    GetAudienceRequestResponse,
    GetAudienceCategoriesResponse,
    GetAudienceInterestsResponse,
    GetAudienceSegmentsResponse
} from '../types';

export const getAudienceRequest = async ({
    slug,
    apiLocation = 'survey'
}: {
    slug: SurveySlug;
    apiLocation?: 'survey' | 'public';
}): Promise<GetAudienceRequestResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `${apiLocation}/${slug}/audience/request`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const getAudienceCategories = async ({
    slug
}: {
    slug?: SurveySlug;
} = {}): Promise<GetAudienceCategoriesResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: slug ? `survey/${slug}/audience/categories?filter=all` : 'audience/categories'
    };

    return _doRequest(requestParams);
};

export const getAudienceInterests = async ({
    lang = 'ru'
}: {
    lang?: Lang;
} = {}): Promise<GetAudienceInterestsResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `audience/interests?lang=${lang}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const getAudienceSegments = async (): Promise<GetAudienceSegmentsResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: 'audience/segments'
    };

    return _doRequest(requestParams);
};
