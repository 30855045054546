export const CURRENCIES = ['RUB', 'KZT', 'BYN', 'UZS', 'USD', 'CHF', 'EUR'] as const;
export const CREATIVE_CURRENCIES = ['RUB', 'KZT', 'BYN', 'UZS'] as const;
export const CURRENCIES_WITH_INTERVIEW_PROMO_PRICES = ['RUB', 'BYN', 'KZT'] as const;

export type Currency = typeof CURRENCIES[number];
export type CreativeCurrency = typeof CREATIVE_CURRENCIES[number];
export type CurrenciesWithInterviewPromoPrices = typeof CURRENCIES_WITH_INTERVIEW_PROMO_PRICES[number];

export interface SurveyPrice {
    value: number;
    value_with_vat?: number;
    value_without_vat?: number;
    currency: Currency;
    overwritable?: boolean;
}

export interface SurveyPaymentStatus {
    status: PaymentStatus;
}

export interface PayerRU {
    TYPE: 'ph';
    FNAME: string;
    LNAME: string;
    MNAME: string;
    EMAIL: string;
    PHONE: string;
}

interface PayerKZ {
    TYPE: 'kzp';
    FNAME: string;
    LNAME: string;
    EMAIL: string;
    PHONE: string;
    POSTADDRESS: string;
    CITY: string;
    KZ_IN: string; // ИНН 12 цифр
}

export type BillingPayer = PayerRU | PayerKZ;

export function isPayerRU(payer: BillingPayer): payer is PayerRU {
    return payer.TYPE === 'ph';
}

export function isPayerKZ(payer: BillingPayer): payer is PayerKZ {
    return payer.TYPE === 'kzp';
}

export interface Wallet {
    balance: number;
    currency: Currency | null;
    balance_with_vat?: number;
    balance_without_vat?: number;
    vat_multiplier?: number;
    vat?: boolean;
    uid: number;
}

export interface InterviewBalance {
    free: number;
    paid: number;
    total: number;
}

export enum PaymentStatus {
    NOT_PAYED,
    PAYED,
    PAYED_WITH_PACKAGE
}
