import {compose, composeU} from '@bem-react/core';

import {
    Textarea as TextareaDesktop,
    withAutoResize,
    withHasClear,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Textarea/desktop';

import {withSizeMBold} from './size/m-bold';

import {withViewBorderless} from './view/borderless';
import {withViewDL} from './view/dl';

import './index.css';

export const Textarea = compose(
    withAutoResize,
    withHasClear,
    composeU(withSizeM, withSizeMBold),
    composeU(withViewBorderless, withViewDefault, withViewDL)
)(TextareaDesktop);

export const TextareaWithoutResize = compose(
    withHasClear,
    composeU(withSizeM, withSizeMBold),
    composeU(withViewBorderless, withViewDefault, withViewDL)
)(TextareaDesktop);
