import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './magic-white.css';

export interface IconTypeMagicWhiteProps {
    type?: 'magic-white';
}

export const withTypeMagicWhite = withBemMod<IconTypeMagicWhiteProps>(cnIcon(), {type: 'magic-white'});
