import {_doRequest} from '../../base';
import {
    AddTableOptionParams,
    AddTableOptionResponse,
    RemoveTableOptionParams,
    UpdateTableOptionParams
} from '../../types';

const addTableOption = async (dir: 'row' | 'column' ,params: AddTableOptionParams): Promise<AddTableOptionResponse> => {
    const {slug, revisionId, questionId} = params;

    const requestParams = {
        method: 'POST',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/${dir}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const addColumn = (params: AddTableOptionParams) => addTableOption('column', params);
export const addRow = (params: AddTableOptionParams) => addTableOption('row', params);

const removeTableOption = async (dir: 'row' | 'column', {
    slug,
    revisionId,
    questionId,
    index
}: RemoveTableOptionParams) => {
    const requestParams = {
        method: 'DELETE',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/${dir}/${index}`,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const removeColumn = (params: RemoveTableOptionParams) => removeTableOption('column', params);
export const removeRow = (params: RemoveTableOptionParams) => removeTableOption('row', params);

const updateTableOption = async (dir: 'row' | 'column', {
    slug,
    revisionId,
    questionId,
    index,
    data
}: UpdateTableOptionParams) => {
    const requestParams = {
        method: 'PATCH',
        url: `survey/${slug}/revision/${revisionId}/question/${questionId}/${dir}/${index}`,
        data,
        passQuery: false
    };

    return _doRequest(requestParams);
};

export const updateColumn = (params: UpdateTableOptionParams) => updateTableOption('column', params);
export const updateRow = (params: UpdateTableOptionParams) => updateTableOption('row', params);
