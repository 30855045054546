import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './magic-black.css';

export interface IconTypeMagicBlackProps {
    type?: 'magic-black';
}

export const withTypeMagicBlack = withBemMod<IconTypeMagicBlackProps>(cnIcon(), {
    type: 'magic-black'
});
