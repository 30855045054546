import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './star.css';

export interface IconTypeStarProps {
    type?: 'star';
}

export const withTypeStar = withBemMod<IconTypeStarProps>(cnIcon(), {type: 'star'});
