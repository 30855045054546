import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './video-error.css';

export interface IconTypeVideoErrorProps {
    type?: 'video-error';
}

export const withTypeVideoError = withBemMod<IconTypeVideoErrorProps>(cnIcon(), {type: 'video-error'});
