import b_ from 'b_';
import I18N from 'assets/i18n/brandlift-project';

import './header2.css';

const b = b_.with('header2');

export const meaningfulWrapperClassName = b('menu', {type: 'main'});
export const meaningfulElemName = 'menu-link';
export const headerMeaningfulSelector = `.${meaningfulWrapperClassName} .${b(meaningfulElemName)}`;

export const CreativesHelpUrls = {
    creative: I18N.surveyStats.creativeStats.scoreboard.help.link.creative,
    bordomatik: I18N.surveyStats.creativeStats.scoreboard.help.link.bordomatik
};
