import clamp from 'lodash/clamp';
import round from 'lodash/round';

import I18N from 'assets/i18n/brandlift-project';
import {
    AudienceType,
    BoxType,
    GeoCountry,
    GeoValue,
    SizeDataFields
} from 'client/common/types';
import {AudienceTargeting} from 'client/common/utils/audience/targeting';
import type {UserRoles} from 'client/common/hooks/user';

import {getMaxAudienceSize} from './size-extended';

const DIRECT_BOX_TYPES: BoxType[] = ['videoBox', 'videoBoxKZ'];

export class AudienceSizeDO {
    private boxType: SizeDataFields['boxType'];
    private categories: SizeDataFields['categories'];
    private userRoles: UserRoles;
    private targetingCountry: GeoCountry;
    private type: SizeDataFields['type'];
    private size: SizeDataFields['size'];

    constructor({boxType, categories, userRoles, targetingCountry, type, size}: SizeDataFields) {
        this.boxType = boxType;
        this.categories = categories;
        this.userRoles = userRoles;
        this.targetingCountry = targetingCountry ?? GeoValue.Russia;
        this.type = type;
        this.size = size;
    }

    static isSizeInputVisible({type}: {type: SizeDataFields['type']}) {
        return type !== AudienceType.None;
    }

    static isSizeInputEnabled({
        boxType,
        userRoles
    }: {
        boxType: SizeDataFields['boxType'];
        userRoles: UserRoles;
    }) {
        // в ux можно редактировать размер аудитории только яндексоидам
        if (boxType === 'ux') {
            return userRoles.isYandexPublisher;
        }

        return true;
    }

    getSizeError(): string | undefined {
        const i18n = I18N.common.audienceSettings;
        const {min, max} = this.getValidLimits();

        return this.isValid()
            ? ''
            : i18n.size.error.replace('%s', String(min)).replace('%s', String(max));
    }
    getValidLimits() {
        const limit = this.getLimit();

        limit.max = round(limit.max, -1); // округляем до десяток

        return limit;
    }
    getValidSize() {
        const {min, max} = this.getValidLimits();

        return clamp(this.size, min, max);
    }
    isValid() {
        const {min, max} = this.getValidLimits();

        if (this.size >= min && this.size <= max) {
            return true;
        }

        return false;
    }

    // eslint-disable-next-line max-statements
    private getLimit(): {min: number; max: number} {
        const MIN_SIZE = 10;

        if (this.userRoles.isSuperuser || this.type === AudienceType.Manual) {
            return {min: 1, max: Infinity};
        }

        if (this.type === AudienceType.RespParamsOmi) {
            const max = getMaxAudienceSize({categories: this.categories});

            return {min: MIN_SIZE, max};
        }

        if (this.boxType === undefined) {
            return {
                [GeoValue.Russia]: {min: MIN_SIZE, max: 2000},
                [GeoValue.Kazakhstan]: {min: MIN_SIZE, max: 300},
                [GeoValue.Belarus]: {min: MIN_SIZE, max: 300},
                [GeoValue.Ukraine]: {min: MIN_SIZE, max: 2000},
                [GeoValue.Turkey]: {min: MIN_SIZE, max: 2000},
                [GeoValue.Uzbekistan]: {min: MIN_SIZE, max: 300}
            }[this.targetingCountry];
        }

        if (DIRECT_BOX_TYPES.includes(this.boxType)) {
            const isDefaultAudience = AudienceTargeting.isDefaultAudience({
                type: this.type,
                categories: this.categories || []
            });

            if (isDefaultAudience) {
                return {
                    [GeoValue.Russia]: {min: MIN_SIZE, max: 10000},
                    [GeoValue.Kazakhstan]: {min: MIN_SIZE, max: 3000},
                    [GeoValue.Belarus]: {min: MIN_SIZE, max: 3000},
                    [GeoValue.Ukraine]: {min: MIN_SIZE, max: 10000},
                    [GeoValue.Turkey]: {min: MIN_SIZE, max: 10000},
                    [GeoValue.Uzbekistan]: {min: MIN_SIZE, max: 3000}
                }[this.targetingCountry];
            }

            return {
                [GeoValue.Russia]: {min: MIN_SIZE, max: 3000},
                [GeoValue.Kazakhstan]: {min: MIN_SIZE, max: 2000},
                [GeoValue.Belarus]: {min: MIN_SIZE, max: 1000},
                [GeoValue.Ukraine]: {min: MIN_SIZE, max: 3000},
                [GeoValue.Turkey]: {min: MIN_SIZE, max: 3000},
                [GeoValue.Uzbekistan]: {min: MIN_SIZE, max: 1000}
            }[this.targetingCountry];
        }

        return {min: MIN_SIZE, max: 3000};
    }
}
