import '@yandex-int/messenger.widget/lib/ui/popup.css';
// @ts-ignore
import {YandexConfig, Widget, popupUIFactory} from '@yandex-int/messenger.widget';

import cfg from 'client/common/utils/client-config';

export const ui = popupUIFactory({});

export const widget = new Widget(
    new YandexConfig({
        serviceId: cfg.chat.widget.serviceId,
        iframeOpenData: {
            guid: cfg.chat.yachat.id
        },
        flags: {
            disableNavigation: 1
        }
    })
)
    .setUI(ui)
    .init();
