import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './download.css';

export interface IconTypeDownloadProps {
    type?: 'download';
}

export const withTypeDownload = withBemMod<IconTypeDownloadProps>(cnIcon(), {type: 'download'});
