import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './cross-bold.css';

export interface IconTypeCrossBoldProps {
    type?: 'cross-bold';
}

export const withTypeCrossBold = withBemMod<IconTypeCrossBoldProps>(cnIcon(), {type: 'cross-bold'});
