import React from 'react';

import I18N from 'assets/i18n/brandlift-project';

import {usePassportRegistrationStatus} from 'client/common/hooks/passport';

import HeaderNotification from './header-notification';

const i18n = I18N.common.passportRegistration;

export function HeaderNotificationPassport() {
    const {shouldShow, redirect} = usePassportRegistrationStatus();

    return shouldShow ? (
        <HeaderNotification
            message={i18n.message}
            linkLabel={i18n.buttonLabel}
            linkUrl={redirect}
        />
    ) : null;
}
