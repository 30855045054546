import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './branching.css';

export interface IconTypeBranchingProps {
    type?: 'branching';
}

export const withTypeBranching = withBemMod<IconTypeBranchingProps>(cnIcon(), {type: 'branching'});
