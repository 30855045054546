import {_doRequest} from '../base';
import {
    CreateResourceParams,
    CreateResourceResponse,
    FetchResourceResponse,
    GetVHVideoUploadLinkResponse,
    GetVHVideoInfoResponse
} from '../types';

export const createResource = async ({
    type,
    resourceData,
    clientId,
    slug
}: CreateResourceParams): Promise<CreateResourceResponse> => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/resource`,
        data: {
            type,
            resourceData,
            clientId
        }
    };

    return _doRequest(requestParams);
};

export const fetchResource = async ({id}: {id: string}): Promise<FetchResourceResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `resource/${id}`
    };

    return _doRequest(requestParams);
};

export const getVideoUploadLink = async ({
    name,
    length,
    clientId,
    slug
}: {
    name: string;
    length: number;
    clientId?: string;
    slug: string;
}): Promise<GetVHVideoUploadLinkResponse> => {
    const requestParams = {
        method: 'POST',
        retries: [3000],
        url: `survey/${slug}/resource/vh`,

        data: {name, length, clientId}
    };

    return _doRequest(requestParams);
};

export const getVHVideoInfo = async ({
    videoMetaId
}: {
    videoMetaId: string;
}): Promise<GetVHVideoInfoResponse> => {
    const requestParams = {
        method: 'GET',
        retries: [3000],
        url: `resource/vh/meta/${videoMetaId}`
    };

    return _doRequest(requestParams);
};
