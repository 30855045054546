import {compose} from '@bem-react/core';

import {
    Radiobox as RadioboxDesktop,
    Radio as RadioDesktop,
    withSizeM,
    withViewDefault
} from '@yandex-lego/components/Radiobox/desktop';

import './index.css';

export const Radiobox = compose(withSizeM, withViewDefault)(RadioboxDesktop);

export const Radio = RadioDesktop;
