import {
    AudienceRequest,
    AudienceSize,
    BoxType,
    SurveyPrice,
    SurveyScope,
    SurveySlug
} from 'client/common/types';

import {SurveyState} from 'client/common/store/reducers/survey/types';

export interface SurveySettingsProps {
    slug: SurveySlug;
}

export type SurveySettingsMode = 'simple' | 'full' | 'ux' | 'createSegment';

export enum AudienceTab {
    Request = 'request',
    Own = 'own',
    Manual = 'manual'
}

export interface AudienceProps extends SurveySettingsProps {
    state: SurveyState;
    mode: SurveySettingsMode;
}

export interface AudienceRequestProps extends Pick<AudienceProps, 'mode'> {
    boxType: BoxType | undefined;
    request: AudienceRequest;
    onResetToDefault: () => void;
    questionCount: number;
    questionLimit?: number;
    size: AudienceSize;
}

export interface DefaultAudienceProps extends Pick<AudienceRequestProps, 'mode' | 'size'> {
    onShowCustom: () => void;
    geoLabel: string;
}

export interface CustomRequestProps
    extends Pick<AudienceRequestProps, 'onResetToDefault'> {}

export interface QuestionCountBarProps {
    count: number;
    limit: number;
}

export interface OwnAudienceProps extends Pick<AudienceProps, 'slug'> {
    respReuse: boolean;
    scope: SurveyScope;
    state: SurveyState;
    boxName?: BoxType;
}

export interface MainProps extends SurveySettingsProps {
    isAuthorized: boolean;
    isPriceLoading: boolean;
    isSuperuser: boolean;
    mode: SurveySettingsMode;
    name: string;
    onModerationCancel: () => void;
    onPause: () => void;
    onResume: () => void;
    paymentStatus: boolean;
    price: SurveyPrice;
    request: AudienceRequest;
    state: SurveyState;
}

export interface EditNameIconProps extends Pick<MainProps, 'slug'> {}

export interface StatusIconProps {
    onClick: () => void;
}

export interface MainButtonsProps extends MainProps {}

export interface ModalProps {
    onClose: () => void;
    slug: SurveySlug;
}
