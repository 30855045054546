import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './people.css';

export interface IconTypePeopleProps {
    type?: 'people';
}

export const withTypePeople = withBemMod<IconTypePeopleProps>(cnIcon(), {type: 'people'});
