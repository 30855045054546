import {withBemMod} from '@bem-react/core';

import {cnTextarea} from '@yandex-lego/components/Textarea';

import './m-bold.css';

export interface TextareaSizeMBoldProps {
    size?: 'm-bold';
}

export const withSizeMBold = withBemMod<TextareaSizeMBoldProps>(cnTextarea(), {size: 'm-bold'});
