import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './user.css';

export interface IconTypeUserProps {
    type?: 'user';
}

export const withTypeUser = withBemMod<IconTypeUserProps>(cnIcon(), {type: 'user'});
