import {useEffect} from 'react';

import {initCounters, sendFirstMeaningfulPaint} from '@yandex-int/pythia-libs/lib/rum';
import {tld} from 'client/common/utils/tld';
import {TLD} from '@yandex-int/pythia-libs/lib/geo';

interface RumCounterProps {
    page: string;
    meaningfulPaintSelector: string;
    testBuckets: string[];
}

export function useRumCounter({page, meaningfulPaintSelector, testBuckets}: RumCounterProps) {
    useEffect(() => {
        initCounters({
            page,
            tld: tld as TLD,
            version: process.env.APP_VERSION,
            slots: testBuckets
        });
        sendFirstMeaningfulPaint(meaningfulPaintSelector);
    }, [meaningfulPaintSelector, page, testBuckets]);
}

export function sendRumCounter({page, meaningfulPaintSelector, testBuckets}: RumCounterProps) {
    initCounters({
        page,
        tld: tld as TLD,
        version: process.env.APP_VERSION,
        slots: testBuckets
    });
    sendFirstMeaningfulPaint(meaningfulPaintSelector);
}
