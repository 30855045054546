import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './check-filled.css';

export interface IconTypeCheckFilledProps {
    type?: 'check-filled';
}

export const withTypeCheckFilled = withBemMod<IconTypeCheckFilledProps>(cnIcon(), {
    type: 'check-filled'
});
