import {withBemMod} from '@bem-react/core';

import {cnIcon} from '@yandex-lego/components/Icon';

import './questions.css';

export interface IconTypeQuestionEmailProps {
    type?: 'q-email';
}
export interface IconTypeQuestionEmotionProps {
    type?: 'q-emotion';
}
export interface IconTypeQuestionGeoProps {
    type?: 'q-geo';
}
export interface IconTypeQuestionLongProps {
    type?: 'q-long';
}
export interface IconTypeQuestionMultichoiceProps {
    type?: 'q-multichoice';
}
export interface IconTypeQuestionDualchoiceProps {
    type?: 'q-dualchoice';
}
export interface IconTypeQuestionNumberProps {
    type?: 'q-number';
}
export interface IconTypeQuestionPercentProps {
    type?: 'q-percent';
}
export interface IconTypeQuestionRatingProps {
    type?: 'q-rating';
}
export interface IconTypeQuestionSbsProps {
    type?: 'q-sbs';
}
export interface IconTypeQuestionScaleProps {
    type?: 'q-scale';
}
export interface IconTypeQuestionShortProps {
    type?: 'q-short';
}
export interface IconTypeQuestionSinglechoiceProps {
    type?: 'q-singlechoice';
}
export interface IconTypeQuestionTableProps {
    type?: 'q-table';
}
export interface IconTypeQuestionTextProps {
    type?: 'q-text';
}

export interface IconTypeQuestionDateProps {
    type?: 'q-date';
}

export const withTypeQuestionEmail = withBemMod<IconTypeQuestionEmailProps>(cnIcon(), {
    type: 'q-email'
});
export const withTypeQuestionEmotion = withBemMod<IconTypeQuestionEmotionProps>(cnIcon(), {
    type: 'q-emotion'
});
export const withTypeQuestionGeo = withBemMod<IconTypeQuestionGeoProps>(cnIcon(), {type: 'q-geo'});
export const withTypeQuestionLong = withBemMod<IconTypeQuestionLongProps>(cnIcon(), {
    type: 'q-long'
});
export const withTypeQuestionMultichoice = withBemMod<IconTypeQuestionMultichoiceProps>(cnIcon(), {
    type: 'q-multichoice'
});
export const withTypeQuestionDualchoice = withBemMod<IconTypeQuestionDualchoiceProps>(cnIcon(), {
    type: 'q-dualchoice'
});
export const withTypeQuestionNumber = withBemMod<IconTypeQuestionNumberProps>(cnIcon(), {
    type: 'q-number'
});
export const withTypeQuestionPercent = withBemMod<IconTypeQuestionNumberProps>(cnIcon(), {
    type: 'q-percent'
});
export const withTypeQuestionRating = withBemMod<IconTypeQuestionRatingProps>(cnIcon(), {
    type: 'q-rating'
});
export const withTypeQuestionSbs = withBemMod<IconTypeQuestionSbsProps>(cnIcon(), {type: 'q-sbs'});
export const withTypeQuestionScale = withBemMod<IconTypeQuestionScaleProps>(cnIcon(), {
    type: 'q-scale'
});
export const withTypeQuestionShort = withBemMod<IconTypeQuestionShortProps>(cnIcon(), {
    type: 'q-short'
});
export const withTypeQuestionSinglechoice = withBemMod<IconTypeQuestionSinglechoiceProps>(
    cnIcon(),
    {
        type: 'q-singlechoice'
    }
);
export const withTypeQuestionTable = withBemMod<IconTypeQuestionTableProps>(cnIcon(), {
    type: 'q-table'
});
export const withTypeQuestionText = withBemMod<IconTypeQuestionTextProps>(cnIcon(), {
    type: 'q-text'
});

export const withTypeQuestionDate = withBemMod<IconTypeQuestionDateProps>(cnIcon(), {
    type: 'q-date'
});
